import React from 'react';
import { SVG } from '@svgdotjs/svg.js';
import { connect } from 'react-redux';
import { getTabs, setOpenStatus, setUpdateData, addSavingID, setShareStatus } from '../../../../../redux/reticles/tabs-reducer';
import { getZoomData, updateZoomData, updateZoomPosition } from '../../../../../redux/zoom/zoom-reducer';
import { getGridData } from '../../../../../redux/grid/grid-reducer';
import { addShapeData, editShapeData } from '../../../../../redux/shape/shape-reducer';
import { getIsSavingId } from '../../../../../redux/reticles/reticles-selectors';
import { getColorThemeData } from '../../../../../redux/colorTheme/colorTheme-reducer';
import {editPolygonCoords} from '../../../utils/editPolygonCoords';
// import coords2GridFinder from '../../../utils/coords2GridFinder';
import './Svg.scss';

// const coords2GridFinder = require('../../../utils/coords2GridFinder');
// const {editPolygonCoords} = require('../../../utils/editPolygonCoords');

class Svg extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            draw: new SVG().size('1024', '768').attr({id: `svgCanvas`, class: 'canvas-block'}),
            canvasWidth: 1024,
            canvasHeight: 768,
            x: 0,
            y: 0,
            x2: 0,
            y2: 0,
            r: 1,
            cx: 0,
            cy: 0,
            startX: 0,
            startY: 0,
            lineStartX: 0,
            lineStartY: 0,
            endX: 0,
            endY: 0,
            rectW: 30,
            rectH: 30,
            editedW: 1,
            editedH: 1,
            editedL: 0,
            editedR: 0,
            degree: 0,
            pxLength: 0,
            gridLength: 0,
            strokeColor: '#000',
            shapeStrokeColor: '#000000',
            strokeWidth: 1,
            fillColor: '#ffffff',
            shapeFillColor: '#ffffff',
            opacity: 0,
            isDrowing: false,
            currentTool: '',
            triangleDirection: 'top',
            romNumber: '',
            currentShape: '',
            selectedShape: '',
            selectedShapeStrokeWidth: 1,
            selectedShapes: [],
            selectedShapeCoords: [],
            groupedShapes: new Set(),
            currentGroup: '',
            alignedShape: '',
            i: 0,
            numberNum: 0,
            lineNum: 0,
            circleNum: 0,
            triangleNum: 0,
            squareNum: 0,
            rectNum: 0,
            gridSize: 3,
            moa: true,
            mil: false,
            moaAvg: 3,
            milAvg: 10,
            gridX: 0,
            gridY: 0,
            canvasShapes: [],
            focalPlane: 'off',
            svgData: '',
            currentMarker: '',
            isMarkerEdit: false,
            markerX: 0,
            markerY: 0,
            isShiftPressed: false,
            currentShapesID: [],
            deletedIndex: 0,
            clone: '',
            isGroupingActive: false,
            selectedLayerID: '',
            prevOpacity: 0,
            prevStroke: '',
            zoomWidthIndex: 1024,
            zoomHeightIndex: 768,
            zoomIndex: 100,
            canvasNames: [],
            prevStrokeColor: '',
            renameData: {id: '', name: ''},
            prevFillColor: '',
            isEdit: false,
            currentTab: 'tab-0',
            shapeNames: [],
            canvasImage: [],
            canvasRedo: [],
            gX: [],
            gY: [],
            isSvgActive: true,
            isZoom: false,
            zx: 0,
            zy: 0,
            maxZX: 110,
            maxZY: 66,
            moveZoomX: 0,
            moveZoomY: 0,
            zoomK: 1,
            isShared: false,
            isShapeMove: false,
            shapesOnCanvas: [],
            sx: 0,
            sy: 0,
            savingID: '',
            axisLine: false,
            fpValue: 42,
            docID: '',
            isShapeSelected: '',
            sizeEditedShape: '',
            isAllShapesFill: false,
            isClose2borderX: false,
            isClose2borderY: false,
            isNightMode: false,
            mainGroup: {},
            strokeDasharray: null,
        }
    }

    groupedSet = new Set();
    canvasShapesBuffer = new Set();
    groupElements = [];
    selectedShapes = new Set(); //  when select many shapes with Shift + mouse click
    selectedShapesBuffer = [];
    // shapeNames = []; // Shapes names on canvas for layers section

    pathCoordsArr = [];
    pathBuffer = [];
    isPathActive = false;

    // gridX = [];
    // gridY = [];
    markers = [];
    g = ''; //  buffer group variable
    b = '';
    // canvasImage = [];   //  buffer variable for addUndoStep function
    // canvasRedo = []; //  buffer variable for redoLastStep function

    strokeW = '';   //  store default shape stroke width
    hoverEl = '';   //  store shape when mouse over

    gData = [];

    sharedSVG = {};

    canvasShapesArr = [];

    objX = 0;
    objY = 0;
    objW = 0;
    objH = 0;
    objR = 0;
    objCX = 0;
    objCY = 0;

    tool = 'move';

    touchBorder = false;    //  if select rectangle tool touch canvas border
    rStep = 0;  //  Rotate step

    trianglesTypes = ['top', 'right', 'bottom', 'left'];

    prevActiveShapeBtn = {name: 'move', direction: ''};

    // isZoom = false; //  true if canvas zooming
    // zx = 0;
    // zy = 0;
    // maxZX = 110;
    // maxZY = 66;
    // moveZoomX = 0;
    // moveZoomY = 0;
    // zoomK = 1;
    // vbcx = 200; //  viewport x coordinates
    // vbcy = 100; //  viewport y coordinates

    // createMarker = (x, y, i) => {
    //     this.state.draw.rect(5, 5)
    //         .stroke({width: `1px`, color: '#1998EE'})
    //         .fill({color: '#fff'})
    //         .attr({id: `m${i}`, class: 'marker'})
    //         .cx(x)
    //         .cy(y)
    // }

    gridToPXConverter = (grid) => {
        if (grid) {
            return this.state.moa ? grid * this.state.moaAvg : grid * this.state.milAvg
        } //else {
        //     return this.state.moa ? 1 * this.state.moaAvg : 1 * this.state.milAvg
        // }
    }

    // addMarkersToSelected = (shape) => {
    //     if (shape) {
    //         let s = shape.width() * shape.height();
    //         let select = document.querySelector(`#${shape.node.id}`);
    
    //         this.setState({
    //             fillColor: this.state.fillColor = select.getAttribute('fill'),
    //             opacity: this.state.opacity = select.getAttribute('fill-opacity'),
    //             prevStroke: this.state.prevStroke = select.getAttribute('stroke-width'),
    //             prevStrokeColor: this.state.prevStrokeColor = select.getAttribute('stroke'),
    //         })
    
    //         if (s > 10 || shape.type === 'line') {
    //             if (shape.type === 'polygon') {
    //                 this.state.draw.rect(5, 5)
    //                     .stroke({width: `1px`, color: '#337AA6'})
    //                     .fill({color: '#fff'})
    //                     .attr({id: 'm0', class: 'marker'})
    //                     .cx(this.state.selectedShape.width() + this.state.selectedShape.x())
    //                     .cy(this.state.selectedShape.height() + this.state.selectedShape.y())
    
    //                 this.props.getSelectedShapeWidth(this.state.prevStroke);
    //                 this.props.getSelectedShapeStrokeColor(this.state.prevStrokeColor);
    //                 this.props.fillShapePrevOpacity(this.state.opacity*100);
    //                 this.props.fillShapePrevColor(this.state.fillColor);
    
    //             } else if (shape.type === 'circle') {
    //                 this.state.draw.path('M495 375 H505 M500 370 V380')
    //                     .attr({id: 'circleCenter', class: 'marker'})
    //                     .fill('none')
    //                     .stroke({ color: '#337AA6', width: 1})
    //                     .center(shape.node.attributes[1].nodeValue, shape.node.attributes[2].nodeValue)
    //                     .back()

    //                 this.state.draw.rect(5, 5)
    //                     .stroke({width: `1px`, color: '#337AA6'})
    //                     .fill({color: '#fff'})
    //                     .attr({id: 'm0', class: 'marker'})
    //                     .cx(this.state.selectedShape.width() + this.state.selectedShape.x())
    //                     .cy(this.state.selectedShape.height() + this.state.selectedShape.y())

    //                 this.props.getSelectedShapeWidth(this.state.prevStroke);
    //                 this.props.getSelectedShapeStrokeColor(this.state.prevStrokeColor);
    //                 this.props.fillShapePrevColor(this.state.fillColor);
    //                 this.props.fillShapePrevOpacity(this.state.opacity * 100);
    
    //             } else if (shape.type === 'line') {
    //                 let coords = this.getLineCoords(shape);
    //                 let has = this.state.draw.find('.marker')
    
    //                 if (has.length === 0) {
                        
    //                     for (let i = 0; i < coords.length; i += 2) {
    //                         let x = coords[i]
    //                         let y = coords[i + 1]
    
    //                         this.state.draw.rect(5, 5)
    //                             .stroke({width: `1px`, color: '#337AA6'})
    //                             .fill({color: '#fff'})
    //                             .attr({id: `m${i}`, class: 'marker'})
    //                             .cx(x)
    //                             .cy(y)
    //                     }
    //                 }
    
    //                 this.props.getSelectedShapeWidth(this.state.prevStroke);
    //                 this.props.getSelectedShapeStrokeColor(this.state.prevStrokeColor);
    //             } else if (shape.type === 'rect') {
    //                 let x = shape.x();
    //                 let y = shape.y();
    //                 let w = shape.width();
    //                 let h = shape.height();
    //                 let arr = [];
    
    //                 arr.push([x, y]);
    //                 arr.push([x + w, y]);
    //                 arr.push([x + w, y + h]);
    //                 arr.push([x, y + h]);
    
    //                 this.state.draw.rect(5, 5)
    //                     .stroke({width: `1px`, color: '#337AA6'})
    //                     .fill({color: '#fff'})
    //                     .attr({id: 'm2', class: 'marker'})
    //                     .cx(x + w)
    //                     .cy(y + h)
    
    //                 this.props.getSelectedShapeWidth(this.state.prevStroke);
    //                 this.props.getSelectedShapeStrokeColor(this.state.prevStrokeColor);
    //                 this.props.fillShapePrevOpacity(this.state.opacity * 100);
    //                 this.props.fillShapePrevColor(this.state.fillColor);
    //             } else if (shape.type === 'g' && shape.hasClass('number-group')) {
    //                 this.state.draw.rect(5, 5)
    //                     .stroke({width: `1px`, color: '#337AA6'})
    //                     .fill({color: '#fff'})
    //                     .attr({id: 'm2', class: 'marker'})
    //                     .cx(shape.x() + shape.width())
    //                     .cy(shape.y() + shape.height())
    //             }
    //         }
    //     }
    // }

    addMarkersToSelected = (shape) => {
        if (shape) {
            let select = document.querySelector(`#${shape.id()}`);
            const shapeWidth = shape.width();
            const shapeHeight = shape.height();
            const shapeStrokeW = shape.attr('stroke-width');
            let fillAttr = select ? select.getAttribute('fill') : '#fff';
            let fillOpacityAttr = select ? select.getAttribute('fill-opacity') : 1;
            let strokeWidthAttr = select ? select.getAttribute('stroke-width') : 1;
            let currentFillColor = shape.type === 'g' ? '#ffffff' : fillAttr;
            let strokeColorAttr = shape.type === 'g' ? shape.stroke() : select && shape.type !== 'g' ? shape.stroke() : '#000';

            this.setState({
                opacity: this.state.opacity = shape.type === 'g' ? '1' : fillOpacityAttr,
                prevStroke: this.state.prevStroke = shape.type === 'g' && this.state.moa ? '3' : shape.type === 'g' && this.state.mil ? '10' : strokeWidthAttr,
                prevStrokeColor: this.state.prevStrokeColor = strokeColorAttr,
            })

            if (!shape.hasClass('mouseSelect')) {
                this.props.getSelectedShapeWidth(this.state.prevStroke);
                this.props.getSelectedShapeStrokeColor(this.state.prevStrokeColor);
                this.props.fillShapePrevOpacity(this.state.opacity * 100);
                this.props.fillShapePrevColor(currentFillColor);
            }


            if (shape.type === 'circle') {
                this.state.draw.path('M495 375 H505 M500 370 V380')
                    .attr({id: 'shapeCenterMarker', class: 'marker'})
                    .fill('none')
                    .stroke({ color: '#337AA6', width: 1})
                    .center(shape.node.attributes[1].nodeValue, shape.node.attributes[2].nodeValue)
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .back()

                // this.state.draw.rect(5, 5)
                //     .stroke({width: `1px`, color: '#337AA6'})
                //     .fill({color: '#fff'})
                //     .attr({id: `m${0}`, class: 'marker left-diagonal-resize'})
                //     .cx(shape.x() - shapeStrokeW / 2)
                //     .cy(shape.y() - shapeStrokeW / 2)
                //     .attr({'vector-effect': 'non-scaling-stroke'})

                let group0 = this.state.draw.group()
                    .id('m0')
                    .addClass('marker left-diagonal-resize')
                    // .cx(shape.x() - shapeStrokeW / 2)
                    // .cy(shape.y() - shapeStrokeW / 2)

                let marker0 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '7px', color: '#337AA6'})
                    .cx(shape.x() - shapeStrokeW / 2)
                    .cy(shape.y() - shapeStrokeW / 2)
                    .addClass('markerElement')

                let marker01 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({id: 'm0', class: 'marker left-diagonal-resize marker-select'})
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '5px', color: '#fff'})
                    .cx(shape.x() - shapeStrokeW / 2)
                    .cy(shape.y() - shapeStrokeW / 2)
                    .addClass('markerElement')

                group0.add(marker0);
                group0.add(marker01);
    
                // this.state.draw.rect(5, 5)
                //     .stroke({width: `1px`, color: '#337AA6'})
                //     .fill({color: '#fff'})
                //     .attr({id: `m${2}`, class: 'marker right-diagonal-resize'})
                //     .cx(shape.x() + shapeWidth + shapeStrokeW / 2)
                //     .cy(shape.y() - shapeStrokeW / 2)
                //     .attr({'vector-effect': 'non-scaling-stroke'})

                let group2 = this.state.draw.group()
                    .id('m2')
                    .addClass('marker right-diagonal-resize')
                    // .cx(shape.x() - shapeStrokeW / 2)
                    // .cy(shape.y() - shapeStrokeW / 2)

                let marker2 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '7px', color: '#337AA6'})
                    .cx(shape.x() + shapeWidth + shapeStrokeW / 2)
                    .cy(shape.y() - shapeStrokeW / 2)
                    .addClass('markerElement')

                let marker02 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({id: 'm2', class: 'marker right-diagonal-resize marker-select'})
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '5px', color: '#fff'})
                    .cx(shape.x() + shapeWidth + shapeStrokeW / 2)
                    .cy(shape.y() - shapeStrokeW / 2)
                    .addClass('markerElement')

                group2.add(marker2);
                group2.add(marker02);
        
                    // this.state.draw.rect(5, 5)
                    //     .stroke({width: `1px`, color: '#337AA6'})
                    //     .fill({color: '#fff'})
                    //     .attr({id: `m${5}`, class: 'marker right-diagonal-resize'})
                    //     .cx(shape.x() - shapeStrokeW / 2)
                    //     .cy(shape.y() + shapeHeight + shapeStrokeW / 2)
                    //     .attr({'vector-effect': 'non-scaling-stroke'})

                let group5 = this.state.draw.group()
                    .id('m5')
                    .addClass('marker right-diagonal-resize')
                    // .cx(shape.x() - shapeStrokeW / 2)
                    // .cy(shape.y() - shapeStrokeW / 2)

                let marker5 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '7px', color: '#337AA6'})
                    .cx(shape.x() - shapeStrokeW / 2)
                    .cy(shape.y() + shapeHeight + shapeStrokeW / 2)
                    .addClass('markerElement')

                let marker05 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({id: 'm5', class: 'marker right-diagonal-resize marker-select'})
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '5px', color: '#fff'})
                    .cx(shape.x() - shapeStrokeW / 2)
                    .cy(shape.y() + shapeHeight + shapeStrokeW / 2)
                    .addClass('markerElement')

                group5.add(marker5);
                group5.add(marker05);

                    // this.state.draw.rect(5, 5)
                    //     .stroke({width: `1px`, color: '#337AA6'})
                    //     .fill({color: '#fff'})
                    //     .attr({id: `m${7}`, class: 'marker left-diagonal-resize'})
                    //     .cx(shape.x() + shapeWidth + shapeStrokeW / 2)
                    //     .cy(shape.y() + shapeHeight + shapeStrokeW / 2)
                    //     .attr({'vector-effect': 'non-scaling-stroke'})

                let group7 = this.state.draw.group()
                    .id('m7')
                    .addClass('marker left-diagonal-resize')
                    // .cx(shape.x() - shapeStrokeW / 2)
                    // .cy(shape.y() - shapeStrokeW / 2)

                let marker7 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '7px', color: '#337AA6'})
                    .cx(shape.x() + shapeWidth + shapeStrokeW / 2)
                    .cy(shape.y() + shapeHeight + shapeStrokeW / 2)
                    .addClass('markerElement')

                let marker07 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({id: 'm7', class: 'marker left-diagonal-resize marker-select'})
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '5px', color: '#fff'})
                    .cx(shape.x() + shapeWidth + shapeStrokeW / 2)
                    .cy(shape.y() + shapeHeight + shapeStrokeW / 2)
                    .addClass('markerElement')

                group7.add(marker7);
                group7.add(marker07);
            }

            if (shape.type === 'line') {
                // this.state.draw.rect(5, 5)
                //     .stroke({width: `1px`, color: '#337AA6'})
                //     .fill({color: '#fff'})
                //     .attr({id: `m${0}`, class: 'marker all-resize'})
                //     .cx(shape.attr('x1'))
                //     .cy(shape.attr('y1'))
                //     .attr({'vector-effect': 'non-scaling-stroke'})

                let group0 = this.state.draw.group()
                    .id(`m${0}`)
                    .addClass('marker all-resize')

                let marker0 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '7px', color: '#337AA6'})
                    .cx(shape.attr('x1'))
                    .cy(shape.attr('y1'))
                    .addClass('markerElement')

                let marker00 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({id: `m${0}`, class: 'marker all-resize marker-select'})
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '5px', color: '#fff'})
                    .cx(shape.attr('x1'))
                    .cy(shape.attr('y1'))
                    .addClass('markerElement')

                group0.add(marker0);
                group0.add(marker00);

                // this.state.draw.rect(5, 5)
                //     .stroke({width: `1px`, color: '#337AA6'})
                //     .fill({color: '#fff'})
                //     .attr({id: `m${5}`, class: 'marker all-resize'})
                //     .cx(shape.cx())
                //     .cy(shape.cy())
                //     .attr({'vector-effect': 'non-scaling-stroke'})

                let group5 = this.state.draw.group()
                    .id(`m${5}`)
                    .addClass('marker all-resize')

                let marker5 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '7px', color: '#337AA6'})
                    .cx(shape.cx())
                    .cy(shape.cy())
                    .addClass('markerElement')

                let marker05 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({id: `m${5}`, class: 'marker all-resize marker-select'})
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '5px', color: '#fff'})
                    .cx(shape.cx())
                    .cy(shape.cy())
                    .addClass('markerElement')

                group5.add(marker5);
                group5.add(marker05);
                    
                // this.state.draw.rect(5, 5)
                //     .stroke({width: `1px`, color: '#337AA6'})
                //     .fill({color: '#fff'})
                //     .attr({id: `m${7}`, class: 'marker all-resize'})
                //     .cx(shape.attr('x2'))
                //     .cy(shape.attr('y2'))
                //     .attr({'vector-effect': 'non-scaling-stroke'})

                let group7 = this.state.draw.group()
                    .id(`m${7}`)
                    .addClass('marker all-resize')

                let marker7 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '7px', color: '#337AA6'})
                    .cx(shape.attr('x2'))
                    .cy(shape.attr('y2'))
                    .addClass('markerElement')

                let marker07 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({id: `m${7}`, class: 'marker all-resize marker-select'})
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '5px', color: '#fff'})
                    .cx(shape.attr('x2'))
                    .cy(shape.attr('y2'))
                    .addClass('markerElement')

                group7.add(marker7);
                group7.add(marker07);

                // this.state.draw.circle(5)
                //     .stroke({width: `1px`, color: '#337AA6'})
                //     .fill({color: '#fff'})
                //     .attr({id: `m${8}`, class: 'marker rotate-marker'})
                //     .cx(shape.cx())
                //     .cy(shape.cy() + shape.height() / 2 + 10)
                //     .attr({'vector-effect': 'non-scaling-stroke'})
            }

            if (shape.type !== 'g' && shape.type !== 'line' && shape.type !== 'circle' && !shape.hasClass('mouseSelect')) {
                // this.state.draw.rect(5, 5)
                //     .stroke({width: `1px`, color: '#337AA6'})
                //     .fill({color: '#fff'})
                //     .attr({id: `m${0}`, class: 'marker left-diagonal-resize'})
                //     .cx(shape.x() - shapeStrokeW)
                //     .cy(shape.y() - shapeStrokeW)
                //     .attr({'vector-effect': 'non-scaling-stroke'})

                let group0 = this.state.draw.group()
                    .id(`m${0}`)
                    .addClass('marker left-diagonal-resize')

                let marker0 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '7px', color: '#337AA6'})
                    .cx(shape.x() - shapeStrokeW)
                    .cy(shape.y() - shapeStrokeW)
                    .addClass('markerElement')

                let marker00 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({id: `m${0}`, class: 'marker left-diagonal-resize marker-select'})
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '5px', color: '#fff'})
                    .cx(shape.x() - shapeStrokeW)
                    .cy(shape.y() - shapeStrokeW)
                    .addClass('markerElement')

                group0.add(marker0);
                group0.add(marker00);

                if ( shape.hasClass('rectangle')) {
                    // this.state.draw.rect(5, 5)
                    //     .stroke({width: `1px`, color: '#337AA6'})
                    //     .fill({color: '#fff'})
                    //     .attr({id: `m${1}`, class: 'marker horizontal-resize'})
                    //     .cx(shape.x() + shapeWidth / 2)
                    //     .cy(shape.y() - shapeStrokeW)
                    //     .attr({'vector-effect': 'non-scaling-stroke'})

                    let group1 = this.state.draw.group()
                        .id(`m${1}`)
                        .addClass('marker horizontal-resize')

                    let marker1 = this.state.draw.path('M 50 50 l 0.0001 0')
                        .attr({'vector-effect': 'non-scaling-stroke'})
                        .attr({'stroke-linecap': 'square'})
                        .stroke({width: '7px', color: '#337AA6'})
                        .cx(shape.x() + shapeWidth / 2)
                        .cy(shape.y() - shapeStrokeW)
                        .addClass('markerElement')

                    let marker01 = this.state.draw.path('M 50 50 l 0.0001 0')
                        .attr({id: `m${1}`, class: 'marker horizontal-resize marker-select'})
                        .attr({'vector-effect': 'non-scaling-stroke'})
                        .attr({'stroke-linecap': 'square'})
                        .stroke({width: '5px', color: '#fff'})
                        .cx(shape.x() + shapeWidth / 2)
                        .cy(shape.y() - shapeStrokeW)
                        .addClass('markerElement')

                    group1.add(marker1);
                    group1.add(marker01);
                        
                    // this.state.draw.rect(5, 5)
                    //     .stroke({width: `1px`, color: '#337AA6'})
                    //     .fill({color: '#fff'})
                    //     .attr({id: `m${3}`, class: 'marker vertical-resize'})
                    //     .cx(shape.x() - shapeStrokeW)
                    //     .cy(shape.y() + shapeHeight / 2)
                    //     .attr({'vector-effect': 'non-scaling-stroke'})

                    let group3 = this.state.draw.group()
                        .id(`m${3}`)
                        .addClass('marker vertical-resize')

                    let marker3 = this.state.draw.path('M 50 50 l 0.0001 0')
                        .attr({'vector-effect': 'non-scaling-stroke'})
                        .attr({'stroke-linecap': 'square'})
                        .stroke({width: '7px', color: '#337AA6'})
                        .cx(shape.x() - shapeStrokeW)
                        .cy(shape.y() + shapeHeight / 2)
                        .addClass('markerElement')

                    let marker03 = this.state.draw.path('M 50 50 l 0.0001 0')
                        .attr({id: `m${3}`, class: 'marker vertical-resize marker-select'})
                        .attr({'vector-effect': 'non-scaling-stroke'})
                        .attr({'stroke-linecap': 'square'})
                        .stroke({width: '5px', color: '#fff'})
                        .cx(shape.x() - shapeStrokeW)
                        .cy(shape.y() + shapeHeight / 2)
                        .addClass('markerElement')

                    group3.add(marker3);
                    group3.add(marker03);

                    // this.state.draw.rect(5, 5)
                    //     .stroke({width: `1px`, color: '#337AA6'})
                    //     .fill({color: '#fff'})
                    //     .attr({id: `m${4}`, class: 'marker vertical-resize'})
                    //     .cx(shape.x() + shapeWidth + shapeStrokeW)
                    //     .cy(shape.y() + shapeHeight / 2)
                    //     .attr({'vector-effect': 'non-scaling-stroke'})

                    let group4 = this.state.draw.group()
                        .id(`m${4}`)
                        .addClass('marker vertical-resize')

                    let marker4 = this.state.draw.path('M 50 50 l 0.0001 0')
                        .attr({'vector-effect': 'non-scaling-stroke'})
                        .attr({'stroke-linecap': 'square'})
                        .stroke({width: '7px', color: '#337AA6'})
                        .cx(shape.x() + shapeWidth + shapeStrokeW)
                        .cy(shape.y() + shapeHeight / 2)
                        .addClass('markerElement')

                    let marker04 = this.state.draw.path('M 50 50 l 0.0001 0')
                        .attr({id: `m${4}`, class: 'marker vertical-resize marker-select'})
                        .attr({'vector-effect': 'non-scaling-stroke'})
                        .attr({'stroke-linecap': 'square'})
                        .stroke({width: '5px', color: '#fff'})
                        .cx(shape.x() + shapeWidth + shapeStrokeW)
                        .cy(shape.y() + shapeHeight / 2)
                        .addClass('markerElement')

                    group4.add(marker4);
                    group4.add(marker04);
                        
                    // this.state.draw.rect(5, 5)
                    //     .stroke({width: `1px`, color: '#337AA6'})
                    //     .fill({color: '#fff'})
                    //     .attr({id: `m${6}`, class: 'marker horizontal-resize'})
                    //     .cx(shape.x() + shapeWidth / 2)
                    //     .cy(shape.y() + shapeHeight + shapeStrokeW)
                    //     .attr({'vector-effect': 'non-scaling-stroke'})

                    let group6 = this.state.draw.group()
                        .id(`m${6}`)
                        .addClass('marker horizontal-resize')

                    let marker6 = this.state.draw.path('M 50 50 l 0.0001 0')
                        .attr({'vector-effect': 'non-scaling-stroke'})
                        .attr({'stroke-linecap': 'square'})
                        .stroke({width: '7px', color: '#337AA6'})
                        .cx(shape.x() + shapeWidth / 2)
                        .cy(shape.y() + shapeHeight + shapeStrokeW)
                        .addClass('markerElement')

                    let marker06 = this.state.draw.path('M 50 50 l 0.0001 0')
                        .attr({id: `m${6}`, class: 'marker horizontal-resize marker-select'})
                        .attr({'vector-effect': 'non-scaling-stroke'})
                        .attr({'stroke-linecap': 'square'})
                        .stroke({width: '5px', color: '#fff'})
                        .cx(shape.x() + shapeWidth / 2)
                        .cy(shape.y() + shapeHeight + shapeStrokeW)
                        .addClass('markerElement')

                    group6.add(marker6);
                    group6.add(marker06);
                }
    
                // this.state.draw.rect(5, 5)
                //     .stroke({width: `1px`, color: '#337AA6'})
                //     .fill({color: '#fff'})
                //     .attr({id: `m${2}`, class: 'marker right-diagonal-resize'})
                //     .cx(shape.x() + shapeWidth + shapeStrokeW)
                //     .cy(shape.y() - shapeStrokeW)
                //     .attr({'vector-effect': 'non-scaling-stroke'})

                let group2 = this.state.draw.group()
                    .id(`m${2}`)
                    .addClass('marker right-diagonal-resize')

                let marker2 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '7px', color: '#337AA6'})
                    .cx(shape.x() + shapeWidth + shapeStrokeW)
                    .cy(shape.y() - shapeStrokeW)
                    .addClass('markerElement')

                let marker02 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({id: `m${2}`, class: 'marker right-diagonal-resize marker-select'})
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '5px', color: '#fff'})
                    .cx(shape.x() + shapeWidth + shapeStrokeW)
                    .cy(shape.y() - shapeStrokeW)
                    .addClass('markerElement')

                group2.add(marker2);
                group2.add(marker02);
    
                // this.state.draw.rect(5, 5)
                //     .stroke({width: `1px`, color: '#337AA6'})
                //     .fill({color: '#fff'})
                //     .attr({id: `m${5}`, class: 'marker right-diagonal-resize'})
                //     .cx(shape.x() - shapeStrokeW)
                //     .cy(shape.y() + shapeHeight + shapeStrokeW)
                //     .attr({'vector-effect': 'non-scaling-stroke'})

                let group5 = this.state.draw.group()
                    .id(`m${5}`)
                    .addClass('marker right-diagonal-resize')

                let marker5 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '7px', color: '#337AA6'})
                    .cx(shape.x() - shapeStrokeW)
                    .cy(shape.y() + shapeHeight + shapeStrokeW)
                    .addClass('markerElement')

                let marker05 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({id: `m${5}`, class: 'marker right-diagonal-resize marker-select'})
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '5px', color: '#fff'})
                    .cx(shape.x() - shapeStrokeW)
                    .cy(shape.y() + shapeHeight + shapeStrokeW)
                    .addClass('markerElement')

                group5.add(marker5);
                group5.add(marker05);
    
                // this.state.draw.rect(5, 5)
                //     .stroke({width: `1px`, color: '#337AA6'})
                //     .fill({color: '#fff'})
                //     .attr({id: `m${7}`, class: 'marker left-diagonal-resize'})
                //     .cx(shape.x() + shapeWidth + shapeStrokeW)
                //     .cy(shape.y() + shapeHeight + shapeStrokeW)
                //     .attr({'vector-effect': 'non-scaling-stroke'})

                let group7 = this.state.draw.group()
                    .id(`m${7}`)
                    .addClass('marker left-diagonal-resize')

                let marker7 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '7px', color: '#337AA6'})
                    .cx(shape.x() + shapeWidth + shapeStrokeW)
                    .cy(shape.y() + shapeHeight + shapeStrokeW)
                    .addClass('markerElement')

                let marker07 = this.state.draw.path('M 50 50 l 0.0001 0')
                    .attr({id: `m${7}`, class: 'marker left-diagonal-resize marker-select'})
                    .attr({'vector-effect': 'non-scaling-stroke'})
                    .attr({'stroke-linecap': 'square'})
                    .stroke({width: '5px', color: '#fff'})
                    .cx(shape.x() + shapeWidth + shapeStrokeW)
                    .cy(shape.y() + shapeHeight + shapeStrokeW)
                    .addClass('markerElement')

                group7.add(marker7);
                group7.add(marker07);

                // if (shape.type === 'polygon') {
                //     this.state.draw.circle(5)
                //         .stroke({width: `1px`, color: '#337AA6'})
                //         .fill({color: '#fff'})
                //         .attr({id: `m${8}`, class: 'marker rotate-marker'})
                //         .cx(shape.cx())
                //         .cy(shape.cy() + shape.height() / 2 + 15)
                //         .attr({'vector-effect': 'non-scaling-stroke'})
                // }
            }
        }
    }

    // setDashedStroke = (shape, type) => {
    //     switch(type) {
    //         case 'dash':
    //             shape.attr({'stroke-dasharray': '10'});

    //             break;
    //         case 'point':
    //             shape.attr({'stroke-dasharray': '10 5 3 5'});
                
    //             break;
    //         case 'solid':
    //             shape.attr({'stroke-dasharray': null});

    //             break;
    //         default:
    //             shape.attr({'stroke-dasharray': null});
    //     }
    // }

    getLineCoords = (line) => {
        let lineCoords = [];
        lineCoords.push(line.attr('x1'));
        lineCoords.push(line.attr('y1'));
        lineCoords.push(line.attr('x2'));
        lineCoords.push(line.attr('y2'));

        return lineCoords;
    }

    getLineLengt = (line) => {
        let lineCoordsArr = this.getLineCoords(line);
        let length = (Math.sqrt((lineCoordsArr[2] -= lineCoordsArr[0]) * lineCoordsArr[2] + (lineCoordsArr[3] -= lineCoordsArr[1]) * lineCoordsArr[3]));
        let gridLength = this.state.moa ? length / this.state.moaAvg : length / this.state.milAvg;
        let res = gridLength.toFixed(1);

        return res;
    }

    getLineAngle = (lineCoordsArr) => {
        let lineAngle = Math.atan((lineCoordsArr[3] - lineCoordsArr[1]) / (lineCoordsArr[2] - lineCoordsArr[0])) * (180 / Math.PI)
        let res = parseFloat(Math.abs(lineAngle))

            return res;
    }

    // getPolyCoords = (poly) => {
    //     let coords = poly.node.attributes[0].nodeValue.split(' ');
    //     let polyCoords = [];

    //     coords.forEach(el => {
    //         polyCoords.push(el.split(','));
    //     })

    //     polyCoords.forEach(el => {
    //         el[0] = +el[0]
    //         el[1] = +el[1]
    //     })

    //     return polyCoords
    // }

    // Method for removeing all markers.

    markerCleaner = () => {
        this.markers = this.state.draw.find('.marker');

        this.markers.forEach(marker => {
            marker.remove()
        })

        if (this.state.currentMarker !== '') {
            this.setState({
                currentMarker: this.state.currentMarker = '',
                isMarkerEdit: this.state.isMarkerEdit = false
            })
        }
    }

    mouseSelectRenderCleaner = () => {
        const selectedRect = this.state.draw.find('.mouseSelect');

        if (selectedRect.length) {
            selectedRect.forEach(selectedEl => selectedEl.remove())
        }
    }

    rectMarkerReRender = (shape) => {
        if (shape) {
            if (shape.type === 'line') {
                let lineCoords = shape.array();
                const marker0 = this.state.draw.findOne('#m0');
                const marker5 = this.state.draw.findOne('#m5');
                const marker7 = this.state.draw.findOne('#m7');
                // const marker8 = this.state.draw.findOne('#m8');
    
                marker0.cx(lineCoords[0][0]);
                marker0.cy(lineCoords[0][1]);
    
                marker5.cx(shape.cx());
                marker5.cy(shape.cy());
    
                marker7.cx(lineCoords[1][0]);
                marker7.cy(lineCoords[1][1]);
    
                // marker8.cx(shape.cx());
                // marker8.cy(shape.cy() + shape.height() / 2 + 15);
            } else if (shape.type === 'circle') {
                const marker0 = this.state.draw.findOne('#m0');
                const marker2 = this.state.draw.findOne('#m2');
                const marker5 = this.state.draw.findOne('#m5');
                const marker7 = this.state.draw.findOne('#m7');
                const circleStrokeW = shape.attr('stroke-width') / 2;
    
                marker0.cx(shape.x() - circleStrokeW);
                marker0.cy(shape.y() - circleStrokeW);
    
                marker2.cx(shape.x() + shape.width() + circleStrokeW);
                marker2.cy(shape.y() - circleStrokeW);
    
                marker5.cx(shape.x() - circleStrokeW);
                marker5.cy(shape.y() + shape.height() + circleStrokeW);
    
                marker7.cx(shape.x() + shape.width() + circleStrokeW);
                marker7.cy(shape.y() + shape.height() + circleStrokeW);
            } else {
                const shapeStrokeW = shape.attr('stroke-width');
    
                if (shape.hasClass('square') || shape.hasClass('triangle')) {
                    const marker0 = this.state.draw.findOne('#m0');
                    const marker2 = this.state.draw.findOne('#m2');
                    const marker5 = this.state.draw.findOne('#m5');
                    const marker7 = this.state.draw.findOne('#m7');
                    // const marker8 = this.state.draw.findOne('#m8');
    
                    marker0.cx(shape.x() - shapeStrokeW);
                    marker0.cy(shape.y() - shapeStrokeW);
    
                    marker2.cx(shape.x() + shape.width() + shapeStrokeW);
                    marker2.cy(shape.y() - shapeStrokeW);
    
                    marker5.cx(shape.x() - shapeStrokeW);
                    marker5.cy(shape.y() + shape.height() + shapeStrokeW);
    
                    marker7.cx(shape.x() + shape.width() + shapeStrokeW);
                    marker7.cy(shape.y() + shape.height() + shapeStrokeW);
    
                    // if (!shape.hasClass('square')) {
                    //     marker8.cx(shape.cx());
                    //     marker8.cy(shape.cy() + shape.height() / 2 + 15);
                    // }
                } else {
                    const marker0 = this.state.draw.findOne('#m0');
                    const marker1 = this.state.draw.findOne('#m1');
                    const marker2 = this.state.draw.findOne('#m2');
                    const marker3 = this.state.draw.findOne('#m3');
                    const marker4 = this.state.draw.findOne('#m4');
                    const marker5 = this.state.draw.findOne('#m5');
                    const marker6 = this.state.draw.findOne('#m6');
                    const marker7 = this.state.draw.findOne('#m7');
            
                    marker0.cx(shape.x() - shapeStrokeW);
                    marker0.cy(shape.y() - shapeStrokeW);
    
                    marker1.cx(shape.x() + shape.width() / 2);
                    marker1.cy(shape.y() - shapeStrokeW);
    
                    marker2.cx(shape.x() + shape.width() + shapeStrokeW);
                    marker2.cy(shape.y() - shapeStrokeW);
            
                    marker3.cx(shape.x() - shapeStrokeW);
                    marker3.cy(shape.y() + shape.height() / 2);
            
                    marker4.cx(shape.x() + shape.width() + shapeStrokeW);
                    marker4.cy(shape.y() + shape.height() / 2);
            
                    marker5.cx(shape.x() - shapeStrokeW);
                    marker5.cy(shape.y() + shape.height() + shapeStrokeW);
            
                    marker6.cx(shape.x() + shape.width() / 2);
                    marker6.cy(shape.y() + shape.height() + shapeStrokeW);
            
                    marker7.cx(shape.x() + shape.width() + shapeStrokeW);
                    marker7.cy(shape.y() + shape.height() + shapeStrokeW);
                }
            }
        }
    }

    moveTool = (e) => {
        if (e.target.id !== `svgCanvas-$(this.props.activeTabId)` && e.target.id !== 'aimcross' && e.target.className.baseVal !== `patRec` && e.target.id !== 'selectRectangle' && e.target.className.baseVal !== 'markerElement') {
            this.state.draw.find('.selected').forEach(el => el.removeClass('selected'))
            this.bBoxCleaner();

            if (e.target.classList[0] !== 'marker') {
                this.markerCleaner();

                let select = this.state.draw.findOne(`#${e.target.id}`);
                
                if (select && select.hasClass('num-line')) {
                    select = select.parent();
                }

                if (!select.hasClass('mouseSelect')) {
                    this.deleteSelectedShapesRect('mouseSelect');

                    // this.selectedShapesBuffer = [];
                }

                // if (select && select.id() === 'selectRectangle') {
                //     select = '';
                // }

                if (e.ctrlKey || e.metaKey) {
                    if (!this.state.isGroupingActive) {
                        this.g = [];
                        
                        this.setState({
                            isGroupingActive: this.state.isGroupingActive = true,
                        })
                    }

                    let groupEl = { id: select.id(), name: select.id() }
                    this.groupElements.push(groupEl)

                    this.props.getGroupedData(this.state.isGroupingActive);

                    if (this.state.isGroupingActive) {
                        let elArr = [];
                        this.groupedSet.add(select);
                        this.groupedSet.forEach(el => el.addClass('selected'));

                        this.groupedSet.forEach(el => {
                            // this.setState({
                            //     currentGroup: this.state.currentGroup = this.state.currentGroup.add(el),
                            // })
                            elArr.push(el);
                        })

                        this.setState({
                            selectedShapes: this.state.selectedShapes = elArr
                        })

                        // let buff = this.state.currentShapesID
                        // let index = 0

                        // for (let i = 0; i < buff.length; i++) {
                        //     buff[i].id === select.id() ? index = i : index = -1
                        // }

                        // for (let i = 0; i < buff.length; i++) {
                        //     if (buff[i].id === this.state.currentGroup.id()) {
                        //         buff[i].gElements.push({id: select.id(), name: select.id()});
                        //     }
                        // }

                        // let newBuff = buff.filter(el => el.id !== select.id())
                        
                        // this.setState({
                        //     currentShapesID: this.state.currentShapesID = newBuff
                        // })
                    }
                }

                if (this.state.isShiftPressed) {
                    if (select.parent().type === 'g') {
                        this.selectedShapes.add(select.parent());
                    } else {
                        this.selectedShapes.add(select);
                    }
                    
                    for (let shape of this.selectedShapes) {
                        this.setState({
                            selectedShape: this.state.selectedShape = shape
                        })

                        shape.addClass('selected');
                        this.bBoxRender();
                    }

                    // this.state.selectedShapes.forEach(el => {
                    //     this.setState({
                    //         selectedShape: this.state.selectedShape = el
                    //     })
                    //     el.addClass('selected')
                    //     this.bBoxRender()
                    // })
                } else {
                    this.selectedShapes.clear()
                    this.selectedShapes.add(select)
                }

                let shapes = []

                this.selectedShapes.forEach(shape => {
                    shapes.push(shape)
                    shape.addClass('selected')
                })

                this.setState({
                    selectedShape: this.state.selectedShape = select,
                    selectedShapes: this.state.selectedShapes = shapes,
                    alignedShape: this.state.alignedShape = this.state.isGroupingActive ? this.g : select,
                    groupedShapes: this.state.groupedShapes = this.groupedSet,
                })

                if (select.hasClass('mouseSelect')) {
                    this.setState({
                        selectedShapes: this.state.selectedShapes = this.selectedShapesBuffer
                    })
                }

                // this.clearSelectedShapesRect('mouseSelect');

                this.props.getSelectedShapes(this.state.selectedShapes);

                let parent = select.parent()

                if (parent.type === 'g') {
                    this.g = parent
                    this.setState({
                        isGroupingActive: this.state.isGroupingActive = true
                    })
                } else {
                    this.setState({
                        isGroupingActive: this.state.isGroupingActive = false
                    })
                }

                this.props.getSelectedShape(this.state.selectedShape);
                this.props.getSelectedShapes(this.state.selectedShapes);
            } else {
                let markers = this.state.draw.find('.marker-select');
                // Reset fill color for not selected markers
                markers.forEach(marker => marker.stroke({'color': '#fff'}));

                let marker = this.state.draw.findOne(`#${e.target.id}`);
                marker.each((i, child) => {
                    if (child[i].hasClass('marker-select')) {
                        child[i].stroke({'color': '#337AA6'});
                    }
                })
                marker.fill('#337AA6');

                this.setState({
                    currentMarker: this.state.currentMarker = marker,
                    isMarkerEdit: this.state.isMarkerEdit = true
                })
            }

            this.state.selectedShape.addClass('selected')

            // const elParent = this.state.selectedShape.parent();

            if (e.target.classList[0] !== 'marker') {
                let elParent = ''

                if (this.state.selectedShape) {
                    elParent = this.state.selectedShape.parent()

                    if (elParent.type === 'g') {
                        this.setState({
                            selectedShape: this.state.selectedShape = elParent
                        })
                    } else {
                        elParent = ''
                    }
                }

                this.markerCleaner();
                this.addMarkersToSelected(this.state.selectedShape);

                if (this.state.selectedShape.type === 'circle' && elParent.type !== 'g') {
                    let marker = document.querySelector(`#${e.target.id}`);
                    
                    this.setState({
                        prevOpacity: this.state.prevOpacity = marker.getAttribute('fill-opacity'),
                        prevFillColor: this.state.prevFillColor = marker.getAttribute('fill'),
                    })

                    // this.state.selectedShape.fill({ fill: this.state.fillColor, opacity: this.state.prevOpacity > 0.8 ? 0.2 : this.state.prevOpacity });
                }
            }

        } else {
            this.markerCleaner();
            this.bBoxCleaner();

            this.setState({
                selectedShape: this.state.selectedShape = '',
                selectedShapes: this.state.selectedShapes = [],
                isSelected: this.state.isSelected = false,
                isGroupingActive: this.state.isGroupingActive = false,
            })

            this.selectedShapesBuffer = [];
            this.selectedShapes.clear();
            this.groupedSet.clear();
            this.state.draw.find('.selected').forEach(el => el.removeClass('selected'));
            this.props.getSelectedShapes(this.state.selectedShapes);
        }
    }

    idCounter = () => {
        // let nameCount = 0;
        let lineNunb = 0;
        let circleNumb = 0;
        let squareNumb = 0;
        let triangleNumb = 0;
        let rectNumb = 0;
        let numNumb = 0;

        this.state.draw.each(function(i, children) {
            if (children[i].data('layername')) {
                if (children[i].data('layername').includes('line-')) {
                    let lNum = children[i].data('layername').replace('line-', '');

                    if (lNum > lineNunb) {
                        lineNunb = +lNum;
                    }
                }

                if (children[i].data('layername').includes('circle-')) {
                    let cNum = children[i].data('layername').replace('circle-', '');

                    if (cNum > circleNumb) {
                        circleNumb = +cNum;
                    }
                }

                if (children[i].data('layername').includes('square-')) {
                    let sNum = children[i].data('layername').replace('square-', '');

                    if (sNum > squareNumb) {
                        squareNumb = +sNum;
                    }
                }

                if (children[i].data('layername').includes('triangle-')) {
                    let tNum = children[i].data('layername').replace('triangle-', '');

                    if (tNum > triangleNumb) {
                        triangleNumb = +tNum;
                    }
                }

                if (children[i].data('layername').includes('rect-')) {
                    let rNum = children[i].data('layername').replace('rect-', '');

                    if (rNum > rectNumb) {
                        rectNumb = +rNum;
                    }
                }

                if (children[i].data('layername').includes('num-')) {
                    let nNum = children[i].data('layername').replace('num-', '');

                    if (nNum > numNumb) {
                        numNumb = +nNum;
                    }
                }

                // let elName = children[i].data('layername').replace('circle-', '')
                //     .replace('line-', '')
                //     .replace('triangle-', '')
                //     .replace('square-', '')
                //     .replace('rect-', '')
                //     .replace('num-', '')

                // let elNum = +elName;

                // if (children[i].type === 'g') {
                //     children[i].each(function(item, gChildren) {
                //         if (gChildren[item].data('layername')) {
                //             elName = gChildren[item].data('layername').replace('circle-', '')
                //                 .replace('line-', '')
                //                 .replace('triangle-', '')
                //                 .replace('square-', '')
                //                 .replace('rect-', '')
                //                 .replace('num-', '')
                //         }
                //     })

                //     elNum = +elName;
                // }

                // if (elNum > nameCount) {
                //     nameCount = elNum
                // }
            }
        })

        // this.setState({
        //     i: this.state.i = nameCount + 1
        // })

        this.setState({
            lineNum: this.state.lineNum = lineNunb + 1,
            circleNum: this.state.circleNum = circleNumb + 1,
            squareNum: this.state.squareNum = squareNumb + 1,
            triangleNum: this.state.triangleNum = triangleNumb + 1,
            rectNum: this.state.rectNum = rectNumb + 1,
            numberNum: this.state.numberNum = numNumb + 1
        })
    }

    createCircle = (radius, x, y) => {
        let cid = (Math.random() * 100000).toFixed(0);
        
        this.idCounter();

        this.state.draw.circle(radius)
            .radius(radius)
            .stroke({ width: this.state.moa ? this.state.strokeWidth * 3 : this.state.strokeWidth * 10, color: this.state.strokeColor})
            .fill({ color: (radius === this.state.moaAvg || radius === this.state.milAvg) ? '#000' : this.state.fillColor, opacity: (radius === this.state.moaAvg || radius === this.state.milAvg) ? 1 : this.state.opacity / 100})
            // .attr({id: `circle-${i}`, class: 'circle'})
            .attr({id: `circle-${cid}`, class: 'circle'})
            .center(x, y)
            .data('layername', `circle-${this.state.circleNum}`)
            .attr({'stroke-dasharray': this.state.strokeDasharray})
            .add(`<title>${this.state.fpValue}</title>`)

        // this.setState({
        //     currentShapesID: [...this.state.currentShapesID, {id: `circle-${cid}`, name: `circle-${this.state.i}`}],
        // })

        this.updateCanvasElementsName();
        // this.sortLayesrs();
    }
    
    createSimpleLine = (x, y, x2, y2) => {
        let lid = (Math.random() * 100000).toFixed(0);
        this.idCounter();

        this.state.draw.line(x, y, x2, y2)
            .stroke({ width: this.state.moa ? this.state.strokeWidth * 3 : this.state.strokeWidth * 10, color: this.state.strokeColor })
            .fill('none')
            // .attr({id: `line-${i}`, class: 'line'})
            .attr({id: `line-${lid}`, class: 'line'})
            .data('layername', `line-${this.state.lineNum}`)
            .attr({'stroke-dasharray': this.state.strokeDasharray})
            .add(`<title>${this.state.fpValue}</title>`)
            .data('axis', this.state.axisLine ? 'axis' : null)
        
        // this.updateCanvasElementsName();
    }

    createRectangle = (x, y, w, h, rtype='square') => {
        let rid = (Math.random() * 100000).toFixed(0);
        this.idCounter();

        if (this.state.currentTool === 'square') {
            this.state.draw.rect(w, w)
                .move(x, y)
                .stroke({ width: this.state.moa ? this.state.strokeWidth * 3 : this.state.strokeWidth * 10, color: this.state.strokeColor })
                .fill({ color: this.state.fillColor, opacity: this.state.opacity / 100})
                .attr({
                    id: this.state.currentTool === 'square' ? `square-${rid}` : `rect-${rid}`,
                    class: this.state.currentTool === 'square' ? 'square' : 'rectangle',
                })
                .data('layername', this.state.currentTool === 'square' ? `square-${this.state.squareNum}` : `rect-${this.state.rectNum}`)
                .attr({'stroke-dasharray': this.state.strokeDasharray})
                .add(`<title>${this.state.fpValue}</title>`)
        } else if (this.state.currentTool === 'rectangle') {
            this.state.draw.rect(w, h)
                .move(x, y)
                .stroke({ width: this.state.moa ? this.state.strokeWidth * 3 : this.state.strokeWidth * 10, color: this.state.strokeColor })
                .fill({ color: this.state.fillColor, opacity: this.state.opacity / 100})
                .attr({
                    id: this.state.currentTool === 'square' ? `square-${rid}` : `rect-${rid}`,
                    class: this.state.currentTool === 'square' ? 'square' : 'rectangle'
                })
                .data('layername', this.state.currentTool === 'square' ? `square-${this.state.squareNum}` : `rect-${this.state.rectNum}`)
                .attr({'stroke-dasharray': this.state.strokeDasharray})
                .add(`<title>${this.state.fpValue}</title>`)
        } else {
            this.state.draw.rect(w, h)
                .move(x, y)
                .stroke({ width: this.state.moa ? this.state.strokeWidth * 3 : this.state.strokeWidth * 10, color: this.state.strokeColor })
                .fill({ color: this.state.fillColor, opacity: this.state.opacity / 100})
                .attr({
                    id: rtype === 'square' ? `square-${rid}` : `rect-${rid}`,
                    class: rtype === 'square' ? 'square' : 'rectangle'
                })
                .data('layername', rtype === 'square' ? `square-${this.state.squareNum}` : `rect-${this.state.rectNum}`)
                .attr({'stroke-dasharray': this.state.strokeDasharray})
                .add(`<title>${this.state.fpValue}</title>`)
        }

        this.updateCanvasElementsName();
        // this.sortLayesrs();
    }

    createTriangle = (x, y, direction) => {
        let tid = (Math.random() * 100000).toFixed(0);
        let coords = [];
        let gStep = this.state.rectW;

        this.idCounter();
        
        switch (direction) {
            case 'top':
                coords.push([x, y]);
                coords.push([x + gStep, y + gStep]);
                coords.push([x - gStep, y + gStep]);
                break
            case 'right':
                coords.push([x, y]);
                coords.push([x - gStep, y + gStep]);
                coords.push([x - gStep, y - gStep]);
                break
            case 'bottom':
                coords.push([x, y]);
                coords.push([x - gStep, y - gStep]);
                coords.push([x + gStep, y - gStep]);
                break
            case 'left':
                coords.push([x, y]);
                coords.push([x + gStep, y - gStep]);
                coords.push([x + gStep, y + gStep]);
                break
            default:
                coords.push([x, y]);
                coords.push([x + gStep, y + gStep]);
                coords.push([x - gStep, y + gStep]);
                break
        }

        this.state.draw.polygon(coords)
            .stroke({ width: this.state.moa ? this.state.strokeWidth * 3 : this.state.strokeWidth * 10, color: this.state.strokeColor })
            .fill({ color: this.state.fillColor, opacity: this.state.opacity / 100})
            .attr({id: `triangle-${tid}`, class: 'triangle'})
            .data('layername', `triangle-${this.state.triangleNum}`)
            .data('direction', direction ? `${direction}` : 'top')
            .attr({'stroke-dasharray': this.state.strokeDasharray})
            .add(`<title>${this.state.fpValue}</title>`)

        this.updateCanvasElementsName();
        // this.sortLayesrs();
    }

    importLine = (x1, y1, x2, y2, strokeColor, strokeW, strokeDash, fpVal='42') => {
        let lid = (Math.random() * 100000).toFixed(0);

        this.idCounter();

        this.state.draw.line(x1, y1, x2, y2)
            .stroke({ width: strokeW, color: strokeColor })
            .fill('none')
            .attr({id: `line-${lid}`, class: 'line'})
            .data('layername', `line-${this.state.lineNum}`)
            .attr({'stroke-dasharray': strokeDash})
            .add(`<title>${fpVal}</title>`)
        
        // this.updateCanvasElementsName();
    }

    importCircle = (cx, cy, r, fillColor, fillOpacity, strokeColor, strokeW, strokeDash, fpVal='42') => {
        let cid = (Math.random() * 100000).toFixed(0);

        this.idCounter();

        this.state.draw.circle(r)
            .radius(r)
            .stroke({ width: strokeW, color: strokeColor})
            .fill({ color: fillColor, opacity: fillOpacity })
            .attr({id: `circle-${cid}`, class: 'circle'})
            .center(cx, cy)
            .data('layername', `circle-${this.state.circleNum}`)
            .attr({'stroke-dasharray': strokeDash})
            .add(`<title>${fpVal}</title>`)

        // this.updateCanvasElementsName();
    }

    importRectangle = (x, y, w, h, rType, fillColor, fillOpacity, strokeW, strokeColor, strokeDash, fpVal='42') => {
        let rid = (Math.random() * 100000).toFixed(0);

        this.idCounter();

        this.state.draw.rect(w, h)
            .move(x, y)
            .stroke({ width: strokeW, color: strokeColor })
            .fill({ color: fillColor, opacity: fillOpacity })
            .attr({
                id: rType === 'square' ? `square-${rid}` : `rect-${rid}`,
                class: rType === 'square' ? 'square' : 'rectangle'
            })
            .data('layername', rType === 'square' ? `square-${this.state.squareNum}` : `rect-${this.state.rectNum}`)
            .attr({'stroke-dasharray': strokeDash})
            .add(`<title>${fpVal}</title>`)
    }

    importTriangle = (coords, direction, fillColor, fillOpacity, strokeColor, strokeW, strokeDash, fpVal='42') => {
        let tid = (Math.random() * 100000).toFixed(0);

        this.idCounter();

        this.state.draw.polygon(coords)
            .stroke({ width: strokeW, color: strokeColor })
            .fill({ color: fillColor, opacity: fillOpacity})
            .attr({id: `triangle-${tid}`, class: 'triangle'})
            .data('layername', `triangle-${this.state.triangleNum}`)
            .data('direction', direction)
            .attr({'stroke-dasharray': strokeDash ? strokeDash : null})
            .add(`<title>${fpVal}</title>`)

        // this.updateCanvasElementsName();
    }

    roundPolygonCoords = (poly) => {
        let polyCoordsArr = poly.array();

        polyCoordsArr.forEach(arr => {
            let nx = Math.round(arr[0]);
            let ny = Math.round(arr[1]);
            arr[0] = nx;
            arr[1] = ny;
        })

    }

    createArabicNumber = (x, y, num, size, id=0) => {
        let nid = (Math.random() * 100000).toFixed(0);
        const numID = id ? id : `arabicNumGroup-${nid}`;

        this.idCounter();

        let numGroup = this.state.draw.group()
            .id(numID)
            .addClass(`number-group num-${num}`)
            .data('layername', `num-${this.state.numberNum}`)

        let l = Number(size);

        switch (num) {
            case '1':
                this.state.draw.line(x, y, x, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-1a-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y, x + l, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor, opacity: 0 })
                    .addTo(numGroup)
                    .id(`num-1b-${nid}`)
                    .addClass('num-line')
                    // .add(`<title>${this.state.fpValue}</title>`)
                break
            case '2':
                this.state.draw.line(x, y, x + l, y)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-2a-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y, x + l, y + l / 2)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-2b-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y + l / 2, x, y + l / 2)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-2c-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x, y + l / 2, x, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-2d-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x, y + l, x + l, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-2e-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)
                break
            case '3':
                this.state.draw.line(x, y, x + l, y)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-3a-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y, x + l, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-3b-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y + l / 2, x, y + l / 2)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-3c-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y + l, x, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-3d-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)
                break
            case '4':
                this.state.draw.line(x, y, x, y + l / 2)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-4a-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x, y + l / 2, x + l, y + l / 2)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-4b-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y, x + l, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-4c-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)
                break
            case '5':
                this.state.draw.line(x, y, x + l, y)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-5a-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x, y, x, y + l / 2)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-5b-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x, y + l / 2, x + l, y + l / 2)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-5c-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y + l / 2, x + l, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-5d-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y + l, x, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-5e-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)
                break
            case '6':
                this.state.draw.line(x, y, x + l, y)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-6a-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x, y, x, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-6b-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x, y + l / 2, x + l, y + l / 2)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-6c-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y + l / 2, x + l, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-6d-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y + l, x, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-6e-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)
                break
            case '7':
                this.state.draw.line(x, y, x + l, y)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-7a-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y, x + l, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-7b-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)
                break
            case '8':
                this.state.draw.line(x, y, x + l, y)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-8a-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x, y + l / 2, x + l, y + l / 2)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-8b-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x, y + l, x + l, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-8c-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x, y, x, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-8d-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y, x + l, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-8e-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)
                break
            case '9':
                this.state.draw.line(x, y, x + l, y)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-9a-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x, y + l / 2, x + l, y + l / 2)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-9b-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x, y, x, y + l / 2)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-9c-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y, x + l, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-9d-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y + l, x, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-9e-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)
                break
            case '0':
                this.state.draw.line(x, y, x + l, y)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-10a-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x, y + l, x + l, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-10b-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x, y, x, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-10c-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y, x + l, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-10d-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)
                break
            default:
                this.setState({
                    romNumber: ''
                })
        }

        this.updateCanvasElementsName();
        // this.sortLayesrs();
    }

    editNumber = (number, size) => {
        const numID = number.id();
        const numClassArr = number.classes();
        let nominal = numClassArr[1][numClassArr[1].length - 1];
        const numName = number.data('layername');
        const numX = number.x();
        const numY = number.y();
        const nextShape = number.next();

        const numLinesArr = number.children();

        const lineColor = numLinesArr.length ? numLinesArr[0].attr('stroke') : '#000000';

        number.remove();

        if (numID.includes('arabicNumGroup')) {
            this.createArabicNumber(numX, numY, nominal, size, numID);
        } else {
            if (nominal === '0') {
                nominal = '10'
            }

            this.createNumber(numX, numY, nominal, size, numID);
        }

        const newNum = this.state.draw.findOne(`#${numID}`);
        
        this.setState({
            selectedShape: this.state.selectedShape = newNum,
            selectedShapes: this.state.selectedShapes = [newNum]
        })

        newNum.data('layername', numName);

        if (nextShape && (newNum.type === 'line' || newNum.type === 'g' || newNum.type === 'circle' || newNum.type === 'rect' || newNum.type === 'polygon')) {
            newNum.insertBefore(nextShape);
        }

        newNum.each(function(i, children) {
            this.stroke({ color: lineColor })
        })

        this.props.getSelectedShape(this.state.selectedShape);
        this.props.getSelectedShapes(this.state.selectedShapes);
        this.updateCanvasElementsName();
        // this.sortLayesrs();
    }

    createNumber = (x, y, num, size, id=0) => {
        let nid = (Math.random() * 100000).toFixed(0);
        const numID = id ? id : `romNumGroup-${nid}`;

        this.idCounter();
        
        let numGroup = this.state.draw.group()
            .id(numID)
            .addClass(`number-group num-${num}`)
            .data('layername', `num-${this.state.numberNum}`)

        // let l = this.state.moa ? this.state.moaAvg * 3 : this.state.milAvg / 2;
        let l = Number(size);

        switch (num) {
            case '1':
                this.state.draw.line(x, y, x, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-1a-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y, x + l, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor, opacity: 0 })
                    .addTo(numGroup)
                    .id(`num-1b-${nid}`)
                    .addClass('num-line')
                break
            case '2':
                this.state.draw.line(x, y, x, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-2a-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y, x + l, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-2b-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)
                break
            case '3':
                this.state.draw.line(x, y, x, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-3a-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l / 2, y, x + l / 2, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-3b-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y, x + l, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-3c-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)
                break
            case '4':
                this.state.draw.line(x, y, x, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-4a-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l / 2, y, x + l / 1.3, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-4b-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l / 1.3, y + l, x + l, y)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-4c-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)
                break
            case '5':
                this.state.draw.line(x, y, x + l / 2, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-5a-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l / 2, y + l, x + l, y)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-5b-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)
                break
            case '6':
                this.state.draw.line(x, y, x + l / 4, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-6a-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l / 4, y + l, x + l / 2, y)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-6b-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y, x + l, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-6c-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)
                break
            case '7':
                this.state.draw.line(x, y, x + l / 4, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-7a-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l / 4, y + l, x + l / 2, y)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-7b-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l / 1.3, y, x + l / 1.3, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-7c-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y, x + l, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-7d-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)
                break
            case '8':
                this.state.draw.line(x, y, x + l / 4, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-8a-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l / 4, y + l, x + l / 2, y)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-8b-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l / 1.5, y, x + l / 1.5, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-8c-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l / 1.2, y, x + l / 1.2, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-8d-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l, y, x + l, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-8e-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)
                break
            case '9':
                this.state.draw.line(x, y, x, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-9a-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l / 2, y, x + l, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-9b-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x + l / 2, y + l, x + l, y)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-9c-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)
                break
            case '10':
                this.state.draw.line(x, y, x + l, y + l)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-10a-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)

                this.state.draw.line(x, y + l, x + l, y)
                    .stroke({ width: 1, color: this.state.strokeColor })
                    .addTo(numGroup)
                    .id(`num-10b-${nid}`)
                    .addClass('num-line')
                    .add(`<title>${this.state.fpValue}</title>`)
                break
            default:
                this.setState({
                    romNumber: ''
                })
        }

        // numGroup.size(size, size);

        this.updateCanvasElementsName();
        // this.sortLayesrs();
    }

    removeEmptyGroups = () => {
        const grps = this.state.draw.find('.number-group');

        if (grps.length) {
            grps.forEach(grp => {
                let grpChilds = grp.children();

                if (grpChilds.length) {
                    grpChilds.forEach(child => {
                        if (child.type === 'title') {
                            child.remove();
                        }
                    })
                } else {
                    grp.remove();
                }
            })
        }
    }

    createSelectRectangle = (x, y, w, h) => {
        this.state.draw.rect(w, h)
            .move(x, y)
            .stroke({width: 1, color: '#337AA6'})
            .fill({ color: '#337AA6', opacity: 0.1})
            .attr({'vector-effect': 'non-scaling-stroke'})
            .id('selectRectangle')
            .addClass('mouseSelect')
    }

    createSelectedShapesRect = (x, y, w, h) => {
        this.state.draw.rect(w, h)
            .move(x, y)
            .stroke({width: 1, color: '#383844', dasharray: '5, 3'})
            .fill({ color: '#fff', opacity: 0.1})
            .attr({'vector-effect': 'non-scaling-stroke'})
            .id('selectedShapesRectangle')
            .addClass('mouseSelect')
    }

    clearSelectedShapesRect = (classStr) => {
        const selectRect = this.state.draw.find(`.${classStr}`);

        for (let i = 1; i < selectRect.length; i++) {
            selectRect[i].remove();
        }
    }

    deleteSelectedShapesRect = (classStr) => {
        const selectRect = this.state.draw.find(`.${classStr}`);

        for (let i = 0; i < selectRect.length; i++) {
            selectRect[i].remove();
        }

        this.selectedShapesBuffer = [];
    }

    triangleDirectionEditer = (trianglePoly, coordsArr) => {
        if ((coordsArr[0][0] < coordsArr[1][0]) && (coordsArr[0][0] > coordsArr[2][0])) {
            trianglePoly.data('direction', 'top');
        }

        if ((coordsArr[0][0] > coordsArr[1][0]) && (coordsArr[0][0] > coordsArr[2][0])) {
            trianglePoly.data('direction', 'rigth');
        }

        if ((coordsArr[0][0] > coordsArr[1][0]) && (coordsArr[0][0] < coordsArr[2][0])) {
            trianglePoly.data('direction', 'bottom');
        }

        if ((coordsArr[0][0] < coordsArr[1][0]) && (coordsArr[0][0] < coordsArr[2][0])) {
            trianglePoly.data('direction', 'left');
        }
    }

    strokeColorGroupPainter = (g, color) => {
        if (g.type === 'g') {
            g.each(function(i, children) {
                this.stroke({color: `${color}`})
            })
        }
    }

    selectedShapesTypeChecker = (shapes) => {
        if (shapes.length) {
            let res = false;

            for (let shape of shapes) {
                if (shape.type === 'line' || shape.type === 'g') {
                    res = false;
    
                    break;
                } else {
                    res = true;
                }
            }

            if (this.state.isAllShapesFill !== res) {
                this.setState({
                    isAllShapesFill: res
                })

                this.props.getSelectedShapesType(res);
            }
        } else {
            if (this.state.isAllShapesFill) {
                this.setState({
                    isAllShapesFill: false
                })

                this.props.getSelectedShapesType(false);
            }
        }
    }

    rotateLine = (line, cx, cy, deg) => {
        const PI = Math.PI;
        // const radian = deg * (PI / 180);
        const radian = deg * (PI / 180);
        // const negativeRadian = -90 * (PI / 180);
        const rotatePointX = line.cx();
        const rotatePointY = line.cy();

        // this.rStep = 0;

        let oldX1 = line.attr('x1');
        let oldY1 = line.attr('y1');
        let oldX2 = line.attr('x2');
        let oldY2 = line.attr('y2');

        //  Convert line coords to rotate points type
        let newX1 = oldX1 - rotatePointX;
        let newY1 = oldY1 - rotatePointY;
        let newX2 = oldX2 - rotatePointX;
        let newY2 = oldY2 - rotatePointY;

        //  Rotate line coords
        let rotateX1 = Math.round(newX1 * Math.cos(radian) - newY1 * Math.sin(radian));
        let rotateY1 = Math.round(newX1 * Math.sin(radian) + newY1 * Math.cos(radian));
        let rotateX2 = Math.round(newX2 * Math.cos(radian) - newY2 * Math.sin(radian));
        let rotateY2 = Math.round(newX2 * Math.sin(radian) + newY2 * Math.cos(radian));

        //  Edit line with rotated coords
        line.plot(rotateX1, rotateY1, rotateX2, rotateY2);
        line.center(cx, cy);

        this.roundLineCoords(line);

        // this.rStep += deg;

        // if (this.rStep >= 5) {
        //     this.rStep = 0;

            //  Rotate line coords
            // let rotateX1 = Math.round(newX1 * Math.cos(radian) - newY1 * Math.sin(radian));
            // let rotateY1 = Math.round(newX1 * Math.sin(radian) + newY1 * Math.cos(radian));
            // let rotateX2 = Math.round(newX2 * Math.cos(radian) - newY2 * Math.sin(radian));
            // let rotateY2 = Math.round(newX2 * Math.sin(radian) + newY2 * Math.cos(radian));
    
            //  Edit line with rotated coords
        //     line.plot(rotateX1, rotateY1, rotateX2, rotateY2);
        //     line.center(cx, cy);
        // }

        // if (this.rStep <= -5) {
        //     this.rStep = 0;

            //  Rotate line coords
            // let rotateX1 = Math.round(newX1 * Math.cos(negativeRadian) - newY1 * Math.sin(negativeRadian));
            // let rotateY1 = Math.round(newX1 * Math.sin(negativeRadian) + newY1 * Math.cos(negativeRadian));
            // let rotateX2 = Math.round(newX2 * Math.cos(negativeRadian) - newY2 * Math.sin(negativeRadian));
            // let rotateY2 = Math.round(newX2 * Math.sin(negativeRadian) + newY2 * Math.cos(negativeRadian));
    
            //  Edit line with rotated coords
        //     line.plot(rotateX1, rotateY1, rotateX2, rotateY2);
        //     line.center(cx, cy);
        // }

        if (this.state.isSvgActive) {
            this.props.getCurrentID(this.state.shapeNames);
            this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());
        }
    }

    roundLineCoords = (line) => {
        let coords = line.plot();

        let roundedCoords = coords.map(coord => {
            return [Math.round(coord[0]), Math.round(coord[1])]
        });

        line.plot(roundedCoords);
    }

    rotateTriangle = (triangle, cx, cy, rotateType) => {
        try {
            const currentTriangleDirection = triangle.data('direction');
            const directionIndex = this.trianglesTypes.indexOf(currentTriangleDirection);
            const triangleColor = triangle.fill();
            const triangleOpacity = triangle.attr('fill-opacity');
            const triangleStrokeColor = triangle.stroke();
            const triangleStrokeW = triangle.attr('stroke-width');
            const triangleName = triangle.data('layername');
            const nextShape = triangle.next();
            const triangleW = triangle.width();
            const triangleH = triangle.height();
            const triangleCoords = triangle.plot();

            let nextDirection = 'top';

            if (rotateType === 'rotateRight') {
                nextDirection = directionIndex <  3 ?
                    this.trianglesTypes[directionIndex + 1] : this.trianglesTypes[0];
            } else {
                nextDirection = directionIndex >  0 ?
                    this.trianglesTypes[directionIndex - 1] : this.trianglesTypes[3];
            }
            
            triangle.remove();
            
            this.createTriangle(this.state.gridX, this.state.gridY, nextDirection);
            
            const newTriangle = this.state.draw.last();
    
            switch(nextDirection) {
                case 'top':
                    newTriangle.width(triangleH);
                    newTriangle.height(triangleW);
                    break;
                case 'right':
                    newTriangle.width(triangleH);
                    newTriangle.height(triangleW);
                    break;
                case 'bottom':
                    newTriangle.width(triangleH);
                    newTriangle.height(triangleW);
                    break;
                case 'left':
                    newTriangle.width(triangleH);
                    newTriangle.height(triangleW);
                    break;
                default:
                    newTriangle.width(triangleW);
                    newTriangle.height(triangleH);
            }
    
            newTriangle.center(cx, cy);
            newTriangle.fill({ color: triangleColor, opacity: triangleOpacity });
            newTriangle.stroke({ color: triangleStrokeColor, width: triangleStrokeW });
            newTriangle.data('layername', triangleName);
    
            if (nextShape && (nextShape.type === 'line' || nextShape.type === 'g' || nextShape.type === 'circle' || nextShape.type === 'rect' || nextShape.type === 'polygon')) {
                newTriangle.insertBefore(nextShape);
            }

            this.setState({
                selectedShape: this.state.selectedShape = newTriangle,
                selectedShapes: this.state.selectedShapes = [newTriangle, ]
            })
    
            this.bBoxCleaner();
            this.bBoxRender();
            this.markerCleaner();
    
            this.updateCanvasElementsName();
            this.addMarkersToSelected(this.state.selectedShape);
    
            this.props.getSelectedShape(this.state.selectedShape);
            this.props.getSelectedShapes(this.state.selectedShapes);
    
            if (this.state.isSvgActive) {
                this.props.getCurrentID(this.state.shapeNames);
                this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());
            }
        } catch(e) {
        }
    }

    // rotateTriangle = (triangle, cx, cy, deg) => {
    //     const PI = Math.PI;
    //     // const radian = deg * (PI / 180);
    //     const radian = deg * (PI / 180);
    //     // const negativeRadian = -90 * (PI / 180);
    //     const rotatePointX = cx;
    //     const rotatePointY = cy;

    //     // this.rStep = 0;

    //     let oldCoordsArr = triangle.array();

    //     // this.rStep += deg;

    //     //  Convert triangle coords to rotate points type
    //     let newCoordsArr = oldCoordsArr.map(el => {
    //         let x = el[0] - rotatePointX;
    //         let y = el[1] - rotatePointY;

    //         return [x, y];
    //     })

    //     let rotateCoordsArr = newCoordsArr.map(el => {
    //         let x = Math.round(el[0] * Math.cos(radian) - el[1] * Math.sin(radian));
    //         let y = Math.round(el[0] * Math.sin(radian) + el[1] * Math.cos(radian));

    //         return [x, y];
    //     })

    //     // let rotateCoordsArr = newCoordsArr.map(el => {
    //     //     let x = el[0] * Math.cos(radian) - el[1] * Math.sin(radian);
    //     //     let y = el[0] * Math.sin(radian) + el[1] * Math.cos(radian);

    //     //     return [x, y];
    //     // })

    //     triangle.plot(rotateCoordsArr);
    //     triangle.center(cx, cy);

    //     this.triangleDirectionEditer(triangle, rotateCoordsArr);

    //     // if (this.rStep >= 3) {
    //     //     this.rStep = 0;

    //         // let rotateCoordsArr = newCoordsArr.map(el => {
    //         //     let x = Math.round(el[0] * Math.cos(radian) - el[1] * Math.sin(radian));
    //         //     let y = Math.round(el[0] * Math.sin(radian) + el[1] * Math.cos(radian));
    
    //         //     return [x, y];
    //         // })

    //         // triangle.plot(rotateCoordsArr);
    //         // triangle.center(cx, cy);

    //         // this.triangleDirectionEditer(triangle, rotateCoordsArr);
    //     // }

    //     // if (this.rStep <= -3) {
    //     //     this.rStep = 0;

    //     //     let rotateCoordsArr = newCoordsArr.map(el => {
    //     //         let x = Math.round(el[0] * Math.cos(negativeRadian) - el[1] * Math.sin(negativeRadian));
    //     //         let y = Math.round(el[0] * Math.sin(negativeRadian) + el[1] * Math.cos(negativeRadian));
    
    //     //         return [x, y];
    //     //     })

    //     //     triangle.plot(rotateCoordsArr);
    //     //     triangle.center(cx, cy);

    //     //     this.triangleDirectionEditer(triangle, rotateCoordsArr);
    //     // }

    //     // //  Rotate triangle coords
    //     // let rotateCoordsArr = newCoordsArr.map(el => {
    //     //     let x = el[0] * Math.cos(radian) - el[1] * Math.sin(radian);
    //     //     let y = el[0] * Math.sin(radian) + el[1] * Math.cos(radian);

    //     //     return [x, y];
    //     // })

    //     // //  Edit triangle with rotated coords
    //     // triangle.plot(rotateCoordsArr);
    //     // triangle.center(cx, cy);

    //     if (this.state.isSvgActive) {
    //         this.props.getCurrentID(this.state.shapeNames);
    //         this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());
    //     }
    // }

    rectangle2polygonConverter = (rect) => {
        if (rect.type === 'rect') {
            const x = rect.x();
            const y = rect.y();
            const w = rect.width();
            const h = rect.height();
            const rClass = rect.classes();
            const rData = rect.data('layername');
            let pId = rect.id();

            const coordsArr = [[x, y], [x + w, y], [x + w, y + h], [x, y + h]];

            rect.remove();

            this.state.draw.polygon(coordsArr)
                .stroke({ width: this.state.moa ? this.state.strokeWidth * 3 : this.state.strokeWidth * 10, color: this.state.strokeColor })
                .fill({ color: this.state.fillColor, opacity: this.state.opacity / 100})
                .id(pId)
                .addClass(rClass)
                .data('layername', rData)
                .add(`<title>${this.state.fpValue}</title>`)

            let poly = this.state.draw.findOne(`#${pId}`);

            this.setState({
                selectedShape: this.state.selectedShape = poly,
                selectedShapes: this.state.selectedShape = [poly,]
            })
        }
    }

    rotateRectangle = (rect, cx, cy, deg) => {
        const PI = Math.PI;
        const radian = deg * (PI / 180);
        const rotatePointX = rect.cx();
        const rotatePointY = rect.cy();

        let pId = rect.id();

        if (rect.type === 'rect') {
            const x = rect.x();
            const y = rect.y();
            const w = rect.width();
            const h = rect.height();
            const rClass = rect.classes();
            const rData = rect.data('layername');

            const coordsArr = [[x, y], [x + w, y], [x + w, y + h], [x, y + h]];

            rect.remove();

            this.state.draw.polygon(coordsArr)
                .stroke({ width: this.state.moa ? this.state.strokeWidth * 3 : this.state.strokeWidth * 10, color: this.state.strokeColor })
                .fill({ color: this.state.fillColor, opacity: this.state.opacity / 100})
                .id(pId)
                .addClass(rClass)
                .data('layername', rData)
                .add(`<title>${this.state.fpValue}</title>`)
        }

        let poly = this.state.draw.findOne(`#${pId}`);

        let oldCoordsArr = poly.array();

        //  Convert rectangle coords to rotate points type
        let newCoordsArr = oldCoordsArr.map(el => {
            let x = el[0] - rotatePointX;
            let y = el[1] - rotatePointY;

            return [x, y];
        })

        //  Rotate rectangle coords
        let rotateCoordsArr = newCoordsArr.map(el => {
            let x = el[0] * Math.cos(radian) - el[1] * Math.sin(radian);
            let y = el[0] * Math.sin(radian) + el[1] * Math.cos(radian);

            return [x, y];
        })

        //  Edit rectangle with rotated coords
        poly.plot(rotateCoordsArr);
        poly.center(cx, cy);
    }

    selectedRectangleRender = (selectedShapes) => {
        if (selectedShapes.length > 1) {
            let selectedShapesX = selectedShapes[0].x() - selectedShapes[0].attr('stroke-width');
            let selectedShapesY = selectedShapes[0].y() - selectedShapes[0].attr('stroke-width');
            let selectedShapesX2 = selectedShapes[0].x() + selectedShapes[0].width() + selectedShapes[0].attr('stroke-width');
            let selectedShapesY2 = selectedShapes[0].y() + selectedShapes[0].height() + selectedShapes[0].attr('stroke-width');
            let selectedShapesW = 0;
            let selectedShapesH = 0;

            selectedShapes.forEach(el => {
                if ((el.x() - el.attr('stroke-width')) < selectedShapesX) {
                    selectedShapesX = el.x() - el.attr('stroke-width');
                }
                
                if ((el.x() + el.width() + el.attr('stroke-width')) > selectedShapesX2) {
                    selectedShapesX2 = el.x() + el.width() + el.attr('stroke-width');
                }

                if ((el.y() - el.attr('stroke-width')) < selectedShapesY) {
                    selectedShapesY = el.y() - el.attr('stroke-width');
                }
                
                if ((el.y() + el.height() + el.attr('stroke-width')) > selectedShapesY2) {
                    selectedShapesY2 = el.y() + el.height() + el.attr('stroke-width');
                }
            })

            selectedShapesW = selectedShapesX2 - selectedShapesX;
            selectedShapesH = selectedShapesY2 - selectedShapesY;

            this.selectedShapesBuffer = [];
            this.selectedShapesBuffer = [...this.state.selectedShapes];

            this.createSelectedShapesRect(selectedShapesX, selectedShapesY, selectedShapesW, selectedShapesH);
        }
    }
    
    deleteShapeID = (shape) => {
        let deletedID = shape.id();
        let buffArr = [];

        buffArr = this.state.currentShapesID.filter(val => val.id !== deletedID);
        
        this.setState({
            currentShapesID: this.state.currentShapesID = buffArr
        })
    }

    setCurrentShapesList = () => {
        let sList = [];

        this.state.draw.each(function(i, shape) {
            if (shape[i].type === 'circle' ||
                shape[i].type === 'line' ||
                shape[i].type === 'polygon' ||
                (shape[i].type === 'rect' && !shape[i].id().includes('patRec') && !shape[i].hasClass('marker') && !shape[i].hasClass('groupBox')) ||
                (shape[i].type === 'g' && !shape[i].hasClass('canvasGroup'))) {

                    sList.push(shape[i])
            }
        })

        let newList = sList.map(el => {
            if (el.type === 'circle') {
                let cx = el.cx() / this.state.canvasWidth * 156;
                let cy = el.cy() / this.state.canvasHeight * 120;
                let r = el.radius() / this.state.canvasWidth * 156;
                let cWidth = el.attr('stroke-width') / this.state.canvasHeight * 156;
                let c = el.clone();

                c.cx(cx);
                c.cy(cy);
                c.radius(r);
                c.attr('stroke-width', cWidth);

                return c.node.outerHTML;
            }

            if (el.type === 'line') {
                let x1 = el.attr('x1') / this.state.canvasWidth * 156;
                let y1 = el.attr('y1') / this.state.canvasHeight * 120;
                let x2 = el.attr('x2') / this.state.canvasWidth * 156;
                let y2 = el.attr('y2') / this.state.canvasHeight * 120;
                let lWidth = el.attr('stroke-width') / this.state.canvasWidth * 156;

                let l = el.clone();

                l.attr('x1', x1);
                l.attr('y1', y1);
                l.attr('x2', x2);
                l.attr('y2', y2);
                l.attr('stroke-width', lWidth);

                return l.node.outerHTML;
            }

            if (el.type === 'rect') {
                let x = el.x() / this.state.canvasWidth * 156;
                let y = el.y() / this.state.canvasHeight * 120;
                let w = el.width() / this.state.canvasWidth * 156;
                let h = el.height() / this.state.canvasHeight * 156;
                let s = el.attr('stroke-width') / this.state.canvasWidth * 156
                let r = el.clone();

                r.x(x);
                r.y(y);
                r.width(w);
                r.height(h);
                r.attr('stroke-width', s);

                return r.node.outerHTML;
            }

            if (el.type === 'polygon') {
                let x = el.x() / this.state.canvasWidth * 156;
                let y = el.y() / this.state.canvasHeight * 120;
                let w = el.width() / this.state.canvasWidth * 156;
                let h = el.height() / this.state.canvasHeight * 156;
                let s = el.attr('stroke-width') / this.state.canvasWidth * 156
                let p = el.clone();

                p.x(x);
                p.y(y);
                p.width(w);
                p.height(h);
                p.attr('stroke-width', s);

                return p.node.outerHTML;
            }

            if (el.type === 'g' && el.width() && el.height()) {
                if (el.children().length) {
                    let x = el.x() / this.state.canvasWidth * 156;
                    let y = el.y() / this.state.canvasHeight * 120;
                    let w = el.width() / this.state.canvasWidth * 156;
                    let h = el.height() / this.state.canvasHeight * 156;
                    let s = el.attr('stroke-width') / this.state.canvasWidth * 156;
                    let g = el.clone();
    
                    g.x(x);
                    g.y(y);
                    g.width(w);
                    g.height(h);
                    g.attr('stroke-width', s);
    
                    return g.node.outerHTML;
                }
            }
        })

        return newList;
    };

    handlerDeleteShape = () => {
        if (!this.state.isEdit) {
            this.markerCleaner();

            if (this.state.selectedShapes.length >= 1) {
                this.state.selectedShapes.forEach(el => {
                    this.canvasShapesBuffer.delete(el.id());
                    this.deleteShapeID(el);
                    el.remove();
                })
            } else if (this.state.selectedShape) {
                this.canvasShapesBuffer.delete(this.state.selectedShape.id());
                this.deleteShapeID(this.state.selectedShape);
                this.state.selectedShape.remove();
            }

            if (this.state.selectedShape.type === 'g') {
                if (this.state.groupedShapes) {
                    this.state.groupedShapes.forEach(el => {
                        this.deleteShapeID(el);
                        this.canvasShapesBuffer.delete(el.id());
                        el.remove();
                    })
                }

                if (this.state.isGroupingActive) {
                    this.g = this.state.selectedShape;
                    let gID = this.state.selectedShape.id()
                    let newcurrentID = this.state.currentShapesID.filter(el => el.id !== gID)

                    this.state.selectedShape.each(function (i, children) {
                        this.remove()
                    })

                    this.ungroupG()

                    this.setState({
                        currentShapesID: this.state.currentShapesID = newcurrentID,
                        selectedShape: this.state.selectedShape = this.state.draw.last()
                    })
                }

                // this.bBoxCleaner();

                this.setState({
                    isGroupingActive: this.state.isGroupingActive = false
                })

                this.props.getGroupedData(this.state.isGroupingActive);

                this.groupedSet.clear();

                this.setState({
                    canvasShapes: this.state.canvasShapes = this.canvasShapesBuffer
                })
            }

            this.bBoxCleaner();
            this.deleteSelectedShapesRect('mouseSelect');

            this.setState({
                selectedShape: this.state.selectedShape = '',
                selectedShapes: this.state.selectedShapes = [],
                alignedShape: this.state.alignedShape = '',
                groupedShapes: this.state.groupedShapes = '',
                currentShape: this.state.currentShape = this.state.draw.last().type !== 'defs' ? this.state.draw.last() : '',
            })

            this.props.getDeletedIndex(this.state.deletedIndex)
            this.updateCanvasElementsName();

            if (this.state.isSvgActive) {
                this.props.getCurrentID(this.state.shapeNames);
                this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());
            }
        }

        this.props.getCanvasShapesData(this.canvasShapesBuffer);
        this.props.toggleDeleteShape();

    }
    handlerUndo = (e) => {
        if ((e.key === 'Delete' && !this.props.focusedInput) || (e.keyCode === 46 && !this.props.focusedInput) || (e.keyCode === 8 && !this.props.focusedInput)) {
            if (!this.state.isEdit) {
                this.markerCleaner();

                if (this.state.selectedShapes.length >= 1) {
                    this.state.selectedShapes.forEach(el => {
                        this.canvasShapesBuffer.delete(el.id());
                        this.deleteShapeID(el);
                        el.remove();
                    })
                } else if (this.state.selectedShape) {
                    this.canvasShapesBuffer.delete(this.state.selectedShape.id());
                    this.deleteShapeID(this.state.selectedShape);
                    this.state.selectedShape.remove();
                }

                if (this.state.selectedShape.type === 'g') {
                                    
                    this.state.groupedShapes.forEach(el => {
    
                        this.deleteShapeID(el);
    
                        this.canvasShapesBuffer.delete(el.id());
    
                        el.remove();
                    })
    
                    if (this.state.isGroupingActive) {
                        this.g = this.state.selectedShape;
                        let gID = this.state.selectedShape.id()
                        let newcurrentID = this.state.currentShapesID.filter(el => el.id !== gID)
    
                        this.state.selectedShape.each(function(i, children) {
                            this.remove()
                        })
                        
                        this.ungroupG()
    
                        this.setState({
                            currentShapesID: this.state.currentShapesID = newcurrentID,
                            selectedShape: this.state.selectedShape = this.state.draw.last()
                        })
                    }
    
                    // this.bBoxCleaner();
    
                    this.setState({
                        isGroupingActive: this.state.isGroupingActive = false
                    })
    
                    this.props.getGroupedData(this.state.isGroupingActive);
    
                    this.groupedSet.clear();
    
                    this.setState({
                        canvasShapes: this.state.canvasShapes = this.canvasShapesBuffer
                    })
                }

                this.bBoxCleaner();
                this.deleteSelectedShapesRect('mouseSelect');
    
                this.setState({
                    selectedShape: this.state.selectedShape = '',
                    selectedShapes: this.state.selectedShapes = [],
                    alignedShape: this.state.alignedShape = '',
                    groupedShapes: this.state.groupedShapes = '',
                    currentShape: this.state.currentShape = this.state.draw.last().type !== 'defs' ? this.state.draw.last() : '',
                })
    
                this.props.getDeletedIndex(this.state.deletedIndex)
                this.updateCanvasElementsName();

                if (this.state.isSvgActive) {
                    this.props.getCurrentID(this.state.shapeNames);
                    this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());
                }
            }
        }

        this.props.getCanvasShapesData(this.canvasShapesBuffer)

        if (e.key === 'Escape') {
            this.setState({
                clone: this.state.clone = ''
            })

            this.selectedShapesBuffer = [];
            this.markerCleaner();
            this.bBoxCleaner();
            // this.clearSelectedShapesRect('selectedShapesRectangle');
            // this.clearSelectedShapesRect('mouseSelect');
            this.deleteSelectedShapesRect('mouseSelect');
        }

        if (this.state.selectedShape && !this.props.focusedInput) {
            let currentX = this.state.selectedShape.type === 'circle' ? this.state.selectedShape.cx() : this.state.selectedShape.x();
            let currentY = this.state.selectedShape.type === 'circle' ? this.state.selectedShape.cy() : this.state.selectedShape.y();
            let step = this.state.moa ? this.state.gridSize * this.state.moaAvg : this.state.gridSize * this.state.milAvg;

            if (!this.state.isEdit) {
                const selectedRect = this.state.draw.findOne('#selectedShapesRectangle');
                let shapeStroke = this.state.selectedShape.attr('stroke-width');

                switch (e.key) {
                    case 'ArrowUp':
                        if (this.state.selectedShape) {
                            e.preventDefault();
                        }

                        if (this.state.selectedShape.type === 'circle') {
                            // let cRad = this.state.selectedShape.radius() + shapeStroke;

                            // if (this.state.selectedShape.cy() - cRad > 0) {
                            //     this.state.selectedShape.cy(currentY - step);
                            // }

                            if (this.state.selectedShape.y() - shapeStroke - step > 0) {
                                this.state.selectedShape.cy(currentY - step);
                            }
                        } else if (this.state.selectedShape.type === 'polygon' || (this.state.selectedShape.type === 'rect' && !this.state.selectedShape.hasClass('mouseSelect'))) {
                            if (this.state.selectedShape.y() - shapeStroke - step > 0) {
                                this.state.selectedShape.move(currentX, currentY - step);
                            }
                        } else if (this.state.selectedShape.type === 'line') {
                            if (this.state.selectedShape.y() - shapeStroke - step > 0) {
                                this.state.selectedShape.move(currentX, currentY - step);
                            }
                        } else if (this.state.selectedShape.hasClass('mouseSelect') &&
                            selectedRect.y() - step > this.state.gY[0]) {
                            // this.state.selectedShape.dy(-step);

                            selectedRect.dy(-step);

                            this.selectedShapesBuffer.forEach(shape => {
                                shape.dy(-step);
                            })
                        } else {
                            if (this.state.selectedShape.y() - shapeStroke > 0) {
                                this.state.selectedShape.move(currentX, currentY - step);
                            }
                        }
    
                        this.bBoxCleaner();
                        this.bBoxRender();
                        this.markerCleaner();

                        this.addMarkersToSelected(this.state.selectedShape);
                        break
                    case 'ArrowDown':
                        if (this.state.selectedShape) {
                            e.preventDefault();
                        }

                        if (this.state.selectedShape.type === 'circle') {
                            let cRad = this.state.selectedShape.radius() + shapeStroke;

                            if (this.state.selectedShape.cy() + cRad + step <= this.state.canvasHeight) {
                                this.state.selectedShape.cy(currentY + step);
                            }
                        } else if (this.state.selectedShape.type === 'polygon' || (this.state.selectedShape.type === 'rect' && !this.state.selectedShape.hasClass('mouseSelect'))) {
                            if (this.state.selectedShape.y() + this.state.selectedShape.height() + shapeStroke + step < this.state.canvasHeight) {
                                this.state.selectedShape.move(currentX, currentY + step)
                            }
                        } else if (this.state.selectedShape.type === 'line') {
                            if (this.state.selectedShape.y()  + this.state.selectedShape.height() + shapeStroke + step < this.state.canvasHeight) {
                                this.state.selectedShape.move(currentX, currentY + step)
                            }
                        } else if (this.state.selectedShape.hasClass('mouseSelect') &&
                            selectedRect.y() + selectedRect.height() + step < this.state.canvasHeight) {
                            // this.state.selectedShape.move(currentX, currentY + step);
                            selectedRect.dy(step);

                            this.selectedShapesBuffer.forEach(shape => {
                                shape.dy(step);
                            })
                        } else {
                            if (this.state.selectedShape.y()  + this.state.selectedShape.height() + shapeStroke < this.state.canvasHeight) {
                                this.state.selectedShape.move(currentX, currentY + step);
                            }
                        }
    
                        this.bBoxCleaner();
                        this.bBoxRender();
                        this.markerCleaner();
                        this.addMarkersToSelected(this.state.selectedShape);
                        break
                    case 'ArrowLeft':
                        if (this.state.selectedShape) {
                            e.preventDefault();
                        }

                        if (this.state.selectedShape.type === 'circle') {
                            // let cRad = this.state.selectedShape.radius() + shapeStroke;

                            if (this.state.selectedShape.x() - shapeStroke - step > 0) {
                                this.state.selectedShape.cx(currentX - step);
                            }
                        } else if (this.state.selectedShape.type === 'polygon' || (this.state.selectedShape.type === 'rect' && !this.state.selectedShape.hasClass('mouseSelect'))) {
                            if (this.state.selectedShape.x() - shapeStroke - step > 0) {
                                this.state.selectedShape.move(currentX - step, currentY)
                            }
                        } else if (this.state.selectedShape.type === 'line') {
                            if (this.state.selectedShape.x() - step > 0) {
                                this.state.selectedShape.move(currentX - step, currentY)
                            }
                        } else if (this.state.selectedShape.hasClass('mouseSelect') &&
                            selectedRect.x() - step > 0) {
                            // this.state.selectedShape.move(currentX - step, currentY);
                            selectedRect.dx(-step);

                            this.selectedShapesBuffer.forEach(shape => {
                                shape.dx(-step);
                            })
                        } else {
                            if (this.state.selectedShape.x() - shapeStroke > 0) {
                                this.state.selectedShape.move(currentX - step, currentY);
                            }
                        }
                        
                        this.bBoxCleaner();
                        this.bBoxRender();
                        this.markerCleaner();
                        this.addMarkersToSelected(this.state.selectedShape);
                        break
                    case 'ArrowRight':
                        if (this.state.selectedShape) {
                            e.preventDefault();
                        }

                        if (this.state.selectedShape.type === 'circle') {
                            let cRad = this.state.selectedShape.radius() + shapeStroke;

                            if (this.state.selectedShape.cx() + cRad + step < this.state.canvasWidth) {
                                this.state.selectedShape.cx(currentX + step);
                            }
                        } else if (this.state.selectedShape.type === 'polygon' || (this.state.selectedShape.type === 'rect' && !this.state.selectedShape.hasClass('mouseSelect'))) {
                            if (this.state.selectedShape.x() + this.state.selectedShape.width() + shapeStroke + step < this.state.canvasWidth) {
                                this.state.selectedShape.move(currentX + step, currentY)
                            }
                        } else if (this.state.selectedShape.type === 'line') {
                            if (this.state.selectedShape.x() + this.state.selectedShape.width() + shapeStroke + step < this.state.canvasWidth) {
                                this.state.selectedShape.move(currentX + step, currentY)
                            }
                        } else if (this.state.selectedShape.hasClass('mouseSelect') &&
                            selectedRect.x() + selectedRect.width() + step < this.state.gX[this.state.gX.length - 1]) {
                            // this.state.selectedShape.move(currentX + step, currentY);
                            selectedRect.dx(step);

                            this.selectedShapesBuffer.forEach(shape => {
                                shape.dx(step);
                            })
                        } else {
                            if (this.state.selectedShape.x() + this.state.selectedShape.width() + shapeStroke < this.state.canvasWidth) {
                                this.state.selectedShape.move(currentX + step, currentY);
                            }
                        }
    
                        this.bBoxCleaner();
                        this.bBoxRender();
                        this.markerCleaner();
                        this.addMarkersToSelected(this.state.selectedShape);
                        break
                    default:
                        this.bBoxCleaner();
                        this.bBoxRender();
                        this.markerCleaner();
                        this.addMarkersToSelected(this.state.selectedShape);
                }

                if (this.state.isSvgActive) {
                    this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());
                }
            }
        }

        if (!this.state.selectedShape && this.state.selectedShapes.length && !this.props.focusedInput) {
            let step = this.state.moa ? this.state.gridSize * this.state.moaAvg : this.state.gridSize * this.state.milAvg;

            if (!this.state.isEdit) {
                const selectedRect = this.state.draw.findOne('#selectedShapesRectangle');

                switch (e.key) {
                    case 'ArrowUp':
                        e.preventDefault();

                        if (selectedRect.y() - selectedRect.attr('stroke-width') > this.state.gY[0]) {
                            selectedRect.dy(-step);
    
                            this.selectedShapesBuffer.forEach(shape => {
                                shape.dy(-step);
                            })
    
                            this.bBoxCleaner();
                            this.bBoxRender();
                            this.markerCleaner();
                            this.addMarkersToSelected(this.state.selectedShape);
                        }
                        break
                    case 'ArrowDown':
                        e.preventDefault();

                        if (selectedRect.y() + selectedRect.height() + selectedRect.attr('stroke-width') + step < this.state.gY[this.state.gY.length - 1]) {
                            selectedRect.dy(step);
    
                            this.selectedShapesBuffer.forEach(shape => {
                                shape.dy(step);
                            })
        
                            this.bBoxCleaner();
                            this.bBoxRender();
                            this.markerCleaner();
                            this.addMarkersToSelected(this.state.selectedShape);
                        }
                        break
                    case 'ArrowLeft':
                        e.preventDefault();

                        if (selectedRect.x() - selectedRect.attr('stroke-width') > this.state.gX[0]) {
                            selectedRect.dx(-step);
    
                            this.selectedShapesBuffer.forEach(shape => {
                                shape.dx(-step);
                            })
        
                            this.bBoxCleaner();
                            this.bBoxRender();
                            this.markerCleaner();
                            this.addMarkersToSelected(this.state.selectedShape);
                        }
                        break
                    case 'ArrowRight':
                        e.preventDefault();

                        if (selectedRect.x() + selectedRect.width() + selectedRect.attr('stroke-width') + step < this.state.gX[this.state.gX.length - 1]) {
                            selectedRect.dx(step);
    
                            this.selectedShapesBuffer.forEach(shape => {
                                shape.dx(step);
                            })
        
                            this.bBoxCleaner();
                            this.bBoxRender();
                            this.markerCleaner();
                            this.addMarkersToSelected(this.state.selectedShape);
                        }
                        break
                    default:
                        this.bBoxCleaner();
                        this.bBoxRender();
                        this.markerCleaner();
                        this.addMarkersToSelected(this.state.selectedShape);
                }

                if (this.state.isSvgActive) {
                    this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());
                }
            }
        }
    }

    gridConverter = (canvasW, canvasH, step) => {
        this.setState({
            gX: this.state.gX = [],
            gY: this.state.gY = []
        })

        for(let i = canvasW / 2; i <= canvasW; this.state.moa ? i += step * this.state.moaAvg : i += step * this.state.milAvg) {
            let buffGX = this.state.gX;
            buffGX.push(+i.toFixed(2));

            this.setState({
                gX: this.state.gX = buffGX
            })
        }

        for(let j = canvasH / 2; j <= canvasH; this.state.moa ? j += step * this.state.moaAvg : j += step * this.state.milAvg) {
            let buffGY = this.state.gY;
            buffGY.push(+j.toFixed(2))
            
            this.setState({
                gY: this.state.gY = buffGY
            })
        }

        for(let i = canvasW / 2; i >= 0; this.state.moa ? i -= step * this.state.moaAvg : i -= step * this.state.milAvg) {
            let buffGX = this.state.gX;
            buffGX.unshift(+i.toFixed(2));

            this.setState({
                gX: this.state.gX = buffGX
            })
        }

        for(let j = canvasH / 2; j >= 0; this.state.moa ? j -= step * this.state.moaAvg : j -= step * this.state.milAvg) {
            let buffGY = this.state.gY;
            buffGY.unshift(+j.toFixed(2));

            this.setState({
                gY: this.state.gY = buffGY
            })
        }

        let grXBuff = this.state.gX;
        let grYBuff = this.state.gY;

        grXBuff.splice(this.state.gX.length / 2, 1);
        grYBuff.splice(this.state.gY.length / 2, 1);

        this.setState({
            gX: this.state.gX = grXBuff,
            gY: this.state.gY = grYBuff
        })
    }

    coordsToGridSize = (x, y) => {
        let bufferGridX = 0;
        let bufferGridY = 0;
        let bufferGridMaxX = x <= this.state.gX[this.state.gX.length - 1] ? x : this.state.gX[this.state.gX.length - 1];
        let bufferGridMaxY = y <= this.state.gY[this.state.gX.length - 1] ? y : this.state.gY[this.state.gX.length - 1];
        let isMaxXFind = true;
        let isMaxYFind = true;

        this.state.gX.forEach(el => {
            let current = el
            if (current <= x && (typeof bufferGridX === 'undefined' || bufferGridX < current)) {
                bufferGridX = current
            }
        })

        this.state.gX.forEach(el => {
            let current = el
            if (current >= x && isMaxXFind) {
                bufferGridMaxX = current
                isMaxXFind = false
            }
        })

        if (bufferGridMaxX - x > x - bufferGridX) {
            if (this.state.gridX !== bufferGridX) {
                this.setState({
                    gridX: this.state.gridX = bufferGridX
                })
            }
        } else {
            if (this.state.gridX !== bufferGridMaxX) {
                this.setState({
                    gridX: this.state.gridX = bufferGridMaxX
                })
            }
        }


        this.state.gY.forEach(el => {
            let current = el
            if (current <= y && (typeof bufferGridY === 'undefined' || bufferGridY < current)) {
                bufferGridY = current
            }
        })

        this.state.gY.forEach(el => {
            let current = el;

            if (current >= y && isMaxYFind) {
                bufferGridMaxY = current
                isMaxYFind = false
            }
        })

        if (bufferGridMaxY - y > y - bufferGridY) {
            if (this.state.gridY !== bufferGridY) {
                this.setState({
                    gridY: this.state.gridY = bufferGridY
                })
            }
        } else {
            if (this.state.gridY !== bufferGridMaxY) {
                this.setState({
                    gridY: this.state.gridY = bufferGridMaxY
                })
            }
        }
    }

    gridCleaner = () => {
        let oldPattern = this.state.draw.findOne(`#gridPattern-${this.props.activeTabId}`);
        let patternCircls = this.state.draw.find('.gridMarker');
        // let oldBgRect = this.state.draw.find('.bgRect');

        if (oldPattern) {
            oldPattern.remove()
            patternCircls.remove()
        }
        
        let defsEl = this.state.draw.find('defs');

        if (defsEl) {
            defsEl.forEach(el => el.remove());
        }

        // mainCanvasG

        // if (oldBgRect) {
        //     oldBgRect.forEach(el => el.remove());
        // }
    }

    gridRender = (gridArrX, gridArrY, nightMode=this.state.isNightMode) => {
        try {
            const patternRect = this.state.draw.findOne(`#patRec-${this.props.svgID}`);
            const bgRect = this.state.draw.findOne(`#bgRect-${this.props.svgID}`);
            const patternSize = this.state.moa ? this.state.gridSize * this.state.moaAvg : this.state.gridSize * this.state.milAvg;
            // const canvasRootBlock = document.getElementById(`canvas-root-${this.props.svgID}`);
            // const mainCanvasG = this.state.draw.findOne(`canvasGroup-${this.props.svgID}`);

            let bgColor;
            let fColor;

            if (nightMode) {
                bgColor = '#1f1f26';
                fColor = '#fff';
            } else {
                bgColor = '#fff';
                fColor = '#000';
            }

            // let bgRect = this.state.draw.rect(this.state.canvasWidth, this.state.canvasHeight).id(`bgRect-${this.props.svgID}`).addClass('bgRect').back();

            // canvasRootBlock.style.backgroundColor('#eee');

            // this.state.draw.opacity(0.9)

            // for (let i = 0; i < 8; i++) {
            //     patternRect.backward();
            // }

            // if (bgRect) {
            //     for (let i = 0; i < 10; i++) {
            //         bgRect.backward()
            //     }
            // }

            const pattern = this.state.draw.pattern(patternSize, patternSize, add => {
                // add.rect('100%', '100%').center(gridArrX[0], gridArrY[0]).addClass('gridMarker').fill(bgColor);
                // add.rect('100%', '100%').center(gridArrX[1], gridArrY[1]).addClass('gridMarker').fill(bgColor);
                add.circle(1).center(gridArrX[0], gridArrY[0]).addClass('gridMarker').fill(fColor);
                add.circle(1).center(gridArrX[1], gridArrY[0]).addClass('gridMarker').fill(fColor);
                add.circle(1).center(gridArrX[0], gridArrY[1]).addClass('gridMarker').fill(fColor);
                add.circle(1).center(gridArrX[1], gridArrY[1]).addClass('gridMarker').fill(fColor);
            })//.attr({id: `gridPattern-${this.props.svgID}`})
    
            if (patternRect) {
                patternRect.fill(pattern)//.back();
            }

            if (bgRect) {
                bgRect.fill(bgColor)//.back();
            }
        } catch (e) {
        }
    }

    renderImportReticle = (svgTxt) => {
        const importSvg = SVG(svgTxt, true);
        const importSvgW = importSvg.width();
        const importSvgH = importSvg.height();
        const isNormSvgSize = importSvgW === this.state.canvasWidth && importSvgH === this.state.canvasHeight;

        importSvg.each((iEl, iSVG) => {
            switch(iSVG[iEl].type) {
                case 'g':
                    console.log(iSVG[iEl].id());

                    break;
                case 'line':
                    const x1 = iSVG[iEl].attr('x1');
                    const y1 = iSVG[iEl].attr('y1');
                    const x2 = iSVG[iEl].attr('x2');
                    const y2 = iSVG[iEl].attr('y2');
                    const lineStrokeColor = iSVG[iEl].stroke();
                    const lineStrokeW = iSVG[iEl].attr('stroke-width');
                    const lineStrokeDash = iSVG[iEl].attr('stroke-dasharray');
                    let lineTitleData = iSVG[iEl].first();

                    if (lineTitleData && lineTitleData.node.textContent !== '42') {
                        lineTitleData = lineTitleData.node.textContent;
                    } else {
                        lineTitleData = '42';
                    }

                    this.importLine(x1, y1, x2, y2, lineStrokeColor, lineStrokeW, lineStrokeDash, lineTitleData);
                    break;
                case 'circle':
                    const cx = iSVG[iEl].cx();
                    const cy = iSVG[iEl].cy();
                    const r = iSVG[iEl].radius();
                    let circleColor = iSVG[iEl].fill();
                    let circleOpacity = iSVG[iEl].attr('fill-opacity');
                    const circleStrokeColor = iSVG[iEl].stroke();
                    const circleStrokeW = iSVG[iEl].attr('stroke-width');
                    const circleStrokeDash = iSVG[iEl].attr('stroke-dasharray');
                    let circleTitleData = iSVG[iEl].first();

                    if (circleTitleData && circleTitleData.node.textContent !== '42') {
                        circleTitleData = circleTitleData.node.textContent;
                    } else {
                        circleTitleData = '42';
                    }

                    if (circleColor.length > 4) {
                        circleColor = '#fff';
                        circleOpacity = 0;
                    }

                    this.importCircle(cx, cy, r, circleColor, circleOpacity, circleStrokeColor, circleStrokeW, circleStrokeDash, circleTitleData);
                    break;
                case 'rect':
                    const x = iSVG[iEl].x();
                    const y = iSVG[iEl].y();
                    const w = iSVG[iEl].width();
                    const h = iSVG[iEl].height();
                    let rectColor = iSVG[iEl].fill();
                    let rectOpacity = iSVG[iEl].attr('fill-opacity');
                    const rectStrokeColor = iSVG[iEl].stroke();
                    const rectStrokeW = iSVG[iEl].attr('stroke-width');
                    const rectStrokeDash = iSVG[iEl].attr('stroke-dasharray');
                    let rectTitleData = iSVG[iEl].first();
                    let rectType = 'square';

                    if (rectColor.length > 4) {
                        rectColor = '#fff';
                        rectOpacity = 0;
                    }

                    if (rectTitleData && rectTitleData.node.textContent !== '42') {
                        rectTitleData = rectTitleData.node.textContent;
                    } else {
                        rectTitleData = '42';
                    }

                    if (w !== h) {
                        rectType = 'rect';
                    }

                    this.importRectangle(x, y, w, h, rectType, rectColor, rectOpacity, rectStrokeW, rectStrokeColor, rectStrokeDash, rectTitleData);
                    break;
                case 'polygon':
                    const polyCoords = iSVG[iEl].array();
                    let polyColor = iSVG[iEl].fill();
                    let polyOpacity = iSVG[iEl].attr('fill-opacity');
                    const polyStrokeColor = iSVG[iEl].stroke();
                    const polyStrokeW = iSVG[iEl].attr('stroke-width');
                    const polyStrokeDash = iSVG[iEl].attr('stroke-dasharray');
                    let polyTitleData = iSVG[iEl].first();
                    let pDirection = 'top';

                    if (polyColor.length > 4) {
                        polyColor = '#fff';
                        polyOpacity = 0;
                    }

                    if (polyTitleData && polyTitleData.node.textContent !== '42') {
                        polyTitleData = polyTitleData.node.textContent;
                    } else {
                        polyTitleData = '42';
                    }

                    if (polyCoords[0][0] < polyCoords[1][0] && polyCoords[0][0] > polyCoords[2][0]) {
                        if (polyCoords[0][1] > polyCoords[1][1] && polyCoords[0][1] > polyCoords[2][1]) {
                            pDirection = 'bottom';
                        }
                    }

                    if (polyCoords[0][0] > polyCoords[1][0] && polyCoords[0][0] > polyCoords[2][0]) {
                        pDirection = 'right';
                    }

                    if (polyCoords[0][0] < polyCoords[1][0] && polyCoords[0][0] < polyCoords[2][0]) {
                        pDirection = 'left';
                    }
                    
                    this.importTriangle(polyCoords, pDirection, polyColor, polyOpacity, polyStrokeColor, polyStrokeW, polyStrokeDash, polyTitleData)
                    break;
                default:
                    // console.log(iSVG[iEl]);
            }

            // if(iSVG[iEl].type === 'g') {
            //     iSVG[iEl].each((j, gArr) => {
            //         if (gArr[j].type === 'ellipse') {
            //             this.state.draw.add(gArr[j])
            //         }

            //         if (gArr[j].type === 'path') {
            //             this.state.draw.add(gArr[j])
            //         }
            //     })
            // }
        })
    }

    handlerShiftPressed = (e) => {
        if (e.key === 'Shift') {
            if (this.state.isShiftPressed !== true) {
                this.setState({
                    isShiftPressed: this.state.isShiftPressed = true
                })
            }
        }

        const key = e.keyCode;

        const ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false);
        const commandKey = e.metaKey //? e.metaKey : ((key === 17) ? true : false);

        //  Copy & Past shortcut

        if (key === 67 && (ctrl || commandKey)) {
            this.cloneSelectedShape(this.state.selectedShape);
        }
        
        if (key === 86 && (ctrl || commandKey)) {
            if (this.state.selectedShapes.length > 1) {
                this.state.selectedShapes.forEach(el => {
                    this.cloneSelectedShape(el);
                    this.addCloneShape2Canvas(this.state.clone);
                })
            } else {
                this.addCloneShape2Canvas(this.state.clone);
            }

            if (this.state.isSvgActive) {
                // this.sortLayesrs();
                this.props.getCurrentID(this.state.shapeNames);
                this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());
            }
        }

        // if (key === 32 && (ctrl || commandKey)) {
        //     e.preventDefault();
        //     this.gridCleaner();
            
        //     if (!this.state.isNightMode) {
        //         this.setState({
        //             isNightMode: true
        //         })

        //         this.gridRender(this.state.gX, this.state.gY, true);
        //     }

        //     if (this.state.isNightMode) {
        //         this.setState({
        //             isNightMode: false
        //         })

        //         this.gridRender(this.state.gX, this.state.gY, false);
        //     }
        // }

        this.updateCanvasElementsName();

        // Ungroup shortcut

        // if (e.shiftKey && e.code === 'KeyG' && (e.ctrlKey || e.metaKey)) {
        //     e.preventDefault();

        //     if (this.state.selectedShape.type === 'g' && !this.state.selectedShape.hasClass('number-group')) {
        //         let groupID = this.state.selectedShape.id();
        //         let groupElements = [];
        //         let shapesBufferID = this.state.currentShapesID.filter(el => el.id !== groupID);

        //         this.state.selectedShape.each(function(i, children) {
        //             groupElements.push({id: `${children[i].id()}`, name: `${children[i].id()}`})
        //         })

        //         shapesBufferID.push(...groupElements)

        //         this.setState({
        //             currentShapesID: this.state.currentShapesID = shapesBufferID
        //         })

        //         this.ungroupG();

        //         this.setState({
        //             isGroupingActive: this.state.isGroupingActive = false
        //         });

        //         this.props.getGroupedData(this.state.isGroupingActive)

        //         this.setState({
        //             selectedShapes: this.state.selectedShapes = []
        //         })
        //     }

        //     this.updateCanvasElementsName();

        //     if (this.state.isSvgActive) {
        //         this.props.getCurrentID(this.state.shapeNames);
        //     }
            
        //     this.selectedShapes.clear();
        //     this.bBoxCleaner();
        // }

        //  Create group shortcut

        // if ((e.ctrlKey || e.metaKey) && e.code === 'KeyG') {
        //     e.preventDefault();
        //     this.idCounter();

        //     if (this.state.selectedShapes.length > 1) {
        //         let gid = (Math.random() * 100000).toFixed(0);
        //         let grp = this.state.draw.group()
        //             .attr({ id: `group-${gid}`, class: 'group' })
        //             .data('layername', `group-${this.state.i}`)

        //         this.state.selectedShapes.forEach(el => {
        //             grp.add(el);
        //         })

        //         this.setState({
        //             selectedShape: this.state.selectedShape = grp,
        //         })
        //     }

        //     this.bBoxRender();

        //     this.updateCanvasElementsName();

        //     if (this.state.isSvgActive) {
        //         this.props.getCurrentID(this.state.shapeNames);
        //     }

        //     this.selectedShapes.clear();
        // }

        if ((e.ctrlKey || e.metaKey) && e.code === 'KeyZ') {
            if (this.state.isSvgActive) {
                this.undoLastStep();
                // this.sortLayesrs();
            }
        }

        if ((e.ctrlKey || e.metaKey) && e.code === 'KeyY') {
            if (this.state.isSvgActive) {
                this.redoLastStep();
                // this.sortLayesrs();
            }
        }

        if ((e.ctrlKey || e.metaKey) && e.key === 'a') {
            e.preventDefault();
            
            if (this.state.currentTool === 'move') {
                this.setState({
                    selectedShapes: this.state.selectedShapes = []
                })
    
                let shapesArr = [];
    
                this.state.draw.each(function(i, children) {
                    if (
                        children[i].hasClass('line') ||
                        children[i].hasClass('circle') ||
                        children[i].hasClass('square') ||
                        children[i].hasClass('triangle') ||
                        children[i].hasClass('rectangle') ||
                        children[i].hasClass('number-group') ||
                        children[i].hasClass('group')
                    ) {
                        shapesArr.push(children[i]);
                    }
                })
                
                this.setState({
                    selectedShapes: this.state.selectedShapes = shapesArr
                })

                this.state.selectedShapes.forEach(el => {
                    this.setState({
                        selectedShape: this.state.selectedShape = el
                    })
                    el.addClass('selected')
                    this.bBoxRender()
                })

                this.selectedRectangleRender(this.state.selectedShapes);

                let selectedRect = this.state.draw.findOne('#selectedShapesRectangle');

                this.setState({
                    selectedShape: this.state.selectedShape = selectedRect
                })

                this.props.getSelectedShape(this.state.selectedShape);
                this.props.getSelectedShapes(this.state.selectedShapes);

                // this.state.draw.each(function(i, children) {

                //     this.bBoxRender()
                    // let b = this.bbox();

                    // this.state.draw.rect(b.width, b.height)
                    //     .stroke({width: 1, color: '#1a5fb4', dasharray: '7, 7'})
                    //     .center(b.cx, b.cy).fill('transparent')
                    //     .addClass('groupBox')
                    //     .back()
                // })
            }
        }

        if ((e.ctrlKey && e.keyCode === 61) || (e.ctrlKey && e.code === 'NumpadAdd')) {
            e.preventDefault();

            if (this.state.zoomIndex < 190) {
                this.setState({
                    zoomIndex: this.state.zoomIndex = this.state.zoomIndex + 5,
                    zoomWidthIndex: this.state.zoomWidthIndex -= this.state.canvasWidth * 0.1,
                    zoomHeightIndex: this.state.zoomHeightIndex -= this.state.canvasHeight * 0.1
                })

                this.zoomCanvas();
    
                this.props.updateZoom({id: this.props.activeTabId, index: this.state.zoomIndex + 5})

                if (!this.state.isZoom){
                    this.setState({
                        isZoom: this.state.isZoom = true
                    })
                }
            }

            // this.state.draw.addClass('inZoom')
        }

        if ((e.ctrlKey && e.keyCode === 173) || (e.ctrlKey && e.code === 'NumpadSubtract')) {
            e.preventDefault();

            if (this.state.zoomIndex > 100) {
                this.setState({
                    zoomIndex: this.state.zoomIndex = this.state.zoomIndex - 5,
                    zoomWidthIndex: this.state.zoomWidthIndex += (this.state.canvasWidth * 0.1),
                    zoomHeightIndex: this.state.zoomHeightIndex += (this.state.canvasHeight * 0.1)
                })
    
                if (!this.state.isZoom){
                    this.setState({
                        isZoom: this.state.isZoom = true
                    })
                }

                this.props.updateZoom({id: this.props.activeTabId, index: this.state.zoomIndex - 5})

                if (this.state.zoomIndex === 100) {
                    if (this.state.isZoom){
                        this.setState({
                            isZoom: this.state.isZoom = false
                        })
                    }
                }
                
            } else {
                if (this.state.isZoom){
                    this.setState({
                        isZoom: this.state.isZoom = false
                    })
                }
            }

            this.zoomCanvas();
        }

        if ((e.ctrlKey && e.keyCode === 48) || (e.ctrlKey && e.code === 'Numpad0')) {
            this.setState({
                zoomIndex: this.state.zoomIndex = 100,
                zoomWidthIndex: this.state.zoomWidthIndex = this.state.canvasWidth,
                zoomHeightIndex: this.state.zoomHeightIndex = this.state.canvasHeight
            })

            this.props.updateZoom({id: this.props.activeTabId, index: 100})

            this.state.draw.viewbox(0, 0, this.state.canvasWidth, this.state.canvasHeight);

            if (this.state.isZoom){
                this.setState({
                    isZoom: this.state.isZoom = false
                })
            }

            this.state.draw.removeClass('inZoom');
        }
    }

    handlerShiftRelease = (e) => {
        this.setState({
            isShiftPressed: this.state.isShiftPressed = false
        })

        this.props.getCanvasShapesData(this.canvasShapesBuffer);
        
        if (this.props.focusedInput) {
            if (this.state.isSvgActive) {
                this.props.setCleanedSvg(this.svgGridRemove())
            }
        }
    }

    // handlerHoverShape = (e) => {
        // this.hoverEl = this.state.draw.findOne(`#${e.target.id}`);

        // if (this.hoverEl) {
            // if (e.type === 'mouseover' && !this.hoverEl.hasClass('marker')) {
            //     let marker = document.querySelector(`#${e.target.id}`)
            //     this.setState({
            //         prevStroke: this.state.prevStroke = marker.getAttribute('stroke-width'),
            //         prevStrokeColor: this.state.prevStrokeColor = marker.getAttribute('stroke'),
            // })

            //     // if (this.hoverEl.type === 'circle') {
            //     //     this.strokeW = +this.hoverEl.node.attributes[3].nodeValue[0];
            //     //     this.hoverEl.stroke({ color: '#1998EE' });
            //     // } else if (this.hoverEl.type === 'polygon') {
            //     //     this.strokeW = +this.hoverEl.node.attributes[1].nodeValue;
            //     //     this.hoverEl.stroke({ color: '#1998EE' });
            //     // } else if (this.hoverEl.type === 'line') {
            //     //     this.strokeW = +this.hoverEl.node.attributes[4].nodeValue[0];
            //     //     this.hoverEl.stroke({ color: '#1998EE' });
            //     // } 
            //     // else if (this.hoverEl.type === 'rect') {
            //     //     this.strokeW = +this.hoverEl.node.attributes[2].nodeValue;
            //     //     this.hoverEl.stroke({ color: '#1998EE' });
            //     // }
            // } else if (e.type === 'mouseout' && !this.hoverEl.hasClass('marker')) {
            //     this.hoverEl.stroke({ color: this.state.prevStrokeColor });
            //     this.setState({
            //         prevStrokeColor: this.state.prevStrokeColor = ''
            //     })
            // }
        // }

        // if (this.state.selectedShapeStrokeWidth !== this.strokeW) {
        //     this.setState({
        //         selectedShapeStrokeWidth: this.state.selectedShapeStrokeWidth = this.strokeW
        //     })
        // }
    // }

    bBoxCleaner = () => {
        let bb = this.state.draw.find('.groupBox');

        if (bb) {
            bb.forEach(el => el.remove())
        }
    }

    bBoxRender = () => {
        if (this.state.selectedShape && !this.state.selectedShape.hasClass('mouseSelect')) {
            const shapeX = this.state.selectedShape.x() - this.state.selectedShape.attr('stroke-width');
            const shapeY = this.state.selectedShape.y() - this.state.selectedShape.attr('stroke-width');
            const shapeW = this.state.selectedShape.width() + this.state.selectedShape.attr('stroke-width') * 2;
            const shapeH = this.state.selectedShape.height() + this.state.selectedShape.attr('stroke-width') * 2;
            const circleX = this.state.selectedShape.x() - this.state.selectedShape.attr('stroke-width') / 2;
            const circleY = this.state.selectedShape.y() - this.state.selectedShape.attr('stroke-width') / 2;
            const circleW = this.state.selectedShape.width() + this.state.selectedShape.attr('stroke-width');
            const canvasG = this.state.draw.findOne(`#canvasGroup-${this.props.svgID}`);
            // const circleH = this.state.selectedShape.height() + this.state.selectedShape.attr('stroke-width');
            // let grid = this.state.draw.find('.patRec');

            if (this.state.selectedShape) {
                if (this.state.selectedShape.type === 'circle') {
                    let grB = this.state.draw.rect(circleW, circleW)
                        .stroke({width: 1, color: '#337AA6'})
                        .move(circleX, circleY)
                        .fill({ color: 'transparent', opacity: 0 })
                        .addClass('groupBox')
                        .attr({'vector-effect': 'non-scaling-stroke'})
                        // .back()
                        // .after(this.state.selectedShape)
                        // .insertBefore(grid)

                    canvasG.add(grB);
                    grB.backward();
                    grB.backward();
                } else {
                    let grB = this.state.draw.rect(shapeW, shapeH)
                        .stroke({width: 1, color: '#337AA6'})
                        .move(shapeX, shapeY)
                        .fill({ color: 'transparent', opacity: 0 })
                        .addClass('groupBox')
                        .attr({'vector-effect': 'non-scaling-stroke'})
                        // .back()
                        // .after(this.state.selectedShape)
                        // .insertBefore(grid)

                    // rect.replace(draw.circle(100))
                    canvasG.add(grB);
                    grB.backward();
                    grB.backward();
                }
            }
        } else if (this.state.selectedShapes.length) {
            this.state.selectedShapes.forEach(el => {
                if (el && !el.hasClass('mouseSelect')) {
                    const shapeX = el.x() - el.attr('stroke-width');
                    const shapeY = el.y() - el.attr('stroke-width');
                    const shapeW = el.width() + el.attr('stroke-width') * 2;
                    const shapeH = el.height() + el.attr('stroke-width') * 2;
                    const circleX = el.x() - el.attr('stroke-width') / 2;
                    const circleY = el.y() - el.attr('stroke-width') / 2;
                    const circleW = el.width() + el.attr('stroke-width');
                    const canvasG = this.state.draw.findOne(`#canvasGroup-${this.props.svgID}`);
                    // const circleH = el.height() + el.attr('stroke-width');

                    if (el.type === 'circle') {
                        let grB = this.state.draw.rect(circleW, circleW)
                            .stroke({width: 1, color: '#337AA6'})
                            .move(circleX, circleY).fill('transparent')
                            .addClass('groupBox')
                            .attr({'vector-effect': 'non-scaling-stroke'})
                            // .back()

                        canvasG.add(grB);
                        grB.backward();
                        grB.backward();
                    } else {
                        let grB = this.state.draw.rect(shapeW, shapeH)
                            .stroke({width: 1, color: '#337AA6'})
                            .move(shapeX, shapeY).fill('transparent')
                            .addClass('groupBox')
                            .attr({'vector-effect': 'non-scaling-stroke'})
                            // .back()

                        canvasG.add(grB);
                        grB.backward();
                        grB.backward();
                    }
                }
            })
        }
    }

    ungroupG = () => {
        // let groups = this.state.draw.find('.group');

        if (this.state.selectedShape.type === 'g') {
            // groups.forEach(el => el.ungroup())
            this.state.selectedShape.ungroup();
        }
    }

    addUndoStep = () => {
        if (this.state.isSvgActive) {
            // let undo = this.state.draw.svg(false);
            
            let undoData = this.state.draw.svg(function(node) {
                if (node.hasClass('marker') || node.hasClass('groupBox') || node.hasClass('mouseSelect')) {
                    return false;
                }
            }, false)

            this.state.canvasImage.push(undoData);

            let historyData = JSON.stringify({undo: this.state.canvasImage, redo: this.state.canvasRedo});
            sessionStorage.setItem(`svgCanvas-${this.props.svgID}`, historyData);
        }
    }

    undoLastStep = () => {
        if (this.state.isSvgActive) {
            if (this.state.selectedShape) {
                this.setState({
                    selectedShape: this.state.selectedShape = '',
                    selectedShapes: this.state.selectedShapes = []
                })

                this.selectedShapesTypeChecker(this.state.selectedShapes);
                this.deleteSelectedShapesRect('mouseSelect');

                this.props.getSelectedShape('');
                this.props.getSelectedShapes([]);
            }

            if (this.state.canvasImage.length > 0) {
                let undo2redo = this.state.canvasImage.pop();
                this.state.canvasRedo.push(undo2redo);
            }
    
            // sessionStorage.setItem('undoData', this.canvasImage);
            let indx = this.state.canvasImage.length > 0 ? this.state.canvasImage.length - 1 : 0;
            let newImage = this.state.canvasImage[indx];
    
            // sessionStorage.setItem('svg', newImage);
            let historyData = JSON.stringify({undo: this.state.canvasImage, redo: this.state.canvasRedo});
            sessionStorage.setItem(`svgCanvas-${this.props.svgID}`, historyData);
            
            if (this.state.canvasImage.length > 0) {
                this.state.draw.each(function(i, children) {
                    this.remove()
                })
                
                this.state.draw.svg(newImage);
            }
        }
    }

    redoLastStep = () => {
        if (this.state.isSvgActive) {
            if (this.state.selectedShape) {
                this.setState({
                    selectedShape: this.state.selectedShape = '',
                    selectedShapes: this.state.selectedShapes = []
                })

                this.selectedShapesTypeChecker(this.state.selectedShapes);
                this.deleteSelectedShapesRect('mouseSelect');

                this.props.getSelectedShape('');
                this.props.getSelectedShapes([]);
            }
            
            if (this.state.canvasRedo.length > 0) {
                let redo2undo = this.state.canvasRedo.pop();
                this.state.canvasImage.push(redo2undo);
            }
    
            let indx = this.state.canvasRedo.length > 0 ? this.state.canvasRedo.length - 1 : 0;
            let newImage = this.state.canvasRedo[indx];
    
            // sessionStorage.setItem('svg', newImage);
            let historyData = JSON.stringify({undo: this.state.canvasImage, redo: this.state.canvasRedo});
            sessionStorage.setItem(`svgCanvas-${this.props.svgID}`, historyData);
            
            if (this.state.canvasRedo.length > 0) {
                this.state.draw.each(function(i, children) {
                    this.remove()
                })
                
                this.state.draw.svg(newImage);
            }
        }
    }

    cloneSelectedShape = (shape) => {
        try {
            if (shape) {
                this.setState({
                    clone: this.state.clone = shape.clone().id('copy')
                })
            }
    
            if (this.state.isGroupingActive) {
                this.setState({
                    clone: this.state.clone = this.g.clone()
                })
            }
        } catch(e) {
            console.log(e);
        }
    }

    addCloneShape2Canvas = (cloneShape) => {
        if (this.state.selectedShape) {
            this.setState({
                clone: this.state.clone = this.state.selectedShape.clone().id('copy')
            })
        } else if (this.state.clone) {
            this.setState({
                clone: this.state.clone = this.state.clone.clone().id('copy')
            })
        }

        if (cloneShape) {
            // let id = cloneShape.id();
            let cloneid = (Math.random() * 100000).toFixed(0);
            this.idCounter();

            switch(cloneShape.type) {
                case 'line':
                    cloneShape.id(`line-${cloneid}`).data('layername', `line-${this.state.lineNum}`);
                    
                    // this.setState({
                    //     currentShapesID: [...this.state.currentShapesID, {id, name: `line-${this.state.i}`}]
                    // })
                    break
                case 'circle':
                    cloneShape.id(`circle-${cloneid}`).data('layername', `circle-${this.state.circleNum}`);

                    // this.setState({
                    //     currentShapesID: [...this.state.currentShapesID, {id, name: `circle-${this.state.i}`}]
                    // })
                    break
                case 'polygon':
                    cloneShape.attr({id: `triangle-${cloneid}`}).data('layername', `triangle-${this.state.triangleNum}`);

                    // this.setState({
                    //     currentShapesID: [...this.state.currentShapesID, {id, name: `triangle-${this.state.i}`}]
                    // })
                    break
                case 'rect':
                    if (cloneShape.hasClass('square')) {
                        cloneShape.attr({id: `square-${cloneid}`}).data('layername', `square-${this.state.squareNum}`);

                        // this.setState({
                        //     currentShapesID: [...this.state.currentShapesID, {id, name: `square-${this.state.i}`}]
                        // })
                    } else {
                        cloneShape.attr({id: `rect-${cloneid}`}).data('layername', `rect-${this.state.rectNum}`);

                        // this.setState({
                        //     currentShapesID: [...this.state.currentShapesID, {id, name: `rect-${this.state.i}`}]
                        // })
                    }
                    break
                case 'g':
                    let clonegid = (Math.random() * 100000).toFixed(0);
                    let j = this.state.i;
                    // let arr = [];

                    if (cloneShape.hasClass('number-group')) {
                        cloneShape.attr(this.state.selectedShape.node.id.includes('romNumGroup') ? { id: `romNumGroup-${clonegid}` } : { id: `arabicNumGroup-${clonegid}` }).data('layername', `num-${this.state.numberNum}`);

                        cloneShape.each((i, childrens) => {
                            let clonesid = (Math.random() * 100000).toFixed(0);

                            switch(childrens[i].type) {
                                case 'line':
                                    childrens[i].attr({id: `line-${clonesid}`}).data('layername', `line-${this.state.i}`);
                                    // arr.push({id: `line-${j}`, name: `line-${j}`});
                                    break
                                default:
                            }

                            j++
                        })
        
                        this.setState({
                            i: this.state.i = j,
                            // currentShapesID: [...this.state.currentShapesID, {id: this.state.clone.id(), name: this.state.clone.id(), gElements: arr}]
                        })
                    } else {
                        cloneShape.attr({id: `group-${clonegid}`}).data('layername', `group-${j}`);

                        cloneShape.each((i, childrens) => {
                            let clonesid = (Math.random() * 100000).toFixed(0);

                            switch(childrens[i].type) {
                                case 'circle':
                                    childrens[i].attr({id: `circle-${clonesid}`}).data('layername', `circle-${j}`);
                                    // arr.push({id: `circle-${j}`, name: `circle-${j}`});
                                    break
                                case 'polygon':
                                    childrens[i].attr({id: `triangle-${clonesid}`}).data('layername', `triangle-${j}`);
                                    // arr.push({id: `shape-${j}`, name: `shape-${j}`});
                                    break
                                case 'line':
                                    childrens[i].attr({id: `line-${clonesid}`}).data('layername', `line-${j}`);
                                    // arr.push({id: `line-${j}`, name: `line-${j}`});
                                    break
                                case 'rect':
                                    childrens[i].attr({id: `rect-${clonesid}`}).data('layername', `rect-${j}`);
                                    // arr.push({id: `rect-${j}`, name: `rect-${j}`});
                                    break
                                default:
                            }

                            j++
                        })
        
                        this.setState({
                            i: this.state.i = j,
                            // currentShapesID: [...this.state.currentShapesID, {id: this.state.clone.id(), name: this.state.clone.id(), gElements: arr}]
                        })
                    }
                    break
                default:
                    this.state.clone.attr({id: `shape-${this.state.i}`});
            }

            this.state.draw.add(cloneShape);

            cloneShape.center(this.state.canvasWidth / 2, this.state.canvasHeight / 2);

            this.idCounter();

            // this.canvasShapesBuffer.add(id);
    
            // this.setState({
            //     canvasShapes: this.state.canvasShapes = this.canvasShapesBuffer
            // })
        }
    }

    updateCanvasElementsName = () => {
        let buffArrID = [];

        this.state.draw.each(function(i, children) {
            if (this.id() !== 'aimcross'
                && !this.hasClass(`patRec`)
                && this.id() !== 'aimpoint'
                && this.id() !== 'circleCenter'
                && !this.hasClass('groupBox')
                && !this.hasClass('marker')
                && this.type !== 'defs'
                && !this.hasClass('canvasGroup')
            ) {
                if (this.hasClass('group')) {
                    let gEl = this.children();
                    let arr = [];

                    gEl.forEach(el => {
                        arr.push({id: el.id(), name: el.data('layername')});
                    })

                    buffArrID.push({id: this.id(), name: this.data('layername'), gElements: arr});
                } else {
                    buffArrID.push({id: this.id(), name: this.data('layername')});
                }
            }
        })

        // this.shapeNames = buffArrID

        this.setState({
            shapeNames: this.state.shapeNames = buffArrID
        })

        // sessionStorage.setItem('layersName', JSON.stringify(buffArrID))
    }

    // sortLayesrs = () => {
    //     let layerShapes = [];

    //     this.state.draw.each(function(i, children) {
    //         if (this.id() !== 'aimcross'
    //             && !this.hasClass(`patRec`)
    //             && this.id() !== 'aimpoint'
    //             && this.id() !== 'circleCenter'
    //             && !this.hasClass('groupBox')
    //             && !this.hasClass('line')
    //             && !this.hasClass('num-line')
    //             && !this.hasClass('num-rect')
    //             // && !this.hasClass('marker')
    //             && this.type !== 'defs'
    //         ) {
    //             layerShapes.push({id: `${this.id()}`, s: this.width() * this.height()});
    //         } else if (this.hasClass('line')) {
    //             layerShapes.push({id: `${this.id()}`, s: 2});
    //         }
    //     })

    //     layerShapes.sort((a, b) => a.s > b.s ? 1 : -1);
    //     layerShapes.reverse();

    //     layerShapes.forEach(el => {
    //         let a = this.state.draw.findOne(`#${el.id}`);
            
    //         a.front();
    //     })
    // }

    zoomCanvas = () => {
        if (this.state.isSvgActive) {
            let vbcx = this.state.zx;
            let vbcy = this.state.zy;
    
            if (this.state.selectedShape) {
                //  zoom center of selected shape
                vbcx = this.state.selectedShape.cx() - (this.state.canvasWidth / 2) + ((this.state.canvasWidth - this.state.zoomWidthIndex) / 2);
                vbcy = this.state.selectedShape.cy() - (this.state.canvasHeight / 2) + ((this.state.canvasHeight - this.state.zoomHeightIndex) / 2);

                if (vbcy < 0) {
                    vbcy = 0
                }

                if (vbcx < 0) {
                    vbcx = 0
                }

                if (this.state.zoomWidthIndex + vbcx > this.state.canvasWidth) {
                    vbcx = this.state.canvasWidth - this.state.zoomWidthIndex
                }

                if (this.state.zoomHeightIndex + vbcy > this.state.canvasHeight) {
                    vbcy = this.state.canvasHeight - this.state.zoomHeightIndex
                }

                this.setState({
                    zx: this.state.zx = vbcx,
                    zy: this.state.zy = vbcy
                })
            } else if (this.state.selectedShapes.length) {
                //  zoom center of selected shapes
                if (this.state.selectedShapes.length === 1) {
                    vbcx = this.state.selectedShapes[0].cx() - (this.state.canvasWidth / 2) + ((this.state.canvasWidth - this.state.zoomWidthIndex) / 2);
                    vbcy = this.state.selectedShapes[0].cy() - (this.state.canvasHeight / 2) + ((this.state.canvasHeight - this.state.zoomHeightIndex) / 2);
    
                    this.setState({
                        zx: this.state.zx = vbcx,
                        zy: this.state.zy = vbcy
                    })
                }
    
                if (this.state.selectedShapes.length > 1) {
                    const selectedShapesRect = this.state.draw.findOne('#selectedShapesRectangle');
        
                    if (selectedShapesRect) {
                        vbcx = selectedShapesRect.cx() - (this.state.canvasWidth / 2) + ((this.state.canvasWidth - this.state.zoomWidthIndex) / 2);
                        vbcy = selectedShapesRect.cy() - (this.state.canvasHeight / 2) + ((this.state.canvasHeight - this.state.zoomHeightIndex) / 2);

                        if (this.state.zoomWidthIndex + vbcx > this.state.canvasWidth) {
                            vbcx = this.state.canvasWidth - this.state.zoomWidthIndex
                        }

                        if (this.state.zoomHeightIndex + vbcy > this.state.canvasHeight) {
                            vbcy = this.state.canvasHeight - this.state.zoomHeightIndex
                        }
            
                        this.setState({
                            zx: this.state.zx = vbcx,
                            zy: this.state.zy = vbcy
                        })
                    }
                }
            } else {
                //  zoom center of canvas
                // vbcx = this.state.zx = ((this.state.canvasWidth - this.state.zoomWidthIndex) / 2);
                // vbcy = this.state.zy = ((this.state.canvasHeight - this.state.zoomHeightIndex) / 2);
    
                //  zoom center of viewpoin
                // vbcx = this.state.zx + 250 - (this.state.canvasWidth / 2) + ((this.state.canvasWidth - this.state.zoomWidthIndex) / 2);
                // vbcy = this.state.zy + 187 - (this.state.canvasHeight / 2) + ((this.state.canvasHeight - this.state.zoomHeightIndex) / 2);
    
                //  zoom center of canvas
                // const centerCanvas = this.state.draw.findOne('#aimcross');
    
                vbcx = (this.state.canvasWidth / 2) - (this.state.canvasWidth / 2) + ((this.state.canvasWidth - this.state.zoomWidthIndex) / 2);
                vbcy = (this.state.canvasHeight / 2) - (this.state.canvasHeight / 2) + ((this.state.canvasHeight - this.state.zoomHeightIndex) / 2);
    
                this.setState({
                    zx: this.state.zx = vbcx,
                    zy: this.state.zy = vbcy
                })
            }
    
            if (this.state.zoomWidthIndex && this.state.zoomHeightIndex) {
                this.state.draw.viewbox(vbcx, vbcy, this.state.zoomWidthIndex, this.state.zoomHeightIndex);
            }
    
            this.props.updateZoomXY({id: this.props.activeTabId, zx: vbcx, zy: vbcy});
        }
    }

    //  Clear SVG data from class, data atributes before export file

    // svgDataCleaner = (svg) => {
    //     let cleared = svg.svg(function(node) {
    //         if (
    //                 node.id() === 'aimcross' ||
    //                 node.hasClass('marker') ||
    //                 // node.id() === `patRec-${this.props.activeTabId}` ||
    //                 node.hasClass(`patRec`) ||
    //                 // node.id() === 'groupBox' ||
    //                 node.hasClass('groupBox') ||
    //                 node.type === 'defs' ||
    //                 node.type === 'path' ||
    //                 node.type === 'rect' && node.width() === 1000
    //             ) {
    //                 return false
    //             } else {
    //                 node.removeClass('canvas-block')
    //                 node.removeClass('aimPoint')
    //                 node.removeClass('selected')
    //                 node.removeClass('line')
    //                 node.removeClass('circle')
    //                 node.removeClass('inZoom')
    //                 node.removeClass('square')
    //                 node.removeClass('rectangle')
    //                 node.removeClass('triangle')
    //                 node.removeClass('number-group')
    //                 node.removeClass('group')
    //                 node.data('layername', null)
    //                 node.data('strokechanged', null)
    //                 node.data('fillchanged', null)
    //                 node.data('direction', null)
    //                 node.id(null)
                    
    //                 if (node.type === 'ellipse') {
    //                     node.id('aimpoint')
    //                 }
    //             }
    //     })

    //     let editedSVG = cleared.replaceAll(' class=""', '')
    //         .replaceAll(' xmlns:svgjs="http://svgjs.dev/svgjs"', '')
    //         .replaceAll('#000000', '#000')
    //         .replaceAll('#ffffff', '#fff')
    //         .replaceAll('#900900', '#900')
    //         .replaceAll('#fa0fa0', '#fa0')
    //         .replaceAll('#ff0ff0', '#ff0')
    //         .replaceAll('#0f00f0', '#0f0')
    //         .replaceAll('#00f00f', '#00f')
    //         .replaceAll('#666666', '#666')

    //     return editedSVG;
    // }

    calcLineGridLength = (line) => {
        let x1 = line.node.attributes[0].nodeValue;
        let y1 = line.node.attributes[1].nodeValue;
        let x2 = line.node.attributes[2].nodeValue;
        let y2 = line.node.attributes[3].nodeValue;

        let l = Math.sqrt((x2-=x1)*x2 + (y2-=y1)*y2);
        let gridL = this.state.moa ? l / this.state.moaAvg : l / this.state.milAvg;

        return gridL.toFixed(1);
    }

    roundCanvasShapesCoords = () => {
        let cloneSVG = this.state.draw.clone();
        let canvasChildrens = [...cloneSVG.children()];

        canvasChildrens.map(ch => {
            if (ch.hasClass('circle')) {
                let centerx = Math.round(ch.cx());
                let centery = Math.round(ch.cy());
                let roundedR = Math.round(ch.radius());
                let roundedStrokeW = Math.round(ch.node.attributes[3].nodeValue);

                ch.center(centerx, centery);
                ch.radius(roundedR);
                ch.stroke({width: roundedStrokeW});
            } else if (ch.hasClass('line')) {
                let lcoords0 = Math.round(ch.node.attributes[0].nodeValue);
                let lcoords1 = Math.round(ch.node.attributes[1].nodeValue);
                let lcoords2 = Math.round(ch.node.attributes[2].nodeValue);
                let lcoords3 = Math.round(ch.node.attributes[3].nodeValue);
                let roundedStrokeW = Math.round(ch.node.attributes[4].nodeValue);

                ch.plot(lcoords0, lcoords1, lcoords2, lcoords3);
                ch.stroke({width: roundedStrokeW});
            } else if (ch.hasClass('triangle')) {
                let pdcoords = '';
                let parsedCoords = [];
                let pcoords = ch.node.attributes[0].nodeValue.split(' ');
                let roundedStrokeW = Math.round(ch.node.attributes[1].nodeValue);

                pcoords.forEach(el => {
                    let part = []
                    pdcoords = el.split(',');
                    part.push(Math.round(pdcoords[0]));
                    part.push(Math.round(pdcoords[1]));
                    parsedCoords.push(part)
                })

                ch.plot(parsedCoords);
                ch.stroke({width: roundedStrokeW});
            } else if (ch.hasClass('square') || ch.hasClass('rectangle')) {
                let recX = Math.round(ch.x());
                let recY = Math.round(ch.y());
                let recW = Math.round(ch.width());
                let recH = Math.round(ch.height());
                let roundedStrokeW = Math.round(ch.node.attributes[4].nodeValue);

                ch.width(recW);
                ch.height(recH);
                ch.move(recX, recY);
                ch.stroke({width: roundedStrokeW});
            }
        })

        return cloneSVG;
    }

    svgGridRemove = () => {
        let cleened = this.state.draw.svg(function(node) {
            if (
                    node.id() === 'aimcross' ||
                    node.hasClass('marker') ||
                    node.hasClass('groupBox') ||
                    // node.id() === `patRec` ||
                    node.hasClass('patRec') ||
                    node.id() === 'groupBox' ||
                    node.type === 'defs' ||
                    node.hasClass('mouseSelect') ||
                    node.hasClass('canvasGroup')
                ) return false
        })

        return cleened;
    }

    handlerZoomScroll = (e) => {
        if (e.ctrlKey || e.metaKey) {
            e.preventDefault();
            
            if (e.deltaY > 0) {
                if (this.state.zoomIndex < 190) {
                    this.setState({
                        zoomIndex: this.state.zoomIndex = this.state.zoomIndex + 10,
                        zoomWidthIndex: this.state.zoomWidthIndex -= this.state.canvasWidth * 0.1,
                        zoomHeightIndex: this.state.zoomHeightIndex -= this.state.canvasHeight * 0.1
                    })

                    this.zoomCanvas();
        
                    this.props.updateZoom({id: this.props.activeTabId, index: this.state.zoomIndex + 10})
    
                    if (!this.state.isZoom){
                        this.setState({
                            isZoom: this.state.isZoom = true
                        })
                    }
                }
            } else {
                if (this.state.zoomIndex > 100) {
                    this.setState({
                        zoomIndex: this.state.zoomIndex = this.state.zoomIndex - 10,
                        zoomWidthIndex: this.state.zoomWidthIndex += (this.state.canvasWidth * 0.1),
                        zoomHeightIndex: this.state.zoomHeightIndex += (this.state.canvasHeight * 0.1)
                    })
        
                    if (!this.state.isZoom){
                        this.setState({
                            isZoom: this.state.isZoom = true
                        })
                    }

                    this.props.updateZoom({id: this.props.activeTabId, index: this.state.zoomIndex - 10})
    
                    if (this.state.zoomIndex === 100) {
                        if (this.state.isZoom){
                            this.setState({
                                isZoom: this.state.isZoom = false
                            })
                        }
                    }
                    
                } else {
                    if (this.state.isZoom){
                        this.setState({
                            isZoom: this.state.isZoom = false
                        })
                    }
                }
            }
        }
    }

    moveShapeSmoothInZoom = (shape, x, y) => {
        const shapeWidth = shape.width() / 2;
        const shapeHeight = shape.height() / 2;
        const shapeCX = shape.cx();
        const shapeCY = shape.cy();

        if (shape && shape.type !== 'polygon' && this.state.isZoom) {
            if (shape.type === 'circle') {
                if (x > shapeCX && y > shapeCY) {
                    this.coordsToGridSize(x - this.state.sx, y - this.state.sy);
                } else if (x < shapeCX && y < shapeCY) {
                    this.coordsToGridSize(x + this.state.sx, y + this.state.sy);
                } else if (x > shapeCX && y < shapeCY) {
                    this.coordsToGridSize(x - this.state.sx, y + this.state.sy);
                } else if (x < shapeCX && y > shapeCY) {
                    this.coordsToGridSize(x + this.state.sx, y - this.state.sy);
                } else {
                    this.coordsToGridSize(x, y);
                }
            } else {
                this.coordsToGridSize(x - this.state.rx, y - this.state.ry);
            }
        } else {
            const triangleDirection = shape.data('direction');

            switch(triangleDirection) {
                case 'top':
                    if (x > shapeCX) {
                        this.coordsToGridSize(x - this.state.sx, y - this.state.ry);
                    } else {
                        this.coordsToGridSize(x + this.state.sx, y - this.state.ry);
                    }
                    break
                case 'right':
                    if (y > shapeCY) {
                        this.coordsToGridSize((x + shapeWidth * 2) - this.state.rx, y - this.state.sy);
                    } else {
                        this.coordsToGridSize((x + shapeWidth * 2) - this.state.rx, y + this.state.sy);
                    }
                    break
                case 'bottom':
                    if (x > shapeCX && y > shapeCY) {
                        this.coordsToGridSize(x - this.state.sx, (y + shapeHeight * 2) - this.state.ry);
                    } else if (x > shapeCX && y < shapeCY) {
                        this.coordsToGridSize(x - this.state.sx, (y + shapeHeight * 2) - this.state.ry);
                    } else {
                        this.coordsToGridSize(x + this.state.sx, (y + shapeHeight * 2) - this.state.ry)
                    }
                    break
                case 'left':
                    if (y > shapeCY) {
                        this.coordsToGridSize(x - this.state.rx, y - this.state.sy);
                    } else {
                        this.coordsToGridSize(x - this.state.rx, y + this.state.sy);
                    }
                    break
                default:
                    this.coordsToGridSize(x, y);
            }
        }
    }

    moveShapeSmooth = (shape, x, y) => {
        const shapeWidth = shape.width() / 2;
        const shapeHeight = shape.height() / 2;
        const shapeCX = shape.cx();
        const shapeCY = shape.cy();

        if (shape && shape.type !== 'polygon' && !this.state.isZoom) {
            if (shape.type === 'circle') {
                if (x > shapeCX && y > shapeCY) {
                    this.coordsToGridSize(x - this.state.sx, y - this.state.sy);
                } else if (x < shapeCX && y < shapeCY) {
                    this.coordsToGridSize(x + this.state.sx, y + this.state.sy);
                } else if (x > shapeCX && y < shapeCY) {
                    this.coordsToGridSize(x - this.state.sx, y + this.state.sy);
                } else if (x < shapeCX && y > shapeCY) {
                    this.coordsToGridSize(x + this.state.sx, y - this.state.sy);
                } else {
                    this.coordsToGridSize(x, y);
                }
            } else {
                this.coordsToGridSize(x - this.state.rx, y - this.state.ry);
            }
        } else {
            const triangleDirection = shape.data('direction');

            switch(triangleDirection) {
                case 'top':
                    if (x > shapeCX) {
                        this.coordsToGridSize(x - this.state.sx, y - this.state.ry);
                    } else {
                        this.coordsToGridSize(x + this.state.sx, y - this.state.ry);
                    }
                    break
                case 'right':
                    if (y > shapeCY) {
                        this.coordsToGridSize((x + shapeWidth * 2) - this.state.rx, y - this.state.sy);
                    } else {
                        this.coordsToGridSize((x + shapeWidth * 2) - this.state.rx, y + this.state.sy);
                    }
                    break
                case 'bottom':
                    if (x > shapeCX && y > shapeCY) {
                        this.coordsToGridSize(x - this.state.sx, (y + shapeHeight * 2) - this.state.ry);
                    } else if (x > shapeCX && y < shapeCY) {
                        this.coordsToGridSize(x - this.state.sx, (y + shapeHeight * 2) - this.state.ry);
                    } else {
                        this.coordsToGridSize(x + this.state.sx, (y + shapeHeight * 2) - this.state.ry)
                    }
                    break
                case 'left':
                    if (y > shapeCY) {
                        this.coordsToGridSize(x - this.state.rx, y - this.state.sy);
                    } else {
                        this.coordsToGridSize(x - this.state.rx, y + this.state.sy);
                    }
                    break
                default:
                    this.coordsToGridSize(x, y);
            }
        }
    }

    // resizeSelectedShape = (selectedShape, oldX, oldY, newX, newY, oldW, oldH) => {
    //     if (this.state.isZoom) {
    //         let x = newX - (newX * this.state.zoomK) + this.state.zx;
    //         let y = newY - (newY * this.state.zoomK) + this.state.zy;

    //         if (this.objX <= x && this.objY <= y) {
    //             selectedShape.width(x - this.objX);
    //             selectedShape.height(y - this.objY);
    //         } else if (this.objX > x && this.objY <= y) {
    //             selectedShape.x(x);
    //             selectedShape.width(this.objX - x);
    //             selectedShape.height(y - this.objY);
    //         } else if (this.objX <= x && this.objY > y) {
    //             selectedShape.y(y);
    //             selectedShape.width(x - this.objX);
    //             selectedShape.height(this.objY - y);
    //         } else if (this.objX > x && this.objY > y) {
    //             selectedShape.x(x);
    //             selectedShape.y(y);
    //             selectedShape.width(this.objX - x);
    //             selectedShape.height(this.objY - y);
    //         }
    //     } else {
    //         if (oldX <= newX && oldY <= newY) {
    //             selectedShape.x(newX);
    //             selectedShape.y(newY);
    //             selectedShape.width(oldW - (newX - oldX));
    //             selectedShape.height(oldH - (newY - oldY));
    //         } else if (oldX > newX && oldY <= newY) {
    //             selectedShape.x(newX);
    //             selectedShape.width(oldX - newX);
    //             selectedShape.height(newY - selectedShape.y());
    //         } else if (oldX <= newX && oldY > newY) {
    //             selectedShape.y(newY);
    //             selectedShape.width(newX - oldX);
    //             selectedShape.height(oldY - newY);
    //         } else if (oldX > newX && oldY > newY) {
    //             selectedShape.x(newX);
    //             selectedShape.y(newY);
    //             selectedShape.width(oldW + (oldX - newX));
    //             selectedShape.height(oldH + (oldY - newY));
    //         }
    //     }
    // }

    resizeSelectedShape = (selectedShape, marker, newX, newY, oldX, oldY, oldW, oldH, oldR, oldCX, oldCY, e) => {
        let markerID = marker.id();
        const minShapeSize = this.state.moa ?
            this.state.moaAvg * this.state.gridSize :
            this.state.milAvg * this.state.gridSize;

        if (selectedShape.type === 'line') {
            switch(markerID) {
                case 'm0':
                    marker.cx(newX);
                    marker.cy(newY);
                    selectedShape.attr({x1: newX, y1: newY});
                    break;
                case 'm5':
                    let lx1 = newX - selectedShape.width() / 2 + selectedShape.attr('stroke-width') > 0;
                    let ly1 = newY- selectedShape.height() / 2 + selectedShape.attr('stroke-width') > 0;
                    let lx2 = newX + selectedShape.width() / 2 - selectedShape.attr('stroke-width') < this.state.canvasWidth;
                    let ly2 = newY + selectedShape.height() / 2 - selectedShape.attr('stroke-width') < this.state.canvasHeight;

                    marker.cx(newX);
                    marker.cy(newY);

                    if (lx1 && lx2) {
                        selectedShape.cx(newX);
                    }

                    if (ly1 && ly2) {
                        selectedShape.cy(newY);
                    }
                    break;
                case 'm7':
                    marker.cx(newX);
                    marker.cy(newY);
                    selectedShape.attr({x2: newX, y2: newY});
                    break;
                // case 'm8':
                    // this.rotateLine(selectedShape, selectedShape.cx(), selectedShape.cy(), e.movementX * -1);
                    // this.rotateLine(selectedShape, selectedShape.cx(), selectedShape.cy(), 90);
                    // break;
                default:
                    marker.cx(newX);
                    marker.cy(newY);
            }
        } else if (selectedShape.type === 'circle') {
            switch(markerID) {
                case 'm0':
                    if (newX <= oldCX - minShapeSize) {
                        marker.cx(newX);
                        marker.cy(newY);
                        selectedShape.radius(oldCX - newX);
                    }
        
                    if (newY <= oldCY - minShapeSize) {
                        marker.cx(newX);
                        marker.cy(newY);
                        selectedShape.radius(oldCY - newY);
                    }
                    break;
                case 'm2':
                    if (newX >= oldCX + minShapeSize) {
                        marker.cx(newX);
                        marker.cy(newY);
                        selectedShape.radius(newX - oldCX);
                    }
        
                    if (newY <= oldCY - minShapeSize) {
                        marker.cx(newX);
                        marker.cy(newY);
                        selectedShape.radius(oldCY - newY);
                    }
                    break;
                case 'm5':
                    if (newX <= oldCX - minShapeSize) {
                        marker.cx(newX);
                        marker.cy(newY);
                        selectedShape.radius(oldCX - newX);
                    }
        
                    if (newY >= oldCY + minShapeSize) {
                        marker.cx(newX);
                        marker.cy(newY);
                        selectedShape.radius(newY - oldCY);
                    }
                    break;
                case 'm7':
                    if (newX >= oldCX + minShapeSize) {
                        marker.cx(newX);
                        marker.cy(newY);
                        selectedShape.radius(newX - oldCX);
                    }
        
                    if (newY >= oldCY + minShapeSize) {
                        marker.cx(newX);
                        marker.cy(newY);
                        selectedShape.radius(newY - oldCY);
                    }
                    break;
                default:
                    marker.cx(newX);
                    marker.cy(newY);
            }
        } else if (selectedShape.type === 'rect' && selectedShape.hasClass('square')) {
            switch (markerID) {
                case 'm0':
                    if (newX <= oldX + oldW - minShapeSize) {
                        marker.center(newX, (oldY + (newX - oldX)));
                        selectedShape.move(newX, (oldY + (newX - oldX)));
                        selectedShape.size(oldW - (newX - oldX));
                    }

                    if (newY <= oldY + oldH - minShapeSize) {
                        marker.center((oldX + (newY - oldY)), newY);
                        selectedShape.move((oldX + (newY - oldY)), newY);
                        selectedShape.size(oldH - (newY - oldY));
                    }

                    break;
                case 'm2':
                    if (newY <= oldY + oldH - minShapeSize) {
                        marker.cy(newY);
                        selectedShape.y(newY);
                        selectedShape.size(oldH - (newY - oldY));
                    }

                    break;
                case 'm5':
                    if (newX <= oldX + oldW - minShapeSize) {
                        marker.center(newX, (oldY + (newX - oldX)));
                        selectedShape.x(newX);
                        selectedShape.size(oldW - (newX - oldX));
                    }

                    break;
                case 'm7':
                    if (newX >= oldX + minShapeSize) {
                        marker.center(newX, (oldY + (newX - oldX)));
                        selectedShape.size(newX - oldX);
                    }

                    break;
                default:
                    marker.cx(newX);
                    marker.cy(newY);
            }
        } else if (selectedShape.type === 'polygon' && selectedShape.hasClass('triangle')) {
            const step = this.state.moa ? this.state.gridSize * this.state.moaAvg : this.state.gridSize * this.state.milAvg;
            let dir = selectedShape.data('direction');
            let markerClass = this.state.currentMarker.hasClass('right-diagonal-resize');
            let oldPolyCoords = selectedShape.plot();
            let newPolyCoords = [];

            switch (markerID) {
                case 'm0':
                    newPolyCoords = editPolygonCoords(oldPolyCoords, newX, newY, 'left', step, dir, markerClass);
                    
                    break;
                case 'm2':
                    newPolyCoords = editPolygonCoords(oldPolyCoords, newX, newY, 'right', step, dir, markerClass);

                    break;
                case 'm5':
                    newPolyCoords = editPolygonCoords(oldPolyCoords, newX, newY, 'left', step, dir, markerClass);

                    break;
                case 'm7':
                    newPolyCoords = editPolygonCoords(oldPolyCoords, newX, newY, 'right', step, dir, markerClass);

                    break;
                default:
                    marker.cx(newX);
                    marker.cy(newY);
            }

            selectedShape.plot(newPolyCoords);
        } else {
            switch (markerID) {
                case 'm0':
                    if (newX <= oldX + oldW - minShapeSize) {
                        marker.cx(newX);
                        selectedShape.x(newX);
                        selectedShape.width(oldW - (newX - oldX));
                    }
    
                    if (newY <= oldY + oldH - minShapeSize) {
                        marker.cy(newY);
                        selectedShape.y(newY);
                        selectedShape.height(oldH - (newY - oldY));
                    }
                    break;
                case 'm1':
                    if (oldY >= newY) {
                        selectedShape.y(newY);
                        marker.cy(newY);
                        selectedShape.height(oldH + (oldY - newY));
                    } else if (oldY < newY) {
                        if (oldH - (newY - oldY) > minShapeSize) {
                            selectedShape.y(newY);
                            marker.cy(newY);
                            selectedShape.height(oldH - (newY - oldY));
                        }
                    }
                    break;
                case 'm2':
                    if (newX >= oldX + minShapeSize) {
                        marker.cx(newX);
                        selectedShape.width(newX - oldX);
                    }
    
                    if (newY <= oldY + oldH - minShapeSize) {
                        selectedShape.y(newY);
                        marker.cy(newY);
                        selectedShape.height(oldH + (oldY - newY));
                    }
                    break;
                case 'm3':
                    if (oldX >= newX) {
                        selectedShape.x(newX);
                        marker.cx(newX);
                        selectedShape.width(oldW + (oldX - newX));
                    } else if (oldX <= newX) {
                        if (oldW - (newX - oldX) > minShapeSize) {
                            selectedShape.x(newX);
                            marker.cx(newX);
                            selectedShape.width(oldW - (newX - oldX));
                        }
                    }
                    break;
                case 'm4':
                    if (oldX <= newX) {
                        if ((newX - oldX) > minShapeSize) {
                            marker.cx(newX);
                            selectedShape.width(newX - oldX);
                        }
                    }
                    break;
                case 'm5':
                    if (newX <= oldX + oldW - minShapeSize) {
                        marker.cx(newX);
                        selectedShape.x(newX);
                        selectedShape.width(oldW - (newX - oldX));
                    }
    
                    if (newY >= oldY + minShapeSize) {
                        marker.cy(newY);
                        selectedShape.height(newY - oldY);
                    }
                    break;
                case 'm6':
                    if (newY >= oldY + minShapeSize) {
                        marker.cy(newY);
                        selectedShape.height(newY - oldY);
                    }
                    break;
                case 'm7':
                    if (newX >= oldX + minShapeSize) {
                        marker.cx(newX);
                        selectedShape.width(newX - oldX);
                    }
    
                    if (newY >= oldY + minShapeSize) {
                        marker.cy(newY);
                        selectedShape.height(newY - oldY);
                    }
                    break;
                // case 'm8':
                //     this.rotateTriangle(selectedShape, selectedShape.cx(), selectedShape.cy(), e.movementX * -1);
                //     break;
                default:
                    marker.cx(newX);
                    marker.cy(newY);
            }
        }

        this.rectMarkerReRender(selectedShape);
    }

    resizeRectangleSelectTool = (selectedShape, newX, newY) => {
        if (this.state.isZoom) {
            let x = newX - (newX * this.state.zoomK) + this.state.zx;
            let y = newY - (newY * this.state.zoomK) + this.state.zy;

            if (this.objX <= x && this.objY <= y) {
                selectedShape.width(x - this.objX);
                selectedShape.height(y - this.objY);
            } else if (this.objX > x && this.objY <= y) {
                selectedShape.x(x);
                selectedShape.width(this.objX - x);
                selectedShape.height(y - this.objY);
            } else if (this.objX <= x && this.objY > y) {
                selectedShape.y(y);
                selectedShape.width(x - this.objX);
                selectedShape.height(this.objY - y);
            } else if (this.objX > x && this.objY > y) {
                selectedShape.x(x);
                selectedShape.y(y);
                selectedShape.width(this.objX - x);
                selectedShape.height(this.objY - y);
            }
        } else {
            if (this.state.x <= newX && this.state.y <= newY) {
                selectedShape.width(newX - selectedShape.x());
                selectedShape.height(newY - selectedShape.y());
            } else if (this.state.x > newX && this.state.y <= newY) {
                selectedShape.x(newX);
                selectedShape.width(this.state.x - newX);
                selectedShape.height(newY - selectedShape.y());
            } else if (this.state.x <= newX && this.state.y > newY) {
                selectedShape.y(newY);
                selectedShape.width(newX - this.state.x);
                selectedShape.height(this.state.y - newY);
            } else if (this.state.x > newX && this.state.y > newY) {
                selectedShape.x(newX);
                selectedShape.y(newY);
                selectedShape.width(this.state.x - newX);
                selectedShape.height(this.state.y - newY);
            }
        }
    }

    moveEachSelectedShape = (shapes, gX, gY, selectedShape) => {
        if (shapes.length > 0) {
            const step = this.state.moa ? this.state.gridSize * this.state.moaAvg : this.state.gridSize * this.state.milAvg;
            let rightShape = shapes[0];
            let leftShape = shapes[0];
            let topShape = shapes[0];
            let bottomShape = shapes[0];
            let isCorrectStepX = (((gX - selectedShape.x()) % step) === 0);
            let isCorrectStepY = (((gY - selectedShape.y()) % step) === 0);

            for (let i = 1; i < shapes.length; i++) {
                if ((shapes[i].x() + shapes[i].width() + shapes[i].attr('stroke-width')) > 
                    (rightShape.x() + rightShape.width() + rightShape.attr('stroke-width'))
                ) {
                    rightShape = shapes[i];
                }

                if ((shapes[i].x() - shapes[i].attr('stroke-width')) < 
                    (leftShape.x() - leftShape.attr('stroke-width'))
                ) {
                    leftShape = shapes[i];
                }

                if ((shapes[i].y() - shapes[i].attr('stroke-width')) < 
                    (topShape.y() - topShape.attr('stroke-width'))
                ) {
                    topShape = shapes[i];
                }

                if ((shapes[i].y() + shapes[i].height() + shapes[i].attr('stroke-width')) > 
                    (bottomShape.y() + bottomShape.height() + bottomShape.attr('stroke-width'))
                ) {
                    bottomShape = shapes[i];
                }
            }

            if ((selectedShape.x() + selectedShape.width() + selectedShape.attr('stroke-width') + step < this.state.canvasWidth) &&
                (selectedShape.x() - selectedShape.attr('stroke-width') - step > 0)) {
                // if (!this.state.isClose2borderX) {
                //     shapes.forEach(el => {
                //         if (isCorrectStepX) {
                //             el.dx(gX - selectedShape.x());
                //         }
                //     })
                // } else if (this.state.isClose2borderX && (gX - selectedShape.x() < 0)) {
                //     shapes.forEach(el => {
                //         if (isCorrectStepX) {
                //             el.dx(gX - selectedShape.x());
                //         }
                //     })
                // }

                if (!this.state.isClose2borderX) {
                    shapes.forEach(el => {
                        if (isCorrectStepX) {
                            el.dx(gX - selectedShape.x());
                        }
                    })
                }
            } else if ((selectedShape.x() - selectedShape.attr('stroke-width') - step <= 0) /*&&
                ((gX - selectedShape.x()) > 0)*/) {
                    if ((gX - selectedShape.x()) > 0) {
                        shapes.forEach(el => {
                            if (isCorrectStepX) {
                                el.dx(gX - selectedShape.x());
                            }
                        })
                    }
            } else if ((selectedShape.x() + selectedShape.width() + selectedShape.attr('stroke-width') + step  >= this.state.canvasWidth)) {
                if ((gX - selectedShape.x()) < 0) {
                    shapes.forEach(el => {
                        if (isCorrectStepX) {
                            el.dx(gX - selectedShape.x());
                        }
                    })
                }
            }

            if ((selectedShape.y() - selectedShape.attr('stroke-width') - step > 0) &&
                (selectedShape.y() + selectedShape.height() + selectedShape.attr('stroke-width') + step < this.state.canvasHeight)) {
                // if (!this.state.isClose2borderY) {
                //     shapes.forEach(el => {
                //         if (isCorrectStepY) {
                //             el.dy(gY - selectedShape.y());
                //         }
                //     })
                // } else if (this.state.isClose2borderY && gY - selectedShape.y() < 0) {
                //     shapes.forEach(el => {
                //         if (isCorrectStepY) {
                //             el.dy(gY - selectedShape.y());
                //         }
                //     })
                // }

                if (!this.state.isClose2borderY) {
                    shapes.forEach(el => {
                        if (isCorrectStepY) {
                            el.dy(gY - selectedShape.y());
                        }
                    })
                }
            } else if ((selectedShape.y() - selectedShape.attr('stroke-width') - step <= 0) /*&&
                ((gY - selectedShape.y()) > 0)*/) {
                if ((gY - selectedShape.y()) > 0) {
                    shapes.forEach(el => {
                        if (isCorrectStepY) {
                            el.dy(gY - selectedShape.y());
                        }
                    })
                }
            }

            if ((selectedShape.y() + selectedShape.height() + selectedShape.attr('stroke-width')) + step >= this.state.canvasHeight) {
                if (gY - selectedShape.y() < 0) {
                    shapes.forEach(el => {
                        if (isCorrectStepY) {
                            el.dy(gY - selectedShape.y());
                        }
                    })
                }
            }
        }
    }

    moveShapeToCanvasBorders = (shape, gridX, gridY) => {
        const minGridX = this.state.gX[0];
        const minGridY = this.state.gY[0];
        const step = this.state.moa ? this.state.gridSize * this.state.moaAvg : this.state.gridSize * this.state.milAvg;
        let minX = shape.x() - shape.attr('stroke-width') - step > 0;
        let minY = shape.y() - shape.attr('stroke-width') - step > 0;
        let maxX = shape.x() + shape.width() + shape.attr('stroke-width') + step < this.state.canvasWidth;
        let maxY = shape.y() + shape.height() + shape.attr('stroke-width') + step < this.state.canvasHeight;

        if (shape.type === 'circle') {
            if (minX && maxX) {
                shape.cx(gridX);

                if (shape.x() <= 0) {
                    shape.cx(shape.width() / 2 + 1);
                }

                if (shape.x() + shape.width() > this.state.canvasWidth) {
                    shape.cx(this.state.canvasWidth - shape.width() / 2 - 1);
                }
            }

            if (!maxX) {
                if (gridX < shape.cx()) {
                    shape.cx(gridX);
                }
            }

            if (!minX) {
                if (gridX > shape.cx()) {
                    shape.cx(gridX);
                }
            }

            if (minY && maxY) {
                shape.cy(gridY);

                if (shape.y() <= 0) {
                    shape.cy(shape.height() / 2 + 1);
                }

                if (shape.y() + shape.height() > this.state.canvasHeight) {
                    shape.cy(this.state.canvasHeight - shape.height() / 2 - 1);
                }
            }

            if (!maxY) {
                if (gridY < shape.cy()) {
                    shape.cy(gridY);
                }
            }

            if (!minY) {
                if (gridY > shape.cy()) {
                    shape.cy(gridY);
                }
            }
        }

        if (shape.type === 'polygon') {
            switch (shape.data('direction')) {
                case 'top':
                    let ptminX = gridX - shape.width() / 2 > 0;
                    let ptmaxX = gridX + shape.width() / 2 < this.state.canvasWidth;
                    let ptmaxY = gridY + shape.height() < this.state.canvasHeight;

                    if (ptminX && ptmaxX && gridX >= this.state.canvasWidth / 2) {
                        shape.x(gridX - shape.width() / 2);
                    }
        
                    if (ptmaxY) {
                        shape.y(gridY);
                    }
        
                    if (ptminX && gridX <= this.state.canvasWidth / 2) {
                        shape.x(gridX - shape.width() / 2);
                    }
                    break
                case 'right':
                    let prminX = gridX - shape.width() > 0;
                    let prmaxX = gridX - shape.width() < this.state.canvasWidth;
                    let prminY = gridY - shape.height() / 2 > 0;
                    let prmaxY = gridY + shape.height() / 2 < this.state.canvasHeight;

                    if (prminX && prmaxX) {
                        shape.x(gridX - shape.width());
                    }

                    if (prminY && prmaxY) {
                        shape.y(gridY - shape.height() / 2);
                    }
                    break
                case 'left':
                    let plminX = gridX + shape.width() > 0;
                    let plmanX = gridX + shape.width() < this.state.canvasWidth;
                    let plminY = gridY - shape.height() / 2 > 0;
                    let plmaxY = gridY + shape.height() / 2 < this.state.canvasHeight;

                    if (plminX && plmanX) {
                        shape.x(gridX);
                    }

                    if (plminY && plmaxY) {
                        shape.y(gridY - this.state.selectedShape.height() / 2);
                    }
                    break
                case 'bottom':
                    let pbminX = gridX - shape.width() / 2 > 0;
                    let pbmaxX = gridX + shape.width() / 2 < this.state.canvasWidth;
                    let pbminY = gridY - shape.height() > 0;
                    let pbmaxY = gridY - shape.height() < this.state.canvasHeight;

                    if (pbminX && pbmaxX) {
                        shape.x(gridX - shape.width() / 2);
                    }

                    if (pbminY && pbmaxY) {
                        shape.y(gridY - shape.height());
                    }
                    break
                default:
                    shape.move(gridX, gridY);
            }
        }

        if (shape.type !== 'circle' && shape.type !== 'polygon') {
            if (minX && maxX) {
                if (this.state.isClose2borderX) {
                    this.setState({
                        isClose2borderX: false
                    })
                }

                shape.x(gridX);

                if (shape.x() === 0) {
                    shape.x(1);
                }

                if (shape.x() + shape.width() > this.state.canvasWidth) {
                    shape.x(this.state.canvasWidth - shape.width() - 1);
                }

                // if (shape.x() + shape.width() + 3 >= this.state.canvasWidth) {
                //     this.touchBorder = true;
                // } else {
                //     this.touchBorder = false;
                // }
            }

            if (!maxX) {
                if (!this.state.isClose2borderX) {
                    this.setState({
                        isClose2borderX: true
                    })
                }

                if (gridX < shape.x()) {
                    shape.x(gridX);
                }
            }

            if (!minX) {
                if (!this.state.isClose2borderX) {
                    this.setState({
                        isClose2borderX: true
                    })
                }

                if (gridX > shape.x()) {
                    shape.x(gridX);
                }
            }

            if (minY && maxY) {
                if (this.state.isClose2borderY) {
                    this.setState({
                        isClose2borderY: false
                    })
                }

                shape.y(gridY);

                if (shape.y() === 0) {
                    shape.y(1);
                }

                if (shape.y() + shape.height() > this.state.canvasHeight) {
                    shape.y(this.state.canvasHeight - shape.height() - 1);
                }

                // if (shape.y() + shape.height() + 3 >= this.state.canvasHeight) {
                //     this.touchBorder = true;
                // } else {
                //     this.touchBorder = false;
                // }
            }

            if (!maxY) {
                if (!this.state.isClose2borderY) {
                    this.setState({
                        isClose2borderY: true
                    })
                }

                if (gridY < shape.y()) {
                    shape.y(gridY);
                }
            }

            if (!minY) {
                if (gridY > shape.y()) {
                    shape.y(gridY);
                }
            }
        }
    }

    // getClearedData2Export = () => {
    //     this.roundCanvasShapesCoords();
    //     this.svgDataCleaner();
    //     this.props.getClearedData2Export(this.svgDataCleaner());
    // }

    // When mouse press left button on canvas

    onMouseDown = (e) => {
        this.setState({
            x: this.state.x = e.offsetX,
            y: this.state.y = e.offsetY,
            x2: this.state.x2 = e.offsetX,
            y2: this.state.y2 = e.offsetY,
            firstX: this.state.firstX = e.offsetX,
            firstY: this.state.firstY = e.offsetY,
            isDrowing: this.state.isDrowing = true,
        })

        if (e.button === 2) {
            this.prevActiveShapeBtn = this.props.activeShapeButton;

            if (this.state.isZoom) {
                this.state.draw.addClass('grab-canvas');
            }

            this.props.setActiveShapeButton({name: 'hand', direction: ''});

            return;
        }

        if (e.target.classList && !this.props.focusedInput) {
            if (e.target.classList[0] === 'circle' ||
                e.target.classList[0] === 'line' ||
                e.target.classList[0] === 'square' ||
                e.target.classList[0] === 'rectangle' ||
                e.target.classList[0] === 'triangle' ||
                e.target.classList[0] === 'num-line') {
                this.setState({
                    isShapeSelected: this.state.isShapeSelected = e.target.id
                })
            } else {
                this.setState({
                    isShapeSelected: this.state.isShapeSelected = ''
                })
            }
        }

        if (this.state.isZoom && this.state.currentTool === 'hand') {
            this.state.draw.addClass('grab-canvas');
        }

        this.coordsToGridSize(this.state.x, this.state.y);

        if (this.props.instruments.move) {
            this.setState({
                currentTool: this.state.currentTool = 'move'
            })
        } else if (this.props.instruments.circle) {
            this.setState({
                currentTool: this.state.currentTool = 'circle'
            })
        } else if (this.props.instruments.line) {
            this.setState({
                currentTool: this.state.currentTool = 'line'
            })
        } else if (this.props.instruments.simpleLine) {
            this.setState({
                currentTool: this.state.currentTool = 'simpleLine'
            })
        }

        switch(this.state.currentTool) {
            case 'circle':
                this.markerCleaner();

                if (this.state.isZoom) {
                    let markerZoomX = this.state.gridX - (this.state.gridX * this.state.zoomK) + this.state.zx;
                    let markerZoomY = this.state.gridY - (this.state.gridY * this.state.zoomK) + this.state.zy;
                    
                    this.coordsToGridSize(markerZoomX, markerZoomY);
                    
                    this.createCircle(this.gridToPXConverter(this.state.r), this.state.gridX, this.state.gridY);
                } else {
                    this.createCircle(this.gridToPXConverter(this.state.r), this.state.gridX, this.state.gridY);
                }
                break
            case 'move':
                this.moveTool(e);
                this.bBoxCleaner();
                this.bBoxRender();

                if (this.state.selectedShape) {
                    this.setState({
                        sx: this.state.sx = Math.abs(e.offsetX - this.state.selectedShape.cx()),
                        sy: this.state.sy = Math.abs(e.offsetY - this.state.selectedShape.cy()),
                        rx: this.state.rx = e.offsetX - this.state.selectedShape.x(),
                        ry: this.state.ry = e.offsetY - this.state.selectedShape.y()
                    })
                }

                switch (this.state.selectedShape.type) {
                    case 'line':
                        let lineGridL = this.getLineLengt(this.state.selectedShape);
                        const lineDimensionsObj = {
                            type: 'line',
                            Length: lineGridL,
                            width: 0,
                            height: 0,
                            radius: 0
                        }

                        this.props.addShapeData(lineDimensionsObj);
                        break;
                    case 'circle':
                        let circleGridRadius = this.state.moa ? this.state.selectedShape.radius() / this.state.moaAvg : this.state.selectedShape.radius() / this.state.milAvg;
                        const circleDimensionsObj = {
                            type: 'circle',
                            Length: 0,
                            width: 0,
                            height: 0,
                            radius: circleGridRadius
                        }

                        this.props.addShapeData(circleDimensionsObj);
                        break;
                    case 'rect':
                        let rectGridWidth = this.state.moa ? this.state.selectedShape.width() / this.state.moaAvg : this.state.selectedShape.width() / this.state.milAvg;
                        let rectGridHeight = this.state.moa ? this.state.selectedShape.height() / this.state.moaAvg : this.state.selectedShape.height() / this.state.milAvg;
                        const rectDimensionsObj = {
                            type: 'rect',
                            Length: 0,
                            width: rectGridWidth,
                            height: rectGridHeight,
                            radius: 0
                        }

                        this.props.addShapeData(rectDimensionsObj);
                        break;
                    case 'polygon':
                        let polygonGridWidth = this.state.moa ? this.state.selectedShape.width() / this.state.moaAvg : this.state.selectedShape.width() / this.state.milAvg;
                        const polygonDimensionsObj = {
                            type: 'polygon',
                            Length: 0,
                            width: polygonGridWidth,
                            height: 0,
                            radius: 0
                        }

                        this.props.addShapeData(polygonDimensionsObj);
                        break;
                    case 'g':
                        let groupGridWidth = this.state.moa ? this.state.selectedShape.width() / this.state.moaAvg : this.state.selectedShape.width() / this.state.milAvg;
                        const groupDimensionsObj = {
                            type: 'number',
                            Length: 0,
                            width: groupGridWidth,
                            height: 0,
                            radius: 0
                        }

                        this.props.addShapeData(groupDimensionsObj);
                        break;
                    default:
                        const defaultData = {
                            type: '',
                            Length: 0,
                            width: 0,
                            height: 0,
                            radius: 0
                        }
                        
                        this.props.addShapeData(defaultData);
                }

                if (this.state.isZoom && this.state.selectedShape) {
                    let x = this.state.gridX - (this.state.gridX * this.state.zoomK) + this.state.zx;
                    let y = this.state.gridY - (this.state.gridY * this.state.zoomK) + this.state.zy;

                    this.setState({
                        sx: this.state.sx = Math.abs(x - this.state.selectedShape.cx()),
                        sy: this.state.sy = Math.abs(y - this.state.selectedShape.cy()),
                        rx: this.state.rx = x - this.state.selectedShape.x(),
                        ry: this.state.ry = y - this.state.selectedShape.y()
                    })
                }

                if (this.state.selectedShape.type === 'circle') {
                    this.setState({
                        markerX: this.state.markerX = this.state.selectedShape.cx(),
                        markerY: this.state.markerY = this.state.selectedShape.cy(),
                    })
                    
                    this.props.getNewMarkerCoords(this.state.markerX, this.state.markerY, false)
                }

                if (!this.state.selectedShape) {
                    let canvasShapesArr = [];

                    if (this.state.isZoom) {
                        let x = e.offsetX - (e.offsetX * this.state.zoomK) + this.state.zx;
                        let y = e.offsetY - (e.offsetY * this.state.zoomK) + this.state.zy;

                        this.objX = x;
                        this.objY = y;

                        this.createSelectRectangle(x, y, 0, 0);
                    } else {
                        this.createSelectRectangle(this.state.x, this.state.y, 0, 0);

                        this.objX = this.state.x;
                        this.objY = this.state.y;
                    }

                    this.state.draw.each(function(i, children) {
                        if (
                            children[i].hasClass('line') ||
                            children[i].hasClass('circle') ||
                            children[i].hasClass('square') ||
                            children[i].hasClass('triangle') ||
                            children[i].hasClass('rectangle') ||
                            children[i].hasClass('number-group') ||
                            children[i].hasClass('group')
                        ) {
                            canvasShapesArr.push(children[i]);
                        }
                    })

                    this.setState({
                        shapesOnCanvas: this.state.shapesOnCanvas = canvasShapesArr
                    })
                }

                if (this.state.selectedShape) {
                    this.objX = this.state.selectedShape.x();
                    this.objY = this.state.selectedShape.y();
                    this.objW = this.state.selectedShape.width();
                    this.objH = this.state.selectedShape.height();
                    this.objR = this.state.selectedShape.type === 'circle' ?
                        this.state.selectedShape.radius() : 0;
                    this.objCX = this.state.selectedShape.type === 'circle' ?
                        this.state.selectedShape.cx() : 0;
                    this.objCY = this.state.selectedShape.type === 'circle' ?
                        this.state.selectedShape.cy() : 0;

                    if (this.state.currentMarker && this.state.currentMarker.hasClass('rotate-marker')) {
                        if (this.state.selectedShape.type === 'line') {
                            this.rotateLine(this.state.selectedShape, this.state.selectedShape.cx(), this.state.selectedShape.cy(), 90);
                        }

                        if (this.state.selectedShape.type === 'polygon') {
                            this.rotateTriangle(this.state.selectedShape, this.state.selectedShape.cx(), this.state.selectedShape.cy(), 90);
                        }

                        this.rectMarkerReRender(this.state.selectedShape);
                        this.bBoxCleaner();
                        this.bBoxRender();
                    }
                }
                break
            case 'line':
                this.markerCleaner();

                if (this.state.isZoom) {
                    let markerZoomX = this.state.gridX - (this.state.gridX * this.state.zoomK) + this.state.zx;
                    let markerZoomY = this.state.gridY - (this.state.gridY * this.state.zoomK) + this.state.zy;
                    
                    this.coordsToGridSize(markerZoomX, markerZoomY);
                    
                    this.createSimpleLine(this.state.gridX, this.state.gridY, this.state.gridX, this.state.gridY);
                } else {
                    this.createSimpleLine(this.state.gridX, this.state.gridY, this.state.gridX, this.state.gridY);
                }
                break
            case 'square':
                this.markerCleaner();

                if (this.state.isZoom) {
                    let markerZoomX = this.state.gridX - (this.state.gridX * this.state.zoomK) + this.state.zx;
                    let markerZoomY = this.state.gridY - (this.state.gridY * this.state.zoomK) + this.state.zy;
                    
                    this.coordsToGridSize(markerZoomX, markerZoomY);

                    this.createRectangle(this.state.gridX, this.state.gridY, this.state.rectW, this.state.rectW);
                } else {
                    this.createRectangle(this.state.gridX, this.state.gridY, this.state.rectW, this.state.rectW);
                }
                break
            case 'rectangle':
                this.markerCleaner();

                if (this.state.isZoom) {
                    let markerZoomX = this.state.gridX - (this.state.gridX * this.state.zoomK) + this.state.zx;
                    let markerZoomY = this.state.gridY - (this.state.gridY * this.state.zoomK) + this.state.zy;
                    
                    this.coordsToGridSize(markerZoomX, markerZoomY);

                    this.createRectangle(this.state.gridX, this.state.gridY, this.state.rectW, this.state.rectH);
                } else {
                    this.createRectangle(this.state.gridX, this.state.gridY, this.state.rectW, this.state.rectH);
                }
                break
            case 'triangle':
                this.markerCleaner();

                if (this.state.isZoom) {
                    let markerZoomX = this.state.gridX - (this.state.gridX * this.state.zoomK) + this.state.zx;
                    let markerZoomY = this.state.gridY - (this.state.gridY * this.state.zoomK) + this.state.zy;
                    
                    this.coordsToGridSize(markerZoomX, markerZoomY);

                    this.createTriangle(this.state.gridX, this.state.gridY, this.state.triangleDirection);
                } else {
                    this.createTriangle(this.state.gridX, this.state.gridY, this.state.triangleDirection);
                }
                break
            case 'number':
                this.markerCleaner();

                if (this.state.isZoom) {
                    let markerZoomX = this.state.gridX - (this.state.gridX * this.state.zoomK) + this.state.zx;
                    let markerZoomY = this.state.gridY - (this.state.gridY * this.state.zoomK) + this.state.zy;
                    
                    this.coordsToGridSize(markerZoomX, markerZoomY);

                    if (this.state.romNumber !== '') {
                        this.createNumber(this.state.gridX, this.state.gridY, this.state.romNumber, this.state.rectW);
                    }
                } else {
                    if (this.state.romNumber !== '') {
                    this.createNumber(this.state.gridX, this.state.gridY, this.state.romNumber, this.state.rectW);
                    }
                }
                break
            case 'arabic-number':
                this.markerCleaner();

                if (this.state.isZoom) {
                    let markerZoomX = this.state.gridX - (this.state.gridX * this.state.zoomK) + this.state.zx;
                    let markerZoomY = this.state.gridY - (this.state.gridY * this.state.zoomK) + this.state.zy;
                    
                    this.coordsToGridSize(markerZoomX, markerZoomY);

                    if (this.state.romNumber !== '') {
                        this.createArabicNumber(this.state.gridX, this.state.gridY, this.state.romNumber, this.state.rectW);
                    }
                } else {
                    if (this.state.romNumber !== '') {
                    this.createArabicNumber(this.state.gridX, this.state.gridY, this.state.romNumber, this.state.rectW);
                    }
                }
                break
            default:
                this.markerCleaner();
        }
    }

    // When mouse move but not relese

    onMouseMove = (e) => {
        if (this.state.isDrowing) {
            this.setState({
                x2: this.state.x2 = e.offsetX,
                y2: this.state.y2 = e.offsetY,
                cx: this.state.cx = e.offsetX,
                cy: this.state.cy = e.offsetY,
                lastX: this.state.lastX = e.offsetX,
                lastY: this.state.lastY = e.offsetY,
                // r: this.state.r = Math.abs(e.offsetX - 15 - this.state.x),
                currentShape: this.state.currentShape = this.state.draw.last(),
                markerX: this.state.markerX = e.offsetX,
                markerY: this.state.markerY = e.offsetY,
            })

            this.coordsToGridSize(e.offsetX, e.offsetY)

            switch(this.state.currentTool) {
                case 'move':
                    if (!this.state.isShapeMove) {
                        this.setState({
                            isShapeMove: true
                        })
                    }

                    if (this.state.currentMarker) {
                        if (this.state.isZoom) {
                            let markerZoomX = this.state.gridX - (this.state.gridX * this.state.zoomK) + this.state.zx;
                            let markerZoomY = this.state.gridY - (this.state.gridY * this.state.zoomK) + this.state.zy;
                            
                            this.coordsToGridSize(markerZoomX, markerZoomY);
                        }
                        
                        this.resizeSelectedShape(this.state.selectedShape, this.state.currentMarker, this.state.gridX, this.state.gridY, this.objX, this.objY, this.objW, this.objH, this.objR, this.objCX, this.objCY, e);

                        switch (this.state.selectedShape.type) {
                            case 'line':
                                let lineGridL = this.getLineLengt(this.state.selectedShape);
                                const lineDimensionsObj = {
                                    type: 'line',
                                    Length: lineGridL,
                                    width: 0,
                                    height: 0,
                                    radius: 0
                                }

                                this.props.editShapeData(lineDimensionsObj);
                                break;
                            case 'circle':
                                let circleGridR = this.state.moa ? this.state.selectedShape.radius() / this.state.moaAvg : this.state.selectedShape.radius() / this.state.milAvg;
                                const circleDimensionsObj = {
                                    type: 'circle',
                                    Length: 0,
                                    width: 0,
                                    height: 0,
                                    radius: circleGridR
                                }

                                this.props.editShapeData(circleDimensionsObj);
                                break;
                            case 'polygon':
                                let polygonGridS = this.state.moa ? this.state.selectedShape.width() / this.state.moaAvg : this.state.selectedShape.width() / this.state.milAvg;
                                const polygonDimensionsObj = {
                                    type: 'polygon',
                                    Length: 0,
                                    width: polygonGridS,
                                    height: 0,
                                    radius: 0
                                }

                                this.props.editShapeData(polygonDimensionsObj);
                                break;
                            case 'rect':
                                let rectGridWidth = this.state.moa ? this.state.selectedShape.width() / this.state.moaAvg : this.state.selectedShape.width() / this.state.milAvg;
                                let rectGridHeight = this.state.moa ? this.state.selectedShape.height() / this.state.moaAvg : this.state.selectedShape.height() / this.state.milAvg;
                                const rectDimensionsObj = {
                                    type: 'rect',
                                    Length: 0,
                                    width: rectGridWidth,
                                    height: rectGridHeight,
                                    radius: 0
                                }

                                this.props.editShapeData(rectDimensionsObj);
                                break;
                            default:
                                const defaultData = {
                                    type: '',
                                    Length: 0,
                                    width: 0,
                                    height: 0,
                                    radius: 0
                                }
                                
                                this.props.editShapeData(defaultData);
                        }
                        
                    }

                    if (this.state.currentMarker && this.state.selectedShape.type === 'polygon') {
                        // let selectedShapeCoords = this.getPolyCoords(this.state.selectedShape);
                        // let markerCoordsPart = parseInt(this.state.currentMarker.id().replace('m', ''));

                        // if (this.state.isZoom) {
                        //     this.coordsToGridSize(markerZoomX, markerZoomY);

                        //     selectedShapeCoords[markerCoordsPart] = [this.state.gridX, this.state.gridY];
                        //     this.state.currentMarker.center(this.state.gridX, this.state.gridY);
                        //     this.state.selectedShape.plot(selectedShapeCoords);
                        // } else {
                        //     selectedShapeCoords[markerCoordsPart] = [this.state.gridX, this.state.gridY];
                        //     this.state.currentMarker.center(this.state.gridX, this.state.gridY);
                        //     this.state.selectedShape.plot(selectedShapeCoords);
                        // }

                        // if (this.state.isZoom) {
                        //     this.coordsToGridSize(markerZoomX, markerZoomY);

                        //     this.state.currentMarker.center(markerX, markerY);

                        //     if (this.state.currentMarker.id() === 'm0') {
                        //         if ((this.state.gridX - this.state.selectedShape.x()) >= minSize) {
                        //             this.state.selectedShape.width(this.state.gridX - this.state.selectedShape.x());
                        //             this.state.selectedShape.height(this.state.gridX - this.state.selectedShape.x());
                        //         }
                        //     }
                        // } else {
                        //     this.state.currentMarker.center(markerX, markerY);

                        //     if ((this.state.gridX - this.state.selectedShape.x()) >= minSize) {
                        //         this.state.selectedShape.width(this.state.gridX - this.state.selectedShape.x());
                        //         this.state.selectedShape.height(this.state.gridX - this.state.selectedShape.x());
                        //     }
                        // }
                    } else if (this.state.currentMarker && this.state.selectedShape.type === 'line') {
                        // let selectedShapeCoords = this.getLineCoords(this.state.selectedShape);
                        // let markerCoordsPart = parseInt(this.state.currentMarker.id().replace('m', ''));

                        // if (this.state.isZoom) {
                        //     this.coordsToGridSize(markerZoomX, markerZoomY);

                        //     selectedShapeCoords[markerCoordsPart] = this.state.gridX;
                        //     selectedShapeCoords[markerCoordsPart + 1] = this.state.gridY;
                        //     this.state.currentMarker.center(this.state.gridX, this.state.gridY);
                        //     this.state.selectedShape.plot(selectedShapeCoords);
                        // } else {
                        //     selectedShapeCoords[markerCoordsPart] = this.state.gridX;
                        //     selectedShapeCoords[markerCoordsPart + 1] = this.state.gridY;
                        //     this.state.currentMarker.center(this.state.gridX, this.state.gridY);
                        //     this.state.selectedShape.plot(selectedShapeCoords);
                        // }

                        if (this.calcLineGridLength(this.state.selectedShape) !== this.state.gridLength) {
                            this.setState({
                                gridLength: this.state.gridLength = this.calcLineGridLength(this.state.selectedShape)
                            })
                        }

                        this.props.getLineGridLength(this.calcLineGridLength(this.state.selectedShape))
                    } else if (this.state.currentMarker && this.state.selectedShape.type === 'rect') {
                        // if (this.state.isZoom) {
                        //     this.coordsToGridSize(markerZoomX, markerZoomY);

                        //     this.state.currentMarker.center(markerX, markerY);

                        //     if (this.state.currentMarker.id() === 'm2') {
                        //         if (this.state.selectedShape.hasClass('square')) {
                        //             if ((this.state.gridX - this.state.selectedShape.x()) >= minSize) {
                        //                 this.state.selectedShape.width(this.state.gridX - this.state.selectedShape.x());
                        //                 this.state.selectedShape.height(this.state.gridX - this.state.selectedShape.x());
                        //             }
                        //         } else {
                        //             if ((this.state.gridX - this.state.selectedShape.x()) >= minSize &&
                        //                 (this.state.gridY - this.state.selectedShape.y()) >= minSize) {
                        //                 this.state.selectedShape.width(this.state.gridX - this.state.selectedShape.x());
                        //                 this.state.selectedShape.height(this.state.gridY - this.state.selectedShape.y());
                        //             } 
                        //         }
                        //     }

                            // switch(this.state.currentMarker.id()) {
                            //     case 'm0':
                            //         this.state.selectedShape.x(this.state.gridX);
                            //         this.state.selectedShape.y(this.state.gridY);
                            //         break
                            //     case 'm1':
                            //         this.state.selectedShape.y(this.state.gridY);
                            //         this.state.selectedShape.width(this.state.gridX - this.state.selectedShape.x());
                            //         break
                            //     case 'm2':
                            //         if ((this.state.gridX - this.state.selectedShape.x()) >= minSize) {
                            //             this.state.selectedShape.width(this.state.gridX - this.state.selectedShape.x());
                            //             this.state.selectedShape.height(this.state.gridX - this.state.selectedShape.x());
                            //         }
                            //         break
                            //     case 'm3':
                            //         this.state.selectedShape.x(this.state.gridX);
                            //         this.state.selectedShape.height(this.state.gridY - this.state.selectedShape.y());
                            //         break
                            //     default:
                            //         this.state.selectedShape.width(this.state.gridX - this.state.selectedShape.x());
                            //         this.state.selectedShape.height(this.state.gridY - this.state.selectedShape.y());
                            // }

                            // this.rectMarkerReRender(this.state.currentMarker.id());
                        // } else {
                            // this.state.currentMarker.center(markerX, markerY);

                            // if (this.state.currentMarker.id() === 'm2') {
                            //     if (this.state.selectedShape.hasClass('square')) {
                            //         if ((this.state.gridX - this.state.selectedShape.x()) >= minSize) {
                            //             this.state.selectedShape.width(this.state.gridX - this.state.selectedShape.x());
                            //             this.state.selectedShape.height(this.state.gridX - this.state.selectedShape.x());
                            //         }
                            //     } else {
                            //         if ((this.state.gridX - this.state.selectedShape.x()) >= minSize &&
                            //             (this.state.gridY - this.state.selectedShape.y()) >= minSize) {
                            //             this.state.selectedShape.width(this.state.gridX - this.state.selectedShape.x());
                            //             this.state.selectedShape.height(this.state.gridY - this.state.selectedShape.y());
                            //         } 
                            //     }
                            // }

                            // switch(this.state.currentMarker.id()) {
                            //     case 'm0':
                            //         this.state.selectedShape.x(this.state.gridX);
                            //         this.state.selectedShape.y(this.state.gridY);
                            //         break
                            //     case 'm1':
                            //         this.state.selectedShape.y(this.state.gridY);
                            //         this.state.selectedShape.width(this.state.gridX - this.state.selectedShape.x());
                            //         break
                            //     case 'm2':
                            //         if ((this.state.gridX - this.state.selectedShape.x()) >= minSize) {
                            //             this.state.selectedShape.width(this.state.gridX - this.state.selectedShape.x());
                            //             this.state.selectedShape.height(this.state.gridX - this.state.selectedShape.x());
                            //         }
                            //         break
                            //     case 'm3':
                            //         this.state.selectedShape.x(this.state.gridX);
                            //         this.state.selectedShape.height(this.state.gridY - this.state.selectedShape.y());
                            //         break
                            //     default:
                            //         this.state.selectedShape.width(this.state.gridX - this.state.selectedShape.x());
                            //         this.state.selectedShape.height(this.state.gridY - this.state.selectedShape.y());
                            // }

                            // this.rectMarkerReRender(this.state.currentMarker.id());
                        // }
                    } else if (this.state.currentMarker && this.state.selectedShape.type === 'circle') {
                        // this.state.currentMarker.center(markerX, markerY);

                        // if (this.state.isZoom) {
                        //     this.coordsToGridSize(markerZoomX, markerZoomY);

                        //     if ((this.state.gridX - this.state.selectedShape.x()) >= 5) {
                        //         this.state.selectedShape.width(this.state.gridX - this.state.selectedShape.x());
                        //         this.state.selectedShape.height(this.state.gridX - this.state.selectedShape.x());
                        //     }
                        // } else {
                        //     if ((this.state.gridX - this.state.selectedShape.x()) >= 5) {
                        //         this.state.selectedShape.width(this.state.gridX - this.state.selectedShape.x());
                        //         this.state.selectedShape.height(this.state.gridX - this.state.selectedShape.x());
                        //     }
                        // }
                    } else if (this.state.currentMarker && this.state.selectedShape.type === 'g') {
                        // let minX = this.state.selectedShape.x() + 10
                        // let minY = this.state.selectedShape.y() + 10

                        // if (this.state.isZoom) {
                        //     this.coordsToGridSize(markerZoomX, markerZoomY);

                        //     if ((this.state.gridX - this.state.selectedShape.x()) >= minSize) {
                        //         this.state.currentMarker.center(markerX, markerY);
                        //         this.state.selectedShape.width(this.state.gridX - this.state.selectedShape.x());
                        //         this.state.selectedShape.height(this.state.gridX - this.state.selectedShape.x());
                        //     }
                        // } else {
                        //     if ((this.state.gridX - this.state.selectedShape.x()) >= minSize) {
                        //         this.state.currentMarker.center(markerX, markerY);
                        //         this.state.selectedShape.width(this.state.gridX - this.state.selectedShape.x());
                        //         this.state.selectedShape.height(this.state.gridX - this.state.selectedShape.x());
                        //     }
                        // }
                    } else {
                        if (this.state.selectedShape) {
                            let x = this.state.gridX - (this.state.gridX * this.state.zoomK) + this.state.zx;
                            let y = this.state.gridY - (this.state.gridY * this.state.zoomK) + this.state.zy;

                            this.bBoxCleaner();

                            // this.moveShapeSmoothInZoom(this.state.selectedShape, x, y);

                            if (!this.state.isShapeMove) {
                                this.setState({
                                    isShapeMove: true
                                })
                            }

                            this.moveShapeSmooth(this.state.selectedShape, e.offsetX, e.offsetY);
                            
                            if (this.state.selectedShape.hasClass('circle')) {
                                if (this.state.isZoom) {
                                    this.moveShapeSmoothInZoom(this.state.selectedShape, x, y);

                                    this.moveShapeToCanvasBorders(this.state.selectedShape, this.state.gridX, this.state.gridY);
                                } else {
                                    this.moveShapeToCanvasBorders(this.state.selectedShape, this.state.gridX, this.state.gridY);
                                }

                                this.markerCleaner();
                                this.addMarkersToSelected(this.state.selectedShape);
                            } else if (this.state.selectedShape.hasClass('triangle')) {
                                if (this.state.isZoom) {
                                    this.moveShapeSmoothInZoom(this.state.selectedShape, x, y);

                                    switch (this.state.selectedShape.data('direction')) {
                                        case 'top':
                                            this.state.selectedShape.move(this.state.gridX - this.state.selectedShape.width() / 2, this.state.gridY);
                                            break
                                        case 'right':
                                            this.state.selectedShape.move(this.state.gridX - this.state.selectedShape.width(), this.state.gridY - this.state.selectedShape.height() / 2)
                                            break
                                        case 'left':
                                            this.state.selectedShape.move(this.state.gridX, this.state.gridY - this.state.selectedShape.height() / 2)
                                            break
                                        case 'bottom':
                                            this.state.selectedShape.move(this.state.gridX - this.state.selectedShape.width() / 2, this.state.gridY - this.state.selectedShape.height())
                                            break
                                        default:
                                            this.state.selectedShape.move(this.state.gridX, this.state.gridY);
                                    }
                                } else {
                                    this.moveShapeToCanvasBorders(this.state.selectedShape, this.state.gridX, this.state.gridY);
                                }

                                this.markerCleaner();
                                this.addMarkersToSelected(this.state.selectedShape);
                            } else {
                                if (this.state.isZoom) {
                                    this.moveShapeSmoothInZoom(this.state.selectedShape, x, y);

                                    this.moveEachSelectedShape(this.selectedShapesBuffer, this.state.gridX, this.state.gridY, this.state.selectedShape);

                                    this.moveShapeToCanvasBorders(this.state.selectedShape, this.state.gridX, this.state.gridY);

                                    // this.state.selectedShape.move(this.state.gridX, this.state.gridY);
                                } else {
                                    this.moveEachSelectedShape(this.selectedShapesBuffer, this.state.gridX, this.state.gridY, this.state.selectedShape);

                                    this.moveShapeToCanvasBorders(this.state.selectedShape, this.state.gridX, this.state.gridY);
                                }

                                this.markerCleaner();
                                this.addMarkersToSelected(this.state.selectedShape);

                                if (this.state.selectedShape.type === 'g') {
                                    if (this.state.isGroupingActive === false) {
                                        this.setState({
                                            isGroupingActive: this.state.isGroupingActive = true
                                        })
                                    }

                                    if (this.state.selectedShape.hasClass('number-group')) {
                                        // const resizeMarker = this.state.draw.findOne('#m2');
    
                                        if (this.state.isZoom) {
                                            this.moveShapeSmoothInZoom(this.state.selectedShape, x, y);
                                            
                                            // this.state.selectedShape.move(this.state.gridX , this.state.gridY);

                                            this.moveShapeToCanvasBorders(this.state.selectedShape, this.state.gridX, this.state.gridY);
                                        } else {
                                            this.moveShapeToCanvasBorders(this.state.selectedShape, this.state.gridX, this.state.gridY);
                                        }
                                    }
                                }
                            }
                        } else {
                            if (this.state.currentShape.id() === 'selectRectangle') {
                                let selectedArr = [];

                                this.resizeRectangleSelectTool(this.state.currentShape, e.offsetX, e.offsetY);

                                this.state.shapesOnCanvas.forEach(el => {
                                    if ((this.state.currentShape.x() < el.cx() && this.state.currentShape.y() < el.cy()) &&
                                        (this.state.currentShape.x() + this.state.currentShape.width() > el.cx() && this.state.currentShape.y() + this.state.currentShape.height() > el.cy())) {
                                        selectedArr.push(el)
                                    }
                                })

                                this.setState({
                                    selectedShapes: this.state.selectedShapes = selectedArr
                                })

                            }
                        }
                    }

                    this.bBoxCleaner();
                    this.bBoxRender();
                    break
                case 'hand':
                    if (this.state.isSvgActive && this.state.isZoom && this.state.currentTool === 'hand') {
                        if (this.state.zx >= 0 && this.state.zx <= this.state.maxZX) {
                            let buffZx = this.state.zx - e.movementX;

                            this.setState({
                                zx: this.state.zx = buffZx
                            })
                        } //else {
                            // if (this.state.zx < 0) {
                            //     this.setState({
                            //         zx: this.state.zx = 0
                            //     })
                            // } else if (this.state.zx > this.state.maxZX) {
                            //     this.setState({
                            //         zx: this.state.zx = this.state.maxZX
                            //     })
                            // }
                        // }

                        if (this.state.zy >= 0 && this.state.zy <= this.state.maxZY) {
                            let buffZy = this.state.zy - e.movementY;
                            this.setState({
                                zy: this.state.zy = buffZy
                            })
                        } //else {
                            // if (this.state.zy < 0) {
                            //     this.setState({
                            //         zy: this.state.zy = 0
                            //     })
                            // } else if (this.state.zy > this.state.maxZY) {
                            //     this.setState({
                            //         zy: this.state.zy = this.state.maxZY
                            //     })
                            // }
                        // }

                        if (this.state.zx < 0) {
                            this.setState({
                                zx: this.state.zx = 0
                            })
                        }

                        if (this.state.zy < 0) {
                            this.setState({
                                zy: this.state.zy = 0
                            })
                        }

                        if (this.state.zoomWidthIndex + this.state.zx > this.state.canvasWidth) {
                            this.setState({
                                zx: this.state.zx = this.state.canvasWidth - this.state.zoomWidthIndex
                            })
                        }

                        if (this.state.zoomHeightIndex + this.state.zy > this.state.canvasHeight) {
                            this.setState({
                                zy: this.state.zy = this.state.canvasHeight - this.state.zoomHeightIndex
                            })
                        }

                        if (this.state.moveZoomX !== e.offsetX + this.state.zx ||
                            this.state.moveZoomY !== e.offsetY + this.state.zy) {
                                this.setState({
                                    moveZoomX: this.state.moveZoomX = e.offsetX + this.state.zx,
                                    moveZoomY: this.state.moveZoomY = e.offsetY + this.state.zy
                                })
                        }

                        if (this.state.moveZoomX > this.state.canvasWidth && this.state.moveZoomX !== this.state.canvasWidth) {
                            this.setState({
                                moveZoomX: this.state.moveZoomX = this.state.canvasWidth
                            })
                        }

                        if (this.state.moveZoomY > this.state.canvasHeight && this.state.moveZoomY !== this.state.canvasHeight) {
                            this.setState({
                                moveZoomY: this.state.moveZoomY = this.state.canvasHeight
                            })
                        }

                        this.props.updateZoomXY({id: this.props.activeTabId, zx: this.state.zx, zy: this.state.zy})

                        this.state.draw.viewbox(this.state.zx, this.state.zy, this.state.zoomWidthIndex, this.state.zoomHeightIndex);
                    }
                    break
                case 'line':
                    if (this.state.isZoom) {
                        let x = this.state.gridX - (this.state.gridX * this.state.zoomK) + this.state.zx;
                        let y = this.state.gridY - (this.state.gridY * this.state.zoomK) + this.state.zy;

                        this.coordsToGridSize(x, y);

                        this.state.currentShape.attr({x2: this.state.gridX, y2: this.state.gridY});
                    } else {
                        this.state.currentShape.attr({x2: this.state.gridX, y2: this.state.gridY});
                    }
                    break
                default:
                    this.bBoxCleaner();
            }

            // if (e.target.classList[0] === 'marker') {
            //     let marker = this.state.draw.findOne(`#${e.target.attributes.id.nodeValue}`)
            //     marker.cx(e.offsetX)
            //     marker.cy(e.offsetY)
            // }
        }

        if (this.isPathActive) {
            switch(this.state.currentTool) {
                case 'line':
                    this.pathBuffer = [];
                    this.pathCoordsArr.pop();

                    this.coordsToGridSize(e.offsetX, e.offsetY)

                    this.pathBuffer.push(this.state.gridX)
                    this.pathBuffer.push(this.state.gridY)

                    this.pathCoordsArr.push(this.pathBuffer)

                    this.state.currentShape.plot(this.pathCoordsArr)
                    break
                default:
                    this.pathBuffer = []
            }
        }

        this.props.getNewMarkerCoords(this.state.markerX, this.state.markerY, false);
    }

    onMouseUp = (e) => {
        if (this.state.isDrowing) {
            this.setState ({
                cx: this.state.cx = 0,
                cy: this.state.cy = 0,
                // r: this.state.r = 30,
                isDrowing: this.state.isDrowing = false,
                // svgData: this.state.svgData = this.state.draw.svg(),
                currentShape: this.state.currentShape = this.state.draw.last()
            })
        }

        if (e.button === 2) {
            if (this.state.isZoom) {
                this.state.draw.removeClass('grab-canvas');
            }

            this.props.setActiveShapeButton(this.prevActiveShapeBtn);

            return;
        }

        if (this.state.isZoom) {
            this.state.draw.removeClass('grab-canvas');
        }

        if (this.state.currentShape && this.state.currentShape.id() === 'selectRectangle') {
            this.mouseSelectRenderCleaner();

            if (this.state.selectedShapes.length === 1) {
                this.setState({
                    selectedShape: this.state.selectedShape = this.state.selectedShapes[0],
                })

                this.markerCleaner();
                this.addMarkersToSelected(this.state.selectedShape);
                this.props.getSelectedShape(this.state.selectedShape);
            }
            
            if (this.state.selectedShapes.length > 1) {
                this.props.getSelectedShapes(this.state.selectedShapes);

                this.markerCleaner();
                this.props.getSelectedShape('');
            }
        }

        if (this.state.currentTool === 'line') {
            if (this.state.currentShape.type === 'line') {
                const lineX1 = this.state.currentShape.attr('x1');
                const lineY1 = this.state.currentShape.attr('y1');
                
                if (this.state.gridX === lineX1 && this.state.gridY === lineY1) {
                    this.state.currentShape.remove();
                } else {
                    // this.sortLayesrs();
                    this.updateCanvasElementsName();
                }
                
                this.setState({
                    axisLine: this.state.axisLine = false
                })

                this.props.disableAxisSwitch();
            }
        }

        if (this.state.currentTool === 'move' && this.state.selectedShape && this.state.isShapeMove) {
            this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());

            this.setState({
                isShapeMove: false
            })
        }

        if (this.state.fpValue === '31') {
            this.setState({
                fpValue: this.state.fpValue = '42'
            })
        }

        this.props.fpSwitchOff();

        if (this.state.currentMarker) {
            this.updateCanvasElementsName();
        }

        if (this.state.currentTool === 'line' ||
            this.state.currentTool === 'circle' ||
            this.state.currentTool === 'square' ||
            this.state.currentTool === 'rectangle' ||
            this.state.currentTool === 'triangle' ||
            this.state.currentTool === 'number' ||
            this.state.currentTool === 'arabic-number') {
            this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());
        }

        this.addUndoStep();

        this.props.getCanvasShapesData(this.canvasShapesBuffer);
        this.props.updateZoomXY({id: this.props.activeTabId, zx: this.state.zx, zy: this.state.zy});

        if (this.state.isSvgActive) {
            this.props.getCurrentID(this.state.shapeNames);
            this.removeEmptyGroups();
        }

        if (this.state.selectedShapes) {
            this.selectedShapesTypeChecker(this.state.selectedShapes);
        }

        this.deleteSelectedShapesRect('mouseSelect');

        this.selectedRectangleRender(this.state.selectedShapes);

        if (this.state.selectedShape && this.state.selectedShape.type === 'line') {
            this.roundLineCoords(this.state.selectedShape);
        }

        if (this.state.selectedShape && this.state.selectedShape.type === 'polygon') {
            this.roundPolygonCoords(this.state.selectedShape);
        }
    }

    onContextMenu = (e) => {
        e.preventDefault();
    }

    componentDidUpdate(prevProps, prevState) {
        if (`svgCanvas-${this.props.activeTabId}` === this.state.draw.id()) {
            if (!this.state.isSvgActive) {
                this.setState({
                    isSvgActive: this.state.isSvgActive = true
                })
            }
        } else {
            if (this.state.isSvgActive) {
                this.setState({
                    isSvgActive: this.state.isSvgActive = false
                })
            }
        }

        if (this.state.currentTool) {
            if (this.state.currentTool !== 'move' && this.state.selectedShape !== '') {
                // this.setState({
                    // selectedShape: this.state.selectedShape = '',
                    // selectedShapes: this.state.selectedShapes = [],
                    // isSelected: this.state.isSelected = false,
                    // isGroupingActive: this.state.isGroupingActive = false,
                // })

                this.selectedShapes.clear();
                this.groupedSet.clear();
                this.state.draw.find('.selected').forEach(el => el.removeClass('selected'));
                this.bBoxCleaner();
                // this.clearSelectedShapesRect('selectedShapesRectangle');
                this.clearSelectedShapesRect('mouseSelect');
                this.markerCleaner();
            }
        }

        if (prevProps.fillColor !== this.state.fillColor) {
            if (this.state.isSvgActive) {
                this.props.getCurrentID(this.state.shapeNames);
            }

            this.setState({
                fillColor: this.state.fillColor = prevProps.fillColor,
            })
        }

        if (prevProps.opacity !== prevState.opacity) {
            if (this.state.isSvgActive) {
                this.props.getCurrentID(this.state.shapeNames);
            }

            this.setState({
                opacity: this.state.opacity = prevProps.opacity,
            })
        }

        if (this.props.align.isFillColorSet) {
            if (this.state.isSvgActive) {
                this.setState({
                    shapeFillColor: this.state.shapeFillColor = this.props.align.shapeFillColor
                })

                this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());
                this.props.switchOffisFillColorSet();
            }
        }

        if (this.props.align.isStrokeColorSet) {
            if (this.state.isSvgActive) {
                this.setState({
                    shapeStrokeColor: this.state.shapeStrokeColor = this.props.align.shapeStrokeColor
                })

                this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());
                this.props.switchOffisStrokeColorSet();
            }
        }

        if (prevProps.strokeWidth !== prevState.strokeWidth || prevProps.strokeColor !== prevState.strokeColor) {
            if (this.state.isSvgActive && this.state.strokeColor !== prevProps.strokeColor) {
                this.props.getCurrentID(this.state.shapeNames);
            }

            this.setState({
                strokeWidth: this.state.strokeWidth = prevProps.strokeWidth,
                strokeColor: this.state.strokeColor = prevProps.strokeColor,
            })

            if(this.state.selectedShape) {
                this.markerCleaner();
                this.addMarkersToSelected(this.state.selectedShape);
                this.bBoxCleaner();
                this.bBoxRender();
            }
        }

        if (prevProps.align.align !== prevState.align) {
            this.setState({
                strokeWidth: this.state.strokeWidth = parseInt(prevProps.align.strokeWidth),
                // strokeColor: this.state.strokeColor = prevProps.align.strokeColor,
                // svgData: this.state.svgData = this.state.draw.svg(),
                align: this.state.align = prevProps.align.align,
                gridSize: this.state.gridSize = prevProps.instruments.gridSize,
            })

            // this.markerCleaner();
            this.strokeColorGroupPainter(this.state.selectedShape, prevProps.align.strokeColor);
        }

        if (prevProps.align.focalPlane !== prevState.focalPlane) {
            this.setState({
                focalPlane: this.state.focalPlane = prevProps.align.focalPlane
            })

            if (this.state.focalPlane === 'off' && this.state.fpValue !== '42') {
                this.setState({
                    fpValue: this.state.fpValue = '42'
                })
            }

            if (this.state.focalPlane === 'on' && this.state.fpValue !== '31') {
                this.setState({
                    fpValue: this.state.fpValue = '31'
                })
            }

            if (this.state.selectedShape) {
                const title31 = '<title>31</title>';
                const title42 = '<title>42</title>';

                if (this.state.selectedShape.type === 'g') {
                    // this.state.selectedShape.each(function(i, children) {
                    //     children[i].each(function(j, gChildren) {
                    //         if (gChildren[j].type === 'title') {
                    //             gChildren[j].remove();
                    //         }
                    //     })
                    // })
                } else {
                    this.state.selectedShape.each(function(i, children) {
                        if (children[i].type === 'title') {
                            children[i].remove();
                        }
                    })
                }
                
                // this.state.focalPlane === 'off' ? this.state.selectedShape.add('<title>42</title>') : this.state.selectedShape.add('<title>31</title>');
                if (this.state.selectedShape.type === 'g') {
                    // if (this.state.focalPlane === 'off') {
                    //     this.state.selectedShape.each(function(i, children) {
                    //         children[i].add(title42)
                    //     })
                    // } else {
                    //     this.state.selectedShape.each(function(i, children) {
                    //         children[i].add(title31)
                    //     })
                    // }
                } else {
                    this.state.focalPlane === 'off' ? this.state.selectedShape.add(title42) : this.state.selectedShape.add(title31);
                }
            }
        }

        if (this.props.align.fpSwitched && this.state.isSvgActive) {
            this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());
            this.props.disablefpSwitcher();
        }

        try {
            this.gData = this.props.getGrid.payload.grid.gridData.filter(el => el.id === this.props.activeTabId);
        } catch(e) {
            console.log(e);
        }

        if (this.gData.length > 0 && this.state.gridSize !== this.gData[0].gridSize) {
            this.setState({
                moa: this.state.moa = this.gData[0].gridType === 'moa' ? true : false,
                mil: this.state.mil = this.gData[0].gridType === 'mil' ? true : false,
                gridSize: this.state.gridSize = this.gData[0].gridSize ? this.gData[0].gridSize : 3
            })

            this.props.gridDisabled();
            this.gridConverter(this.state.canvasWidth, this.state.canvasHeight, this.state.gridSize);
            this.gridCleaner();
            this.gridRender(this.state.gX, this.state.gY);
        }

        if (this.gData.length > 0 && this.state.moa && this.gData[0].gridType === 'mil') {
            this.setState({
                moa: this.state.moa = false,
                mil: this.state.mil = true,
                // gridSize: this.state.gridSize = this.gData[0].gridSize ? this.gData[0].gridSize : 3
            })

            this.props.gridDisabled();
            this.gridConverter(this.state.canvasWidth, this.state.canvasHeight, this.state.gridSize);
            this.gridCleaner();
            this.gridRender(this.state.gX, this.state.gY);
        } else if (this.gData.length > 0 && this.state.mil && this.gData[0].gridType === 'moa') {
            this.setState({
                moa: this.state.moa = true,
                mil: this.state.mil = false,
                // gridSize: this.state.gridSize = this.gData[0].gridSize ? this.gData[0].gridSize : 3
            })

            this.props.gridDisabled();
            this.gridConverter(this.state.canvasWidth, this.state.canvasHeight, this.state.gridSize);
            this.gridCleaner();
            this.gridRender(this.state.gX, this.state.gY);
        }

        if (!this.state.draw.last().hasClass('aimCross') && !this.state.draw.last().hasClass('marker') && !this.state.draw.last().type === 'defs') {
            this.canvasShapesBuffer.add(this.state.draw.last().node.attributes.id.nodeValue)
        }

        if (this.state.currentShape && !this.state.currentShape.hasClass('aimCross') && !this.state.draw.last().hasClass('marker')) {
            this.canvasShapesBuffer.add(this.state.currentShape.id())
        }

        if (prevProps.align.pxLength !== prevState.pxLength) {
            this.setState({
                pxLength: this.state.pxLength = +prevProps.align.pxLength
            })

            if (this.state.selectedShape) {
                let [x1, y1] = this.state.selectedShape.node.attributes[0].nodeValue.replace('M', '').replace(/L/gi, ' ').replace('Z', '').split(' ')
                let a = +x1
                let b = +y1

                let endX = Math.round(a + Math.cos(this.state.degree * Math.PI / 180) * this.state.pxLength);
                let endY = Math.round(b + Math.sin(this.state.degree * Math.PI / 180) * this.state.pxLength);

                let coords = 'M' + x1 + ' ' + y1 + ' L' + endX + ' ' + endY
                this.state.selectedShape.plot(coords)

                this.markerCleaner()
                this.addMarkersToSelected(this.state.selectedShape)
            }
        }

        if (this.state.isSvgActive && prevProps.align.selectedLayerID !== prevState.selectedLayerID) {
            this.setState({
                selectedLayerID: this.state.selectedLayerID = prevProps.align.selectedLayerID
            })
            
            let selectLayer = this.state.draw.findOne(`#${prevProps.align.selectedLayerID}`);
            let selectedArr = [selectLayer];

            this.setState({
                selectedShape: this.state.selectedShape = selectLayer,
                selectedShapes: this.state.selectedShapes = selectedArr
            })

            if (this.state.isShiftPressed) {
                this.selectedShapes.add(this.state.selectedShape);
            } else {
                this.selectedShapes.clear();
                this.selectedShapes.add(this.state.selectedShape);
            }

            this.state.draw.find('.selected').forEach(el => el.removeClass('selected'));

            if (selectLayer) {
                selectLayer.addClass('selected');
            }

            this.markerCleaner();
            this.bBoxCleaner();
            this.addMarkersToSelected(selectLayer);
            this.bBoxRender();

            this.props.getSelectedShape(this.state.selectedShape);
            this.props.getSelectedShapes(this.state.selectedShapes);
        }

        if (this.props.align.duplicate) {
            if (this.state.selectedShapes.length > 1) {
                this.state.selectedShapes.forEach(el => {
                    this.cloneSelectedShape(el);
    
                    this.addCloneShape2Canvas(this.state.clone);
                })
            } else {
                this.cloneSelectedShape(this.state.selectedShape);
    
                this.addCloneShape2Canvas(this.state.clone);
            }

            this.props.duplicateDeactivate();

            this.updateCanvasElementsName();

            if (this.state.isSvgActive) {
                this.props.getCurrentID(this.state.shapeNames);
                this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());
            }
        }

        if (this.state.isSvgActive && this.props.align.isLayerMove) {
            this.updateCanvasElementsName();
            this.props.getCurrentID(this.state.shapeNames);
            this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());
            this.props.layerMoveSwitcher(false);
        }

        if (prevProps.align.undo && this.state.isSvgActive) {
            this.undoLastStep();
            this.props.undoRedoDeactivate();

            this.updateCanvasElementsName();

            if (this.state.isSvgActive) {
                this.props.getCurrentID(this.state.shapeNames);
                // this.sortLayesrs();
            }

            this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());
        } else if (prevProps.align.redo && this.state.isSvgActive) {
            this.redoLastStep();
            this.props.undoRedoDeactivate();

            this.updateCanvasElementsName();

            if (this.state.isSvgActive) {
                this.props.getCurrentID(this.state.shapeNames);
                // this.sortLayesrs();
            }

            this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());
        }

        if (this.props.isShapeDelete === true) {
            this.handlerDeleteShape();
        }


        if (this.props.align.clearCanvas && this.state.isSvgActive) {
            this.state.draw.each(function(i, children) {
                if (
                    children[i].hasClass('line') ||
                    children[i].hasClass('circle') ||
                    children[i].hasClass('square') ||
                    children[i].hasClass('triangle') ||
                    children[i].hasClass('rectangle') ||
                    children[i].hasClass('number-group') ||
                    children[i].hasClass('marker') ||
                    children[i].hasClass('group')
                ) {
                    children[i].remove();
                }
            })

            if (this.state.i !== 0) {
                this.setState({
                    i: this.state.i = 0,
                    selectedShapes: this.state.selectedShapes = ''
                })
            }

            this.setState({
                selectedShape: this.state.selectedShape = '',
                selectedShapes: this.state.selectedShapes = []
            })

            this.bBoxCleaner();
            this.props.clearDeactivate();
            
            this.updateCanvasElementsName();

            if (this.state.isSvgActive) {
                this.props.getCurrentID(this.state.shapeNames);
            }

            this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());
            this.deleteSelectedShapesRect('mouseSelect');
        }

        if (this.props.reduxZoomData.payload.zoom.currentZoomIndex !== this.state.zoomIndex) {
            this.setState({
                zoomIndex: this.state.zoomIndex = this.props.reduxZoomData.payload.zoom.currentZoomIndex
            })

            let index = this.state.zoomIndex / 100;

            if (index === 1) {
                this.setState({
                    zoomWidthIndex: this.state.zoomWidthIndex = this.state.canvasWidth,
                    zoomHeightIndex: this.state.zoomHeightIndex = this.state.canvasHeight
                })
            } else if (index > 1) {
                let w = this.state.canvasWidth - (this.state.canvasWidth * index - this.state.canvasWidth)
                let h = this.state.canvasHeight - (this.state.canvasHeight * index - this.state.canvasHeight)
                
                this.setState({
                    zoomWidthIndex: this.state.zoomWidthIndex = w > 0 ? w : 50,
                    zoomHeightIndex: this.state.zoomHeightIndex = h > 0 ? h : 25
                })
            } else {
                let w = (this.state.canvasWidth - this.state.canvasWidth * index) + this.state.canvasWidth
                let h = (this.state.canvasHeight - this.state.canvasHeight * index) + this.state.canvasHeight

                this.setState({
                    zoomWidthIndex: this.state.zoomWidthIndex = w,
                    zoomHeightIndex: this.state.zoomHeightIndex = h
                })
            }

            this.zoomCanvas();
        }

        if (this.state.zoomIndex === 100) {
            this.state.draw.viewbox(0, 0, this.state.canvasWidth, this.state.canvasHeight);

            if (this.state.isZoom){
                this.setState({
                    isZoom: this.state.isZoom = false
                })
            }

            this.state.draw.removeClass('inZoom')
        } else if (this.state.zoomIndex > 100 || this.state.zoomIndex < 100) {
            if (!this.state.isZoom){
                this.setState({
                    isZoom: this.state.isZoom = true
                })
            }

            if (this.state.currentTool === 'hand') {
                this.state.draw.addClass('inZoom')
            } else {
                this.state.draw.removeClass('inZoom')
            }

            let k = this.state.zoomIndex - 100;
            let mZX = k * 9.4;
            let mZY = k * 6.6;

            if (this.state.maxZX !== mZX && this.state.maxZY !== mZY) {
                this.setState({
                    maxZX: this.state.maxZX = mZX,
                    maxZY: this.state.maxZY = mZY
                })
            }

            //    set zoomK

            let zK = +(this.state.zoomIndex / 100 - 1).toFixed(2);

            if (this.state.zoomK !== zK) {
                this.setState({
                    zoomK: this.state.zoomK = zK
                })

                if (this.state.isSvgActive && zK === 0.01) {
                    this.props.setUpdateData({id: this.props.activeTabId, svg: this.svgGridRemove(), shapeList: this.setCurrentShapesList()});
                }
            }

        }

        if (this.props.reduxZoomData.payload.zoom.currentZoomPosition.x !== this.state.zx) {
            this.setState({
                zx: this.state.zx = this.props.reduxZoomData.payload.zoom.currentZoomPosition.x
            })

            this.state.draw.viewbox(this.state.zx, this.state.zy, this.state.zoomWidthIndex, this.state.zoomHeightIndex);
        }

        if (this.props.reduxZoomData.payload.zoom.currentZoomPosition.y !== this.state.zy) {
            this.setState({
                zy: this.state.zy = this.props.reduxZoomData.payload.zoom.currentZoomPosition.y
            })

            this.state.draw.viewbox(this.state.zx, this.state.zy, this.state.zoomWidthIndex, this.state.zoomHeightIndex);
        }

        if (this.props.activeShapeButton.name !== 'move' &&
            (this.props.activeShapeButton.name !== this.state.currentTool ||
            this.props.activeShapeButton.direction !== this.state.triangleDirection)
            ) {
            this.setState({
                currentTool: this.state.currentTool = this.props.activeShapeButton.name,
                triangleDirection: this.state.triangleDirection = this.props.activeShapeButton.direction,
                romNumber: this.state.romNumber = this.props.activeShapeButton.direction,
            })

            if (this.state.selectedShape) {
                this.setState({
                    selectedShape: this.state.selectedShape = '',
                    selectedShapes: this.state.selectedShapes = []
                })

                this.markerCleaner();
                this.bBoxCleaner();
                this.deleteSelectedShapesRect('mouseSelect');

                this.props.getSelectedShape(this.state.selectedShape);
                this.props.getSelectedShapes(this.state.selectedShapes);
            }
        }

        if (this.props.activeShapeButton.name === 'move') {
            let moveType = this.props.activeShapeButton.direction === 'hand' ? 'hand' : 'move';

            if (this.state.currentTool !== moveType) {
                this.setState({
                    currentTool: this.state.currentTool = moveType,
                })

                if (this.state.selectedShape) {
                    this.setState({
                        selectedShape: this.state.selectedShape = '',
                        selectedShapes: this.state.selectedShapes = []
                    })
    
                    this.markerCleaner();
                    this.bBoxCleaner();
                    this.deleteSelectedShapesRect('mouseSelect');
    
                    this.props.getSelectedShape(this.state.selectedShape);
                    this.props.getSelectedShapes(this.state.selectedShapes);
                }
            }
        }

        if (this.props.align.shapeW && this.props.align.shapeW > 0 && this.gridToPXConverter(this.props.align.shapeW) !== this.state.rectW) {
            this.setState({
                rectW: this.state.rectW = this.gridToPXConverter(this.props.align.shapeW),
            })
        }

        if (this.props.align.shapeH && this.props.align.shapeH > 0 && this.gridToPXConverter(this.props.align.shapeH) !== this.state.rectH) {
            this.setState({
                rectH: this.state.rectH = this.gridToPXConverter(this.props.align.shapeH)
            })
        }

        if (this.props.align.editedW !== this.state.editedW) {
            this.setState({
                editedW: this.state.editedW = this.props.align.editedW
            })
            
            if (this.state.selectedShape) {
                if ((this.state.selectedShape.type === 'rect' ||
                    this.state.selectedShape.type === 'polygon') && this.state.sizeEditedShape === this.state.selectedShape.id()) {
                        if (this.state.selectedShape.hasClass('rectangle')) {
                            this.state.selectedShape.width(this.gridToPXConverter(this.state.editedW))
                        } else {
                            this.state.selectedShape.width(this.gridToPXConverter(this.state.editedW))
                            this.state.selectedShape.height(this.gridToPXConverter(this.state.editedW))
                        }

                    // this.sortLayesrs();
                    this.markerCleaner();
                    this.addMarkersToSelected(this.state.selectedShape);
                    this.bBoxCleaner();
                    this.bBoxRender();
                } else if (this.state.selectedShape.type === 'g') {
                    this.editNumber(this.state.selectedShape, this.gridToPXConverter(this.props.align.editedW));

                    this.markerCleaner();
                    this.addMarkersToSelected(this.state.selectedShape);
                    this.bBoxCleaner();
                    this.bBoxRender();
                } 
            }
        }

        if (this.props.align.editedH !== this.state.editedH) {
            this.setState({
                editedH: this.state.editedH = this.props.align.editedH
            })

            if (this.state.selectedShape && this.state.selectedShape.type === 'rect' && this.state.selectedShape.hasClass('rectangle')) {
                this.state.selectedShape.height(this.gridToPXConverter(this.state.editedH));

                // this.sortLayesrs();
            }

            this.markerCleaner();
            this.addMarkersToSelected(this.state.selectedShape);
            this.bBoxCleaner();
            this.bBoxRender();
        }

        if (this.props.align.isEdit && this.state.selectedShape) {
            if (!this.state.sizeEditedShape) {
                this.setState({
                    sizeEditedShape: this.state.sizeEditedShape = this.state.selectedShape.id()
                })
            }
        } else {
            if (this.state.sizeEditedShape) {
                this.setState({
                    sizeEditedShape: this.state.sizeEditedShape = ''
                })
            }
        }

        if (this.props.align.isEditParam) {
            if (this.state.selectedShape) {
                switch(this.props.align.lastEditParam) {
                    // case 'radius':
                    //     if (this.state.selectedShape.type === 'circle' && this.state.sizeEditedShape === this.state.selectedShape.id()) {
                    //         this.state.selectedShape.radius(this.gridToPXConverter(this.state.editedR));
                    //     }
                    //     break;
                    case 'width':
                        // if ((this.state.selectedShape.type === 'rect' ||
                        //     this.state.selectedShape.type === 'polygon') && this.state.sizeEditedShape === this.state.selectedShape.id()) {
                        //         if (this.state.selectedShape.hasClass('rectangle')) {
                        //             this.state.selectedShape.width(this.gridToPXConverter(this.state.editedW))
                        //         } else {
                        //             this.state.selectedShape.width(this.gridToPXConverter(this.state.editedW))
                        //             this.state.selectedShape.height(this.gridToPXConverter(this.state.editedW))
                        //         }
                        // }

                        if ((this.state.selectedShape.type === 'rect' ||
                            this.state.selectedShape.type === 'polygon')) {
                                if (this.state.selectedShape.hasClass('rectangle')) {
                                    this.state.selectedShape.width(this.gridToPXConverter(this.state.editedW))
                                } else {
                                    this.state.selectedShape.width(this.gridToPXConverter(this.state.editedW))
                                    this.state.selectedShape.height(this.gridToPXConverter(this.state.editedW))
                                }
                        }

                        if (this.state.selectedShape.type === 'g') {
                            // this.editNumber(this.state.selectedShape, this.state.editedW);
                        }

                        // this.state.selectedShape.width(this.gridToPXConverter(this.state.editedW));
                        break;
                    case 'height':
                        this.state.selectedShape.height(this.gridToPXConverter(this.state.editedH));
                        break;
                    default:

                }

                // this.sortLayesrs();
                this.markerCleaner();
                this.addMarkersToSelected(this.state.selectedShape);
                this.bBoxCleaner();
                this.bBoxRender();
            }

            this.props.disableEditParam();
        }

        if (this.state.isSvgActive && this.props.align.renameData.id !== this.state.renameData.id) {
            this.setState({
                renameData: this.state.renameData = this.props.align.renameData
            })

            let shape = this.state.draw.findOne(`#${this.state.renameData.id}`);
            if (shape) {
                shape.data('layername', this.state.renameData.name);
            }
        }

        if (this.props.align.radius && this.props.align.radius > 0 && this.props.align.radius !== this.state.r) {
            this.setState({
                r: this.state.r = this.props.align.radius
            })
        }

        if (this.props.align.editedR !== this.state.editedR) {
            this.setState({
                editedR: this.state.editedR = this.props.align.editedR
            })

            // if (this.state.selectedShape.type === 'circle' && this.state.sizeEditedShape === this.state.selectedShape.id()) {
            //     this.state.selectedShape.radius(this.gridToPXConverter(this.props.align.editedR))
                
            //     // this.sortLayesrs();
            //     this.bBoxCleaner();
            //     this.bBoxRender();
            //     this.markerCleaner();
            //     this.addMarkersToSelected(this.state.selectedShape);
            // }

            if (this.state.selectedShape.type === 'circle') {
                this.state.selectedShape.radius(this.gridToPXConverter(this.props.align.editedR))
                
                // this.sortLayesrs();
                this.bBoxCleaner();
                this.bBoxRender();
                this.markerCleaner();
                this.addMarkersToSelected(this.state.selectedShape);
            }
        }

        if (this.props.align.isEdit !== this.state.isEdit) {
            this.setState({
                isEdit: this.state.isEdit = this.props.align.isEdit
            })
        }

        if (this.props.align.editedL !== this.state.editedL) {
            this.setState({
                editedL: this.state.editedL = this.props.align.editedL
            })

            if (this.state.selectedShape.type === 'line') {
                this.state.selectedShape.width(this.gridToPXConverter(this.props.align.editedL))
                this.state.selectedShape.height(this.gridToPXConverter(this.props.align.editedL))
                
                this.bBoxCleaner();
                this.bBoxRender();
            }
        }

        if (this.props.align.gridLength !== this.state.gridLength && !this.state.isMarkerEdit) {
            this.setState({
                gridLength: this.state.gridLength = this.props.align.gridLength
            })

            if (this.state.selectedShape && this.state.selectedShape.type === 'line') {
                let x1 = +this.state.selectedShape.attr('x1');
                let y1 = +this.state.selectedShape.attr('y1');
                let x2 = +this.state.selectedShape.attr('x2');
                let y2 = +this.state.selectedShape.attr('y2');
                let dx = x2 - x1;
                let dy = y2 - y1;
                let oldLineLength = Math.sqrt((dx * dx) + (dy * dy));
                let newLineLength = this.gridToPXConverter(this.state.gridLength);
                let dirX = dx / oldLineLength;
                let dirY = dy / oldLineLength;

                dirX *= newLineLength;
                dirY *= newLineLength;

                this.state.selectedShape.attr('x2', dirX + x1);
                this.state.selectedShape.attr('y2', dirY + y1);

                this.markerCleaner();
                this.addMarkersToSelected(this.state.selectedShape);
                this.bBoxCleaner();
                this.bBoxRender();
            }
        }

        if (this.props.activeTab && this.state.currentTab !== this.props.activeTab.id) {
            this.setState({
                currentTab: this.state.currentTab = this.props.activeTab.id
            })

            if (this.state.isSvgActive && this.state.draw.id() === `svgCanvas-${this.props.activeTab.id}`) {
                this.updateCanvasElementsName();
                this.props.getCurrentID(this.state.shapeNames);
            }
        }
        
        if (!this.state.isShared) {
            this.sharedSVG = this.props.reduxTabsData.payload.tabs.tabs[0];

            if (this.sharedSVG.isShared) {
                // let sharedSVG = this.props.reduxTabsData.payload.tabs.tabs[0].svg
                let newSVG = SVG().size(`${this.state.canvasWidth}`, `${this.state.canvasHeight}`).attr({id: `bufferSvgCanvas`})
                newSVG.svg(this.props.reduxTabsData.payload.tabs.tabs[0].svg, true)
                this.state.draw.svg(this.props.reduxTabsData.payload.tabs.tabs[0].svg);
                this.state.draw.each(function(i, children) {
                    if (children[i].hasClass('canvas-block')) {
                        children[i].each(function(i, children) {
                            if (
                                children[i].hasClass('line') ||
                                children[i].hasClass('circle') ||
                                children[i].hasClass('square') ||
                                children[i].hasClass('triangle') ||
                                children[i].hasClass('rectangle') ||
                                children[i].hasClass('number-group') ||
                                children[i].hasClass('marker') ||
                                children[i].hasClass('group')
                            ) {
                                children[i].toRoot()
                            }
                        })
                        this.remove()
                    }
                })

                this.props.addShareStatus({id: 'tab-0', status: false});
                this.updateCanvasElementsName();
                this.props.getCurrentID(this.state.shapeNames);

                this.setState({
                    isShared : this.state.isShared = true
                })
            }
        }

        if (this.props.align.axisSwitch && this.state.isSvgActive) {
            if (!this.state.axisLine) {
                this.setState({
                    axisLine: this.state.axisLine = true
                })

                this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());
            }

            // this.props.disableAxisSwitch();
        }

        if (this.props.firstSave && this.state.isSvgActive && this.props.activeTab.savingId) {
                this.state.draw.data('reticleId', null);
                this.state.draw.data('reticleid', null);
                this.state.draw.data('reticleId', this.props.activeTab.savingId);

                this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());
                this.props.disableFirstSave();
        }

        if (this.props.align.btnRightRotate) {
            if (this.state.selectedShape.type === 'line') {
                this.rotateLine(this.state.selectedShape, this.state.selectedShape.cx(), this.state.selectedShape.cy(), 90);
            }

            if (this.state.selectedShape.type === 'polygon') {
                // this.rotateTriangle(this.state.selectedShape, this.state.selectedShape.cx(), this.state.selectedShape.cy(), 90);
                this.rotateTriangle(this.state.selectedShape, this.state.selectedShape.cx(), this.state.selectedShape.cy(), 'rotateRight');
            }

            this.props.rotateSwitcher();

            this.rectMarkerReRender(this.state.selectedShape);
            this.bBoxCleaner();
            this.bBoxRender();
        }

        if (this.props.align.btnLeftRotate) {
            if (this.state.selectedShape.type === 'line') {
                this.rotateLine(this.state.selectedShape, this.state.selectedShape.cx(), this.state.selectedShape.cy(), -90);
            }

            if (this.state.selectedShape.type === 'polygon') {
                // this.rotateTriangle(this.state.selectedShape, this.state.selectedShape.cx(), this.state.selectedShape.cy(), -90);
                this.rotateTriangle(this.state.selectedShape, this.state.selectedShape.cx(), this.state.selectedShape.cy(), 'rotateLeft');
            }

            this.props.rotateSwitcher();

            this.rectMarkerReRender(this.state.selectedShape);
            this.bBoxCleaner();
            this.bBoxRender();
        }

        if (!this.state.isSvgActive) {
            if (this.state.selectedShape) {
                this.setState({
                    selectedShape: ''
                })

                this.markerCleaner();
                this.bBoxCleaner();

                this.props.getSelectedShape('');
            }

            if (this.state.selectedShapes.length) {
                this.setState({
                    selectedShapes: []
                })

                this.markerCleaner();
                this.bBoxCleaner();
                this.mouseSelectRenderCleaner();

                this.props.getSelectedShapes([]);
            }
        }

        if (this.state.isSvgActive) {
            let colorTheme = this.props.getColorThemeData.payload.colorTheme.colotThemeData;
            let currentData = colorTheme.find(el => el.id === this.props.activeTabId);

            if (currentData) {
                if (!currentData.isNightMode && this.state.isNightMode) {
                    this.setState({
                        isNightMode: false
                    })
        
                    this.gridCleaner();
                    this.gridRender(this.state.gX, this.state.gY, false);
                }
        
                if (currentData.isNightMode && !this.state.isNightMode) {
                    this.setState({
                        isNightMode: true
                    })
        
                    this.gridCleaner();
                    this.gridRender(this.state.gX, this.state.gY, true);
                }
            }
        }

        if (this.props.isStrokeTypeChanged) {
            this.props.resetStrokeTypeChanged();

            if (this.state.strokeDasharray !== this.props.strokeDasharray) {
                this.setState({
                    strokeDasharray: this.props.strokeDasharray === 'null' ? null : this.props.strokeDasharray
                })
            }

            if (this.state.selectedShapes && this.state.selectedShapes.length > 1) {
                this.state.selectedShapes.forEach(el => {
                    if (el.type !== 'g') {
                        el.attr({'stroke-dasharray': this.props.strokeDasharray === 'null' ? null : this.props.strokeDasharray})
                    }
                })
            } else if (this.state.selectedShape) {
                this.state.selectedShape.attr({'stroke-dasharray': this.props.strokeDasharray === 'null' ? null : this.props.strokeDasharray});
            }

            this.addUndoStep();
            this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());
        }

        if (this.props.importReticle) {
            if (this.state.isSvgActive) {
                this.renderImportReticle(this.props.importReticle);
                this.props.clearImportData();
                this.updateCanvasElementsName();
                this.props.getCurrentID(this.state.shapeNames);
                this.props.svgUpdateData(this.svgGridRemove(), this.setCurrentShapesList());
            }
        }
    }

    componentDidMount() {
        if (this.state.docID === '') {
            this.setState({
                docID: this.state.docID = this.props.svgID
            })
        }

        if (!localStorage.getItem('appVersion') || localStorage.getItem('appVersion') !== this.props.appVersion) {
            sessionStorage.clear();
            localStorage.clear();

            localStorage.setItem('appVersion', this.props.appVersion);
        }

        if (sessionStorage.getItem(this.props.svgID)) {
            if (!this.props.importReticle) {
                let svgImage = JSON.parse(sessionStorage.getItem(this.props.svgID));

                this.state.draw = SVG(svgImage.svg).size(`${this.state.canvasWidth}`, `${this.state.canvasHeight}`).attr({id: `svgCanvas-${this.props.svgID}`});
            }
        } else {
            let rTabs = this.props.reduxTabsData.payload.tabs.tabs.filter(el => el.id === this.state.docID);

            if (rTabs[0] && rTabs[0].svg && rTabs[0].id === this.state.docID) {
                if (rTabs[0].isActiveTab) {
                    this.state.draw = SVG(rTabs[0].svg).size(`${this.state.canvasWidth}`, `${this.state.canvasHeight}`).attr({id: `svgCanvas-${this.props.svgID}`});
                    this.props.changeOpenStatus({id: rTabs[0].id});
                }
            }
        }

        if (sessionStorage.getItem(`svgCanvas-${this.props.svgID}`)) {
            let historyData = JSON.parse(sessionStorage.getItem(`svgCanvas-${this.props.svgID}`));

            if (!this.state.canvasImage.length && !this.state.canvasRedo.length) {
                this.setState({
                    canvasImage: this.state.canvasImage = historyData.undo,
                    canvasRedo: this.state.canvasRedo = historyData.redo
                })
            }
        }

        this.state.draw.id(`svgCanvas-${this.props.svgID}`);
        this.state.draw.addClass('canvas-block');
        this.state.draw.addTo(`#canvas-root-${this.props.svgID}`);
        this.state.draw.viewbox(0, 0, this.state.canvasWidth, this.state.canvasHeight);
        let canvasWindow = document.querySelector(`#canvas-root-${this.props.svgID}`);
        let svg = document.querySelector(`#canvas-root-${this.props.svgID}`);
        const rootEl = document.querySelector(`#root`);

        svg.addEventListener('mousedown', this.onMouseDown);
        svg.addEventListener('mousemove', this.onMouseMove);
        svg.addEventListener('mouseup', this.onMouseUp);
        svg.addEventListener('contextmenu', this.onContextMenu);
        document.addEventListener('keydown', this.handlerUndo);
        document.addEventListener('keydown', this.handlerShiftPressed);
        document.addEventListener('keyup', this.handlerShiftRelease);
        svg.addEventListener('mouseover', this.handlerHoverShape);
        svg.addEventListener('mouseout', this.handlerHoverShape);
        rootEl.addEventListener('wheel', this.handlerZoomScroll)

        this.gridConverter(this.state.canvasWidth, this.state.canvasHeight, this.state.gridSize);

        let prevG = this.state.draw.find('.canvasGroup');

        if (prevG && prevG.length) {
            prevG.forEach(el => {
                el.remove();
            })
        }

        const canvasGroup = this.state.draw.group().id(`canvasGroup-${this.props.svgID}`).addClass('canvasGroup');
        canvasGroup.add(this.state.draw.rect(this.state.canvasWidth, this.state.canvasHeight).id(`bgRect-${this.props.svgID}`).addClass('bgRect'));
        canvasGroup.add(this.state.draw.rect(this.state.canvasWidth, this.state.canvasHeight).id(`patRec-${this.props.svgID}`).addClass('patRec'));
        canvasGroup.back();

        this.setState({
            mainGroup: canvasGroup
        })

        this.gridCleaner();
        this.gridRender(this.state.gX, this.state.gY);

        if (sessionStorage.getItem('layersName')) {
            this.setState({
                currentShapesID: this.state.currentShapesID = JSON.parse(sessionStorage.getItem('layersName'))
            })

            // this.props.getCurrentID(this.state.currentShapesID)
        }

        if (this.props.activeTabId === this.props.svgID) {
            this.updateCanvasElementsName();
            this.props.getCurrentID(this.state.shapeNames);
        }

        if (!this.state.draw.findOne('#aimpoint')) {
            this.state.draw.ellipse(0, 0)
                .attr({id: 'aimpoint', class: 'aimPoint'})
                .center(this.state.canvasWidth / 2, this.state.canvasHeight / 2)
        }

        if (this.state.draw.findOne('#aimpoint')) {
            const ellipseEl = this.state.draw.findOne('#aimpoint');

            if (ellipseEl.cx() !== this.state.canvasWidth / 2 || ellipseEl.cy() !== this.state.canvasHeight / 2) {
                ellipseEl.center(this.state.canvasWidth / 2, this.state.canvasHeight / 2)
            }
        }

        const cross = this.state.draw.path('M295 225 H305 M300 220 V230')
            .attr({id: 'aimcross', class: 'aimCross'})
            .fill('none')
            .stroke({ color: '#337AA6', width: 1, opacity: 0.7})
            .attr({'vector-effect': 'non-scaling-stroke'})
            .attr({'stroke-linecap': 'round'})
            .center(512, 384)
            // .back()

        canvasGroup.add(cross);

        // if (this.state.draw.id().includes(this.props.activeTab.id)) {
        //     this.setState({
        //         isSvgActive: this.state.isSvgActive = true
        //     })
        // }

        // if (this.state.isSvgActive) {
        //     this.state.draw.data('reticleId', null);
        //     this.state.draw.data('reticleid', null);
        // }

        if (!this.props.getIsReticleId && this.state.draw.id().includes(this.props.activeTab.id)) {
            // this.state.draw.data('reticleId', null);
            // this.state.draw.data('reticleid', null);
            // this.state.draw.data('reticleId', this.props.activeTab.savingId);
        } else if (this.state.draw.id().includes(this.props.activeTab.id)) {
            if (this.props.getIsReticleId === this.state.draw.data('reticleid')) {
                this.state.draw.data('reticleId', null);
                this.state.draw.data('reticleid', null);
                this.state.draw.data('reticleId', this.props.getIsReticleId);
            }
        }

        canvasWindow.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});

        this.props.setUpdateData({id: this.props.activeTabId, svg: this.svgGridRemove(), shapeList: this.setCurrentShapesList()});
    }

    componentWillUnmount() {
        if (this.state.isSvgActive) {
            this.setState({
                isSvgActive: this.state.isSvgActive = false
            })
        }

        const rootEl = document.querySelector(`#root`);
        let svg = document.querySelector(`#canvas-root-${this.props.svgID}`);

        if (svg) {
            svg.removeEventListener('mousedown', this.onMouseDown);
            svg.removeEventListener('mousemove', this.onMouseMove);
            svg.removeEventListener('mouseup', this.onMouseUp);
            svg.removeEventListener('mouseover', this.handlerHoverShape);
            svg.removeEventListener('mouseout', this.handlerHoverShape);
            svg.removeEventListener('contextmenu', this.onContextMenu);
        }

        rootEl.removeEventListener('wheel', this.handlerZoomScroll);
        document.removeEventListener('keydown', this.handlerUndo);
        document.removeEventListener('keydown', this.handlerShiftPressed);
        document.removeEventListener('keyup', this.handlerShiftRelease);
    }

    render () {
        return (
            <div id={`canvas-root-${this.props.svgID}`} onClick={() => {
                // this.props.getSelectedShape(this.state.selectedShape === '' ? '' : this.state.alignedShape)
                this.props.getSelectedShape(this.state.selectedShape)
                this.props.setGroupedShapes(this.state.groupedShapes)
            }}>
            </div>
        )
    } 
}

// export default Svg;

const mapStateToProps = state => ({
    reduxTabsData: getTabs(state),
    reduxZoomData: getZoomData(state),
    getGrid: getGridData(state),
    getIsReticleId: getIsSavingId(state),
    getColorThemeData: getColorThemeData(state)
});

const mapDispatchToProps = dispatch => ({
    changeOpenStatus: data => dispatch(setOpenStatus(data)),
    updateZoom: data => dispatch(updateZoomData(data)),
    updateZoomXY: data => dispatch(updateZoomPosition(data)),
    setUpdateData: data => dispatch(setUpdateData(data)),
    addSavingID: data => dispatch(addSavingID(data)),
    addShareStatus: data => dispatch(setShareStatus(data)),
    addShapeData: data => dispatch(addShapeData(data)),
    editShapeData: data => dispatch(editShapeData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Svg);
