import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import './share.scss';
import { getCurrentReticleStatus, getIsSavingId, getCurrentReticlePublicId, getCurrentReticleUserEmail } from '../../../../redux/reticles/reticles-selectors';
import { useDispatch, useSelector } from 'react-redux';
// import { changeUserStatusReticle, changeAdminShareStatusReticle } from '../../../../redux/reticles/reticles-operations';
// import { getUserEmail, getUserRole } from '../../../../redux/auth/auth-selectors';
// import { addSavingID } from '../../../../redux/reticles/tabs-reducer';

export default function Share({ activeTab }) {
    // const dispatch = useDispatch();
    const reticleId = useSelector(getIsSavingId);
    const publicId = useSelector(getCurrentReticlePublicId);
    // const status = useSelector(getCurrentReticleStatus);

    // const userEmail = useSelector(getUserEmail);
    // const userRole = useSelector(getUserRole);
    // const currentReticleUserEmail = useSelector(getCurrentReticleUserEmail);

    const baseUrl = 'https://reticle-stage.atncorp.com/';
    // const baseUrl = 'https://reticle.atncorp.com/';
    let [shareUrl, setShareUrl] = useState('?id=');
    let [docID, setDocID] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const shareBtn = useRef();
    const facebookBtn = useRef();
    const twitterBtn = useRef();
    const statusBtn = useRef();
    const copyBtn = useRef();
    const [isCopy, setIsCopy] = useState(false);

    // const hendlerChangeStatus = () => {
    //     // if (status === "BAN") {
    //     //     return
    //     // } else {
    //     let reticleStatus
    //     if (status === "NOT_APPROVED") {
    //         reticleStatus = "PUBLIC"
    //     } else if (status === "PRIVATE") {
    //         reticleStatus = "PUBLIC"
    //     } else if (status === "PUBLIC") {
    //         reticleStatus = "PRIVATE"
    //     } else if (status === "PENDING") {
    //         reticleStatus = "PRIVATE"
    //     };

    //         const payload = {
    //             id: reticleId,
    //             status: reticleStatus,
    //             publicId: ''
    //         };

    //     if (userEmail !== currentReticleUserEmail && (userRole === 'ADMIN' || userRole === 'MANAGER')) {
    //         dispatch(changeAdminShareStatusReticle(payload));
    //     } else if (userEmail === currentReticleUserEmail) {
    //         dispatch(changeUserStatusReticle(payload))
    //     };

    //     dispatch(addSavingID({ id: activeTab.id, savId: reticleId, status: reticleStatus, publicId: '' }));

    //         let objData = JSON.parse(sessionStorage.getItem(activeTab.id));
    //         // console.log('objData', objData)

    //     const historyDataPayload = { ...objData, status: reticleStatus, reticleUserEmail: currentReticleUserEmail };

    //         let historyData = JSON.stringify(historyDataPayload);
    //         sessionStorage.setItem(activeTab.id, historyData);
    //     // }

    // }
    
    // let handlerShowShareSection = () => {
    //     let shareBlock = document.querySelector('#shareBlock');
    //     if (shareBlock) {
    //         shareBlock.classList.toggle('share-block_active');
    //     }
    // }

    let hendlerCopyToClipboard = () => {
        // console.log('reticleId', reticleId)
        // console.log('publicId', publicId)

        // navigator.clipboard.writeText(baseUrl + 'shared/' + '?id=' + docID);
        navigator.clipboard.writeText(`${baseUrl}shared/?id=${docID}`);
        setIsCopy(true);

        setTimeout(() => {
            setIsCopy(false);
            // setShareUrl('');
            // navigator.clipboard.writeText('');
        }, 10000);
    }

    useEffect(() => {
        const id = reticleId ? reticleId : publicId
        setDocID(id);
        // setShareUrl(baseUrl + 'shared/' + '?id=' + docID);
        setShareUrl(`${baseUrl}shared/?id=${docID}`);

    }, [docID, reticleId, publicId])

    useEffect(() => {
        setIsCopy(false);
        // setShareUrl('');
        // navigator.clipboard.writeText('');
    }, [activeTab])

    useEffect(() => {
        const closeDropdown = e => {
            if (e.target !== shareBtn.current && e.target !== facebookBtn.current && e.target !== twitterBtn.current && e.target !== statusBtn.current && e.target !== copyBtn.current) {
                setIsOpen(false)
            }
        }
        document.body.addEventListener('click', closeDropdown);

        return () => document.body.removeEventListener('click', closeDropdown);
    }, [])

    return (
        <section className='share-section'>
            <button ref={shareBtn} className='share-btn' onClick={() => setIsOpen(prev => !prev)}>
                Share
            </button>
            <div className={`share-block ${(isOpen ? 'share-block_active'  : '')}`}>
                <div className="arrow-up"></div>
                <FacebookShareButton
                    ref={facebookBtn}
                    url={shareUrl}
                    quote={'My reticle'}
                    hashtag={'#myreticle'}
                >
                    <div className='share-item fb-ico'>Facebook</div>
                </FacebookShareButton>
                <TwitterShareButton
                    ref={twitterBtn}
                    url={shareUrl}
                    title={'My reticle'}
                    hashtag={'#myreticle'}
                >
                    <div className='share-item tw-ico'>Twitter</div>
                </TwitterShareButton>
                {/* {publicId !== '' || reticleId === '' || status === "BAN" ? (
                    null) :
                    (<div ref={statusBtn} className='share-item mp-ico' onClick={hendlerChangeStatus}>{status === "PRIVATE" || status === "NOT_APPROVED" ? 'Make public' : 'Make private'}</div>
                )} */}
                <div ref={copyBtn} className='share-item gl-ico' onClick={hendlerCopyToClipboard}>{isCopy ? 'Copied!' : 'Copy Link'}</div>
            </div>
        </section>
    );
}
